import React from 'react'
import SideBar from '../components/SideBar'
import { Components } from "../assets/Dimensions";
import Previews from '../components/ImageUpload/DropZonePreview'

const contentManagment = () => {

  const styles = {
    container: {
      marginLeft: Components.SideBarWidth + 15,
      paddingTop: 20,
      marginRight: 30,
      marginBottom: 20
    },
  }

  return (
    <div>
      <SideBar />
      <div style={styles.container}>
        <Previews />
      </div>
    </div>
  )
}

export default contentManagment
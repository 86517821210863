import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

//#region Material Components
import { withStyles } from '@material-ui/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiGrid from '@material-ui/core/Grid';
import MuiTypography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
//#endregion

//#region Internal
import { Primary } from './../../assets/Colors';
import { FontSize } from './../../assets/Dimensions';
import { Text } from './../../assets/Strings';

import { removeCroppedList } from './../../redux/actions/cropAction';
//#endregion

//#region Override Style
const Grid = withStyles({
    'grid-xs-true': {
        flexGrow: 0
    }
})(MuiGrid);

const IconButton = withStyles({
    root: {
        color: 'rgb(114,122,148)',
        padding: 0,
        borderRadius: '10%',

    },
    label: {
        '& .MuiSvgIcon-root':{
            fontSize: FontSize.Huge
        }
    }
})(MuiIconButton);

const Typography = withStyles({
    body1: {
        fontSize: FontSize.Mid,
        float: 'left',
        color: 'rgb(114,122,148)'
    }
})(MuiTypography)
//#endregion

//#region Inline Style
const styles = {
    container: {
        backgroundColor: Primary.ContainerBackground,
        marginTop: 10,
        borderRadius: 10
    },
    gridContainer: {
        padding: 10,
        marginRight: 30,
        width: 320
    },
    img: {
        width: 300
    }
}
//#endregion

class CroppedPanel extends Component {
    constructor(props){
        super(props);

        this.state={};
    }

    _removeCroppedItem = (item) => {
        this.props.removeCroppedList(item);
    };

    render(){
        let count = 0;
        return(
            <Grid container style={{...styles.container}}>
                {this.props.CroppedList.map((item) => {
                    count++;
                    return(
                        <Grid key={count} container style={{...styles.gridContainer}}>
                            <Grid item style={{width: '100%'}}>
                                <Typography>
                                    {count + '. Crop'}
                                </Typography>
                                <div style={{float: 'right'}}>
                                    <IconButton onClick={() => this._removeCroppedItem(item.ImageData)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                    <Typography>
                                        {Text.Delete}        
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item style={{marginTop: 5}}>
                                <img
                                    alt='croppedImage'
                                    src={typeof item.ImageData === 'string' ? item.ImageData : window.URL.createObjectURL(item.ImageData)}
                                    style={{...styles.img}}
                                />
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        )
    }
}

CroppedPanel.propTypes = {
};

CroppedPanel.defaultProps = {
};

const mapStateToProps = (state) => ({
    CroppedList: state.cropReducer.CroppedList
});

const mapDispatchToProps = (dispatch) => ({
    removeCroppedList: (cropped) => dispatch(removeCroppedList(cropped))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CroppedPanel));
import { BookSectionActions } from './../ActionTypes';

const initialState = {
    BookSections: [],
    RowCount: 0,
    SelectedId: 0
};

export const bookSectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case BookSectionActions.SetAll:
            return Object.assign({}, state, { 
                BookSections: action.bookSections,
                RowCount: action.rowCount
            });
        case BookSectionActions.SetSelectedBookSectionId:
            return Object.assign({}, state, { 
                SelectedId: action.selectedId
            });
        default:
            return state;
    }
};
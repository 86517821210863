import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

//#region Internal
import DialogPanel from './../components/Base/DialogPanel';
import PageHeader from './../components/Base/PageHeader';
import SideBar from './../components/SideBar'
import Grid from './../components/Grid/index';
import GridStatusBar from './../components/Grid/GridStatusBar';
import GridSwitchButton from './../components/Grid/GridSwitchButton';
import { Notification, success, error, warning } from './../components/Notification/index';
import { Components } from './../assets/Dimensions';
import { Text } from './../assets/Strings';
import getRows from './../util/getRows';

import { getAllUsers, createUserByAdmin, deleteUserByAdmin } from './../api/User';
import { updateUserDetail } from './../api/UserDetail';
import { getAllRoles } from './../api/Role';

import { setAllUsers } from './../redux/actions/allUserAction';
import { setAllRoles } from './../redux/actions/roleAction';
import { setSelectedItem } from './../redux/actions/sidebarAction';
import { setLinks } from './../redux/actions/pageHeaderLinkAction';
import { logoutUser } from './../redux/actions/userAction';
import { setPageNumber } from './../redux/actions/gridAction';
//#endregion 

//#region Variables
const _pageSize = 6;
//#endregion

//#region Inline Style
const styles = {
    container: {
        marginLeft: Components.SideBarWidth + 15,
        paddingTop: 20,
        marginRight: 30
    }
}
//#endregion

class MembersPage extends Component {
    constructor(props){
        super(props);

        this._editFieldNames = ['editAccountStatus','editName','editSurname','editPhoneNumber','editEmail','editIban','editRole'];
        this._createFieldNames = ['newUserName','newPassword','newName','newSurname','newPhoneNumber','newEmail','newIban','newRole'];
        this._rows=[];
        this._columns=[];
        this._editRowData=[];
        this._user={};
        this._orderColumn='';
        this._orderByAsc=true;

        this.state={
            createDialog: false,
            deleteDialog: false,
            editDialog: false,
            deleteUserId: 0,
            searchValue: ''
        };
    }

    componentWillMount(){
        this.props.setLinks([{
            Text: Text.Members,
            href: '/members'
        }]);
    };

    async componentDidMount() {
        this._user = JSON.parse(localStorage.getItem('user'));
        if(this._user === null || this._user.Role !== 'Administrators'){
            localStorage.clear();
            this.props.logOutUser();
            this.props.history.push('/login');
            return;
        }

        this.props.setPageNumber(0);
        this.props.setSelectedSideBar(Text.Members);

        let {Users, RowCount} = await this._getAllUsers('', '', 1);
        this.props.setUsers(Users, RowCount);

        let roleRes = await getAllRoles(this._user.Token);
        if(roleRes.status === 200){
            this.props.setRoles(roleRes.data);
        } else {
            error(roleRes, 2000);
        }
    };

    async shouldComponentUpdate(nextProps, nextState) {
        if(this.props.Users !== nextProps.Users){
            return true;
        }
        if(this.props.PageNumber !== nextProps.PageNumber){
            let {Users, RowCount} = await this._getAllUsers('','', 1, nextProps.PageNumber);
            this.props.setUsers(Users,RowCount);
            return true;
        }
        if(this.state !== nextState) {
            return true;
        }

        return false;
    };

    _getAllUsers = async (searchValue, orderColumn, orderBy, pageNumber = this.props.PageNumber) => {
        let paging = {
            PageSize: _pageSize,
            PageNumber: pageNumber,
            SearchValue: searchValue,
            OrderColumn: orderColumn,
            OrderBy: orderBy
        };
        
        let res = await getAllUsers(paging, this._user.Token);
        if(res.status === 200){
            this._columns = res.data.Users.length > 0 ?
                Object.keys(res.data.Users[0])
                : []; 
            this._rows = this._getRows(res.data.Users) || [];
            return {Users: res.data.Users, RowCount: res.data.RowCount};
        } else {
            error(res, 2000);
        }
    };

    _handleClose = (name) => {
        this.setState({
            [name]: false
        });
    };

    _createUser = async() => {
        this.setState({
            createDialog: true
        });
    };
    
    _deleteRow = (e) => {
        this.setState({
            deleteDialog: true,
            deleteUserId: e.currentTarget.id
        });
    };

    _validation = (fields) => {
        for(var key in fields){
            let val = fields[key];
            if(val.indexOf('editAccountStatus') === -1 && document.getElementById(val).value === ''){
                let msg = Text[val];
                return `Lütfen '${msg}' kısmını doldurunuz!`;
            }
        }
        return '';
    };

    _addUser = async() => {
        let validationResult = this._validation(this._createFieldNames);
        if(validationResult !== ''){
            warning(validationResult, 2000);
        } else {
            let newUser ={
                UserName: document.getElementById('newUserName').value,
                Name: document.getElementById('newName').value,
                Surname: document.getElementById('newSurname').value,
                Password: document.getElementById('newPassword').value,
                Email: document.getElementById('newEmail').value,
                PhoneNumber: document.getElementById('newPhoneNumber').value,
                Iban: document.getElementById('newIban').value,
                Role: document.getElementById('newRole').value,
            };
            let res = await createUserByAdmin(newUser, this._user.Token);
            if(res.status === 200){
                success(Text.SuccessCreateUser, 2000);

                this.setState({
                    createDialog: false
                });

                let {Users, RowCount} = await this._getAllUsers('', '', 1);
                this.props.setUsers(Users,RowCount);
            } else {
                error(res, 2000);
            }
        }
    };

    _deleteUser = async() => {
        let user = {
            Id: this.state.deleteUserId
        };
        let res = await deleteUserByAdmin(user, this._user.Token);
        if(res.status === 200){
            success(Text.DeletedMember, 2000);

            let {Users, RowCount} = await this._getAllUsers('', '', 1);

            this.setState({
                deleteDialog: false
            });
            
            this.props.setUsers(Users,RowCount);
        } else {
            error(res, 2000);
        }
    };

    _editRow = (e) => {
        let selectedRow = this.props.Users.filter((row) => 
            row.Id.toString() === e.currentTarget.id
        );
        this._editRowData = selectedRow;

        this.setState({
            editDialog: true
        });
    };
    
    _editUser = async() => {
        let validationResult = this._validation(this._editFieldNames);
        if(validationResult !== ''){
            warning(validationResult, 2000);
        } else {
            let user = {
                UserId: this._editRowData[0].Id,
                Name: document.getElementById('editName').value,
                Surname: document.getElementById('editSurname').value,
                PhoneNumber: document.getElementById('editPhoneNumber').value,
                Email: document.getElementById('editEmail').value,
                Iban: document.getElementById('editIban').value,
                AccountStatus: document.getElementById('editAccountStatus').checked,
                Role: document.getElementById('editRole').value
            };
            let res = await updateUserDetail(user, this._user.Token);
            if(res.status === 200){
                success(Text.EditedMember, 2000);
    
                let {Users, RowCount} = await this._getAllUsers('', '', 1);
    
                this.setState({
                    editDialog: false
                });
                
                this.props.setUsers(Users,RowCount);
            } else {
                error(res, 2000);
            }
        }
    };

    _getRows = (data) => {
        let cellComponentArray = [
            {
                Name: 'EmailConfirmed', 
                FalseComponent: (<GridStatusBar img={require('./../assets/images/clock.png')} text={Text.Waiting}/>),
                TrueComponent: (<GridStatusBar img={require('./../assets/images/success.png')} text={Text.Confirmed}/>)
            },
            {
                Name: 'AccountStatus', 
                FalseComponent: (<GridSwitchButton checked={false} text={Text.DeActive}/>),
                TrueComponent: (<GridSwitchButton checked={true} text={Text.Active}/>)
            }
        ];
        return getRows(data, null, null, cellComponentArray);
    };

    _handleSearchChange = async (e) => {
        this.setState({
            searchValue: e.target.value
        });

        if(e.target.value.length >= 3 || e.target.value.length === 0) {
            let {Users, RowCount} = await this._getAllUsers(e.target.value, '', 1);
            this.props.setUsers(Users,RowCount);
        }
    };

    _headerClick = async (e) => {
        if(e.target.innerHTML !== ''){
            let column = e.target.innerHTML;
            if(column === 'AccountStatus'){
                column = 'RowStatus';
            }

            if(column === 'Role' ||column === 'Iban' || column === 'DeservedAmount'
                || column === 'PaidAmount'){
                    warning(Text.DoesNotOrderColumn, 2000);
                    return;
                }

            let orderBy = this._orderColumn !== column ? this._orderByAsc : !this._orderByAsc;
            this._orderByAsc = orderBy;
            this._orderColumn = column;

            let {Users, RowCount} = await this._getAllUsers(this.state.searchValue, column, this._orderByAsc === true ? 1 : 2);
            this.props.setUsers(Users,RowCount);
        }
    };

    render(){
        return(
            <div>
                <SideBar/>

                <div style={{...styles.container}}>
                    <PageHeader
                        title={Text.Members}
                    />
                    <Grid 
                        stickyColumnCount={2} 
                        data={{
                            columns: this._columns,
                            rows: this._rows
                        }} 
                        toolbar={{
                            create: true,
                            createClick: this._createUser
                        }}
                        paging={{
                            rowsPerPage: _pageSize,
                            count: this.props.RowCount
                        }}
                        editRow={this._editRow}
                        deleteRow={this._deleteRow}
                        searchOnChange={this._handleSearchChange}
                        searchValue={this.state.searchValue}
                        headerClick={this._headerClick}
                    />
                </div>
                
                <DialogPanel
                    id='createDialog'
                    isOpen={this.state.createDialog}
                    onClose={() => this._handleClose('createDialog')}
                    title={Text.CreateMember}
                    actions={[
                        { onClick: () => this._handleClose('createDialog'), text: Text.Cancel }, 
                        { onClick: this._addUser, text: Text.Add }
                    ]}
                    contentComponentNames={this._createFieldNames}
                />

                <DialogPanel
                    id='deleteDialog'
                    isOpen={this.state.deleteDialog}
                    onClose={() => this._handleClose('deleteDialog')}
                    title={Text.DeleteMemberTitle}
                    contentText={Text.DeleteMemberQuestion}
                    actions={[
                        { onClick: () => this._handleClose('deleteDialog'), text: Text.Cancel }, 
                        { onClick: this._deleteUser, text: Text.Delete }
                    ]}
                />

                <DialogPanel
                    id='editDialog'
                    isOpen={this.state.editDialog}
                    onClose={() => this._handleClose('editDialog')}
                    title={Text.EditMemberTitle}
                    actions={[
                        { onClick: () => this._handleClose('editDialog'), text: Text.Cancel }, 
                        { onClick: this._editUser, text: Text.Edit }
                    ]}
                    contentComponentNames={this._editFieldNames}
                    editRow={this._editRowData}
                />

                <Notification/>
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    User: state.userReducer.User,
    PageNumber: state.gridReducer.PageNumber,
    Users: state.allUserReducer.Users,
    RowCount: state.allUserReducer.RowCount,
    Roles: state.roleReducer.Roles
});

const mapDispatchToProps = (dispatch) => ({
    setUsers: (users, rowCount) => dispatch(setAllUsers(users, rowCount)),
    setRoles: (roles) => dispatch(setAllRoles(roles)),
    setSelectedSideBar: (item) => dispatch(setSelectedItem(item)),
    setLinks: (links) => dispatch(setLinks(links)),
    logOutUser: () => dispatch(logoutUser()),
    setPageNumber: (pageNumber) => dispatch(setPageNumber(pageNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MembersPage));
import React, { Component } from 'react';

//#region Material Components
import { withStyles } from '@material-ui/core';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//#endregion

//#region Internal
import { Primary } from '../assets/Colors';
import { Components } from '../assets/Dimensions';

import Logo from './../assets/images/logo.png';


//#region Inline Style
const styles = {
    icon:{
        marginLeft: Components.SideBarWidth + 10,
        width: 100
    }
}
//#endregion

function Loader(){

        return(
            <div style={{position:'absolute',backgroundColor:'rgba(0,0,0,0.5)',top:0,left:0,right:0,bottom:0}}>
          
            Yükleniyor
            </div>
        );
    
}

export default Loader;
import React, { Component } from 'react';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/styles';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiSwitch from '@material-ui/core/Switch';
//#endregion

import { Components, FontSize } from './../../assets/Dimensions';
import { Primary } from './../../assets/Colors';

//#region Override Style
const FormControlLabel = withStyles({
    label: {
        fontSize: FontSize.Small
    }
})(MuiFormControlLabel);

const Switch = withStyles({
    colorPrimary: {
        '&.Mui-disabled + .MuiSwitch-track':{
            backgroundColor: 'rgb(65,190,160)',
            opacity: 0.3
        },
        '&.Mui-checked':{
            color: Primary.ContainerBackground
        },
        '&.Mui-disabled':{
            color: Primary.ContainerBackground
        }
    },
    track: {
        backgroundColor: 'rgb(216,216,216)',
        opacity: 0.6
    },
    switchBase: {
        padding: '13px 18px 12px 12px'
    },
    thumb: {
        width: Components.Image.Small.Width,
        height: Components.Image.Small.Height,
    }
})(MuiSwitch)
//#endregion

class GridSwitchButton extends Component {
    constructor(props){
        super(props);

        this.state={};
    }

    render(){
        return(
            <FormControlLabel
                value='end'
                disabled
                control={
                    <Switch 
                        color="primary" 
                        checked={this.props.checked}
                    />
                }
                label={this.props.text}
                labelPlacement='end'
            />
        )
    }
}

GridSwitchButton.propTypes = {
    text: PropTypes.string,
    checked: PropTypes.bool
};

GridSwitchButton.defaultProps = {
    checked: false
};

export default GridSwitchButton;

import React, { Component } from 'react';

//#region Internal
import { Text } from './../assets/Strings';

import ApproveButton from './Base/ApproveButton';
//#endregion

//#region Inline Style
const styles= {
    toolbar:{
        marginTop: 20,
        marginBottom: 20
    }
}
//#endregion

class Toolbar extends Component {
    constructor(props){
        super(props);

        this.state={};
    }

    render(){
        return(
            <div style={{...styles.toolbar}}>
                <ApproveButton onClick={this.props.saveClick} text={Text.Save}/>
            </div>
        )
    }
};

Toolbar.propTypes = {
};

Toolbar.defaultProps = {
};

export default Toolbar;
import { ApiUrl } from './common/Config';
import { post, put } from './common/Helper';

const baseUrl = ApiUrl + 'book/';

export const getAllBooks = async(paging, token) => {
    let url = baseUrl + 'getall';
    return await post(url, paging, token);
};

export const createBook = async(book, token) => {
    let url = baseUrl + 'create';
    return await post(url, book, token);
};

export const deleteBook = async(book, token) => {
    let url = baseUrl + 'delete';
    return await put(url, book, token);
};

export const updateBook = async(book, token) => {
    let url = baseUrl + 'update';
    return await put(url, book, token);
};

export const bulkInsertBooks = async(books, token) => {
    let url = baseUrl + 'excelupload';
    return await post(url, {Books: books}, token);
};
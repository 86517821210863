import { ApiUrl, ApiUrlAdmin } from "../common/Config";
import { postAdmin,post,get,getAdmin,postFormData } from "../common/Helper";

export const getKpssCourses = async (token) =>{
    let url = ApiUrl + `BookSectionCrop/getkpsscourses`
    return await get(url,token)
}
export const getKpssSubCourses = async (token,courseId) =>{
    let url = ApiUrl + `BookSectionCrop/getkpsssubcourses?courseId=${courseId}`
    return await get(url,token)
}
export const getKpssQuestion = async (token,data) =>{
    let url = ApiUrl + `BookSectionCrop/getkpssquestion`
    return await post(url,data,token)
}
export const updateKpssQuestion = async (token,data) =>{
    let url = ApiUrl + `BookSectionCrop/updatekpssquestion`
    return await post(url,data,token)
}
import { UserActions } from './../ActionTypes';

const initialState = {
    User: {}
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActions.Login:
            return Object.assign({}, state, { 
                User: action.user
            });
        case UserActions.Update:
            return Object.assign({}, state, { 
                User: action.user
            });
        case UserActions.Logout:
            return initialState;
        default:
            return state;
    }
};
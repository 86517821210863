import { RoleActions } from './../ActionTypes';

const initialState = {
    Roles: []
};

export const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case RoleActions.SetAll:
            return Object.assign({}, state, { 
                Roles: action.roles,
            });
        default:
            return state;
    }
};
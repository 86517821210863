import React from 'react';

const DynamicTable = ({ data, isTablet }) => {
  const entries = Object.entries(data);
  const styles = {
    card: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      border: "solid 1px gray",
      padding: 12,
      margin: 10,
      backgroundColor: "#FFFFFF",
      borderRadious: 20,

    },
    cardDiv: {
      display: "flex",
      flexDirection: isTablet ? "column" : "row",
      margin: 12
    },
  }
  return (
    isTablet ?
      <div style={styles.cardDiv}>
        {data.map((data, index) => (
          <div style={styles.card} key={index}>
            {Object.entries(data).map(([key, value]) => (
              <p key={key}>
                <strong>{key}: </strong> {value}
              </p>
            ))}
          </div>
        ))}

      </div> :
      <table>
        <thead>
          <tr>
            {entries.length > 0 &&
              Object.keys(entries[0][1]).map((header, index) => (
                <th key={index}>{header}</th>
              ))}

          </tr>
        </thead>
        <tbody>
          {entries.map(([key, value], index) => (
            <tr key={index}>
              {Object.values(value).map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}

            </tr>
          ))}
        </tbody>
      </table>
  );
};

export default DynamicTable;
// {isTabletOrMobile &&
//                           <div style={styles.cardDiv}>
//                             {data.length > 0 ? data.map((credit) => {
//                               return (
//                                 <div style={styles.card}>
//                                   <p>{`ID: ${credit.Id}`}</p>
//                                   <p>{`CreditResourceType: ${credit.CreditResourceType}`}</p>
//                                   <p>{`EndDate: ${moment(credit.EndDate).format("DD/MM/YYYY hh:mm")}`}</p>
//                                   <p>{`IsPaid: ${credit.IsPaid}`}</p>
//                                   <p>{`ProductId: ${credit.ProductId}`}</p>
//                                   <p>{`PromotionCode: ${credit.PromotionCode}`}</p>
//                                   <p>{`StartDate: ${moment(credit.StartDate).format("DD/MM/YYYY hh:mm")}`}</p>
//                                   <p>{`Status: ${credit.Status}`}</p>

//                                   <button
//                                     onClick={() => {
//                                       updateStatus(credit);
//                                     }}
//                                   >
//                                     {loading ? "Yükleniyor" : "Update Status"}
//                                   </button>
//                                   <p>{`GPA: ${credit.OrderId ? credit.OrderId : ""}`}</p>
//                                   <p>{`User Id: ${credit.UserId}`}</p>

//                                 </div>
//                               )
//                             }) : <div>
//                             <span
//                               style={{ backgroundColor: "red", fontSize: "30px", color: "white" }}
//                             >
//                               {notFound}
//                             </span>
//                           </div>}
//                           </div>}
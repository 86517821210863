import React, { useState } from 'react';
import './SwitchComponent.css'; // Make sure you have this CSS file to style the switch

const SwitchComponent = ( {onValueChange} ) => {
  const [switchValue, setSwitchValue] = useState('DevTest');

  const handleSwitchToggle = () => {
    // Toggle the switch between 'Value 1' and 'Value 2'
    const newValue = switchValue === 'DevTest' ? 'Admin' : 'DevTest';
    setSwitchValue(newValue);
    onValueChange(newValue); 
  };

  return (
    <div className="switch-container">
      <button onClick={handleSwitchToggle} className={`switch-button ${switchValue === 'DevTest' ? 'active' : ''}`}>
      DevTest
      </button>
      <button onClick={handleSwitchToggle} className={`switch-button ${switchValue === 'Admin' ? 'active' : ''}`}>
      Admin
      </button>
    </div>
  );
};

export default SwitchComponent;

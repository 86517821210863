export const Components = {
    SideBarWidth: 100,
    GridButton: {
        Width: 130,
        Height: 36
    },
    Image: {
        Small: {
            Width: 12,
            Height: 12
        },
        Mid: {
            Width: 14,
            Height: 14
        },
        Big:{
            Width: 16,
            Height: 16
        },
        Huge:{
            Width: 24,
            Height: 24
        }
    },
    Typography: {
        Mid: {
            Height: 30
        }
    },
    FabButton: {
        Small: {
            Width: 30,
            Height: 30
        },
        Mid: {
            Width: 40,
            Height: 40
        }
    }
}

export const FontSize = {
    VerySmall: 10,
    Small: 12,
    Mid: 14,
    Big: 16,
    Huge: 20
}
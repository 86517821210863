import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/core';
import MuiTypography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
//#endregion

//#region Internal
import { Primary } from './../../assets/Colors';
import { FontSize } from './../../assets/Dimensions';
//#endregion

//#Override Style
const Typography = withStyles({
    root: {
        fontWeight: 500,
        display: 'inline-block',
        marginLeft: 20,
        marginTop: 5
    }
})(MuiTypography);

const Link = withStyles({
    root: {
        color: Primary.LinkText,
        fontSize: FontSize.Small,
        fontWeight: 'normal',
    }
})(MuiLink);
//#endregion

//#region Inline Style
const styles = {
    container:{
        backgroundColor: Primary.ContainerBackground,
        padding: 10,
        borderRadius: 6,
        boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
        minHeight: 55,
        position: 'sticky',
        top: 0,
        zIndex: 1000,
        display: 'flow-root'
    }
}
//#endregion

class PageHeader extends Component {
    constructor(props){
        super(props);

        this.state={};
    }

    render(){
        return(
            <div style={{...styles.container}}>
                {this.props.mainTitle &&
                    <Typography style={{
                        display: 'block',
                        fontSize: FontSize.Big,
                        fontWeight: 'bold'
                    }}>
                        {this.props.mainTitle}
                    </Typography>
                }
                <Typography style={{
                    float: 'left',
                    fontSize: this.props.isCropPage ? FontSize.Mid : FontSize.Huge
                }}>
                    {this.props.title}
                </Typography>   

                <div style={{float: 'right'}}>
                    {this.props.Links.map((item) => (
                        <Typography key={item.Text}>
                            <Link href={item.href}>
                                {item.Text + ' > '} 
                            </Link>
                        </Typography> 
                    ))}
                </div>
            </div>
        )
    }
};

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    mainTitle: PropTypes.string,
    isCropPage: PropTypes.bool
};

PageHeader.defaultProps = {
    isCropPage: false
};

const mapStateToProps = (state) => ({
    Links: state.pageHeaderLinkReducer.Links,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
import React, { useEffect, useState } from "react";
import Toggle from "../../scenes/questionVideoSolution/components/toggle/toggle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ExamType from "../../enums/ExamType";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/RemoveCircle";
import AchievementType from "../../enums/AchievementType";

import "./compatiblityOptionsStyle.css";
import KpssCompatibilityStatus from "../../enums/KpssCompabilityStatus";

const SubCourses = ({
  updateKpssQuestionCompatibility,
  question,
  previousQuestion,
  nextQuestion,
  questionOrder,
}) => {
  const [status, setStatus] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null); 

  const handleCheckboxChange = (value) => {
    setStatus(prevStatus =>
      prevStatus.includes(value)
        ? prevStatus.filter(item => item !== value)
        : [...prevStatus, value]
    );
    setSelectedValue(value);
    setTimeout(() => {
      updateKpssQuestionCompatibility(
        question,
        value
      );
      setSelectedValue(null); 
      setStatus([])
    }, 500);
  };
  console.log("<<<<question>>>>", question);
  const getNextQuestion = () => {
    // Check if primaryAchievement is not set

    // If primaryAchievement is set, proceed to next question
    nextQuestion();

    // Reset selectedTopic and primaryAchievement
  };

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      <div className="buttonContainer">
        <button
          disabled={questionOrder <= 1}
          onClick={() => {
            previousQuestion();
          }}
          className="prevButton"
        >
          <ArrowBackIcon />
        </button>
        <button
          disabled={question.KpssCompatibility == 0}
          className="nextButton"
          onClick={() => {
            // setPrimaryAchievement(null);
            // nextQuestion();
            getNextQuestion();
          }}
        >
          <ArrowForwardIcon />
        </button>
      </div>

      {/* </div> */}
      <div
        style={{
          width: "100%",

          //maxHeight:window.screen.height
        }}
      >
        <div style={{margin:10}}>KPSS ile uyumlu mu?</div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              padding: 10,
              borderWidth: "2px",
              border: 1,
              borderRadius: 8,
              borderStyle: "solid",
              marginBottom: 10,
            }}
            onClick={()=>handleCheckboxChange(KpssCompatibilityStatus.Compatible)}
          >
            
            <label
            className={`checkbox-label ${selectedValue === KpssCompatibilityStatus.Compatible ? 'selected' : ''}`}
            >
              <input
                type="checkbox"
                value={KpssCompatibilityStatus.Compatible}
                checked={
                  question.KpssCompatibility ==
                  KpssCompatibilityStatus.Compatible || status.includes(KpssCompatibilityStatus.Compatible)
                }
                // onChange={() =>
                //   // updateKpssQuestionCompatibility(
                //   //   question,
                //   //   KpssCompatibilityStatus.Compatible
                //   // )
                  
                // }
              />
              Evet
            </label>
          </div>
          <div
            style={{
              padding: 10,
              borderWidth: "2px",
              border: 1,
              borderRadius: 8,
              borderStyle: "solid",
              marginBottom: 10,
            }}
            onClick={()=> handleCheckboxChange(KpssCompatibilityStatus.NotCompatible)}
          >
            <label
            className={`checkbox-label ${selectedValue === KpssCompatibilityStatus.NotCompatible ? 'selected' : ''}`}

            >
              <input
                type="checkbox"
                value={KpssCompatibilityStatus.NotCompatible}
                checked={
                  question.KpssCompatibility ==
                  KpssCompatibilityStatus.NotCompatible || status.includes(KpssCompatibilityStatus.NotCompatible)
                }
                // onChange={() =>
                 
                 
                // }
              />
             Hayır
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCourses;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

//#region Material Components
import { withStyles } from '@material-ui/styles';
import MuiTypography from '@material-ui/core/Typography';
import MuiFormControl from '@material-ui/core/FormControl';
import MuiTextField from '@material-ui/core/TextField';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiButton from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
//#endregion

//#region Internal
import ApproveButton from './../Base/ApproveButton';

import { Components, FontSize } from './../../assets/Dimensions';
import { Primary } from './../../assets/Colors';
//#endregion

//#region Override Style
const FormControl = withStyles({
    root: {
        float: 'left',
        padding: '35px 35px 30px',
        width: '60%'
    }
})(MuiFormControl);

const Title = withStyles({
    root: {
        marginBottom: 30
    },
    body1: {
        fontSize: FontSize.Huge
    }
})(MuiTypography);

const TextField = withStyles({
    root: {
        fontSize: FontSize.Small,
        marginTop: 10
    }
})(MuiTextField);

const IconButton = withStyles({
    label: {
        color: Primary.IconColor
    }
})(MuiIconButton);

const Typography = withStyles({
    root: {
        marginTop: 8,
        float: 'left',
    },
    body1: {
        fontSize: FontSize.Small
    }
})(MuiTypography);

const TextButton = withStyles({
    label:{
        fontSize: FontSize.Small,
        color: Primary.TextColor,
        textTransform: 'capitalize'
    }
})(MuiButton);
//#endregion

//#region Inline Style 
const styles = {
    passwordIcon: {
        width: Components.Image.Big.Width,
        height: Components.Image.Big.Height
    },
    footer: {
        marginTop: 10
    },
    footerRightBlock: {
        float:'right', 
        margin:'inherit'
    }
};
//#endregion

class FormPanel extends Component {
    constructor(props){
        super(props);

        this.state={
            showPasswordValue: false
        };
    }

    _showPassword = () => {
        this.setState({
            showPasswordValue: !this.state.showPasswordValue,
        });
    };

    render(){
        return(
            <FormControl>
                {this.props.title &&
                    <Title>
                        {this.props.title}
                    </Title>
                }
                    

                {this.props.components.map(item => {
                    if(item.type !== 'password'){
                        return (
                            <TextField
                                id={item.id}
                                key={item.id}
                                label={item.label}
                                type={item.type !== undefined ? item.type : 'text'}
                                InputProps={{ 
                                    style: { fontSize: FontSize.Mid },
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <img
                                                src={item.icon}
                                                alt='icon'/> 
                                        </InputAdornment>
                                    )
                                }}
                            /> 
                        );
                    } else {
                        return(
                            <TextField
                                id={item.id}
                                key={item.id}
                                label={item.label}
                                type={this.state.showPasswordValue ? 'text' : 'password'}
                                InputProps={{ 
                                    style: { fontSize: FontSize.Mid },
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <img
                                                src={item.icon}
                                                alt='icon'/> 
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton onClick={this._showPassword}>
                                                {this.state.showPasswordValue ? 
                                                    <Visibility style={{...styles.passwordIcon}} /> : 
                                                    <VisibilityOff style={{...styles.passwordIcon}}/>}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            /> 
                        );
                    }  
                })}

                {this.props.footer &&
                    <div style={{...styles.footer}}>
                        <ApproveButton onClick={this.props.footer.button.onClick} text={this.props.footer.button.text}/>

                        <div style={{...styles.footerRightBlock}}>
                            <Typography>
                                {this.props.footer.text}
                                {Text.NoAccount}
                            </Typography>

                            <TextButton component={Link} to={this.props.footer.link}>
                                {this.props.footer.linkText}
                            </TextButton>
                        </div>
                    </div>
                }
            </FormControl>
        )
    }
}

FormPanel.propTypes = {
    title: PropTypes.string,
    components: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            type: PropTypes.string,
            icon: PropTypes.node
        })
    ).isRequired,
    footer: PropTypes.shape({
        button: PropTypes.shape({
            onClick: PropTypes.func,
            text: PropTypes.string
        }),
        text: PropTypes.string,
        link: PropTypes.string,
        linkText: PropTypes.string
    })
};

FormPanel.defaultProps = {
};

export default FormPanel;
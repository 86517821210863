import { BookSectionTypeActions } from './../ActionTypes';

const initialState = {
    BookSectionTypes: []
};

export const bookSectionTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case BookSectionTypeActions.SetAll:
            return Object.assign({}, state, { 
                BookSectionTypes: action.bookSectionTypes,
            });
        default:
            return state;
    }
};
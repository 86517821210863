import { ApiUrl, ApiUrlAdmin } from "./common/Config";
import { postAdmin,post } from "./common/Helper";

export const getReports = async (params, token) => {
  let url = ApiUrlAdmin + "report";
  return await postAdmin(url, params, token, true);
};

export const updateReport = async (BookSectionCropId, token) => {
  let url = ApiUrlAdmin + "report/update";
  return await postAdmin(url, { BookSectionCropId }, token, true);
};

export const getReportsDetails = async (BookSectionCropId, token) => {
  let url = ApiUrlAdmin + "report/details";
  return await postAdmin(url, { BookSectionCropId }, token, true);
};
export const updateAnswer = async (data, token) => {
  let url = ApiUrlAdmin + "report/update-answer";
  return await postAdmin(url, data, token, true);
};
export const updateCropAnswer = async (data, token) => {
  let url = ApiUrl + "report/update-crop-answer";
  return await postAdmin(url, data, token, true);
};
export const getImageUrlR = async (data, token) => {
  let url = ApiUrl + "/booksectioncrop/getcroppedimage";
  return await post(url, data, token);
};
export const cropPage = async (BookSectionCropId, token) => {
  let url = ApiUrl + "report/cropPage";
  return await postAdmin(url, {BookSectionCropId}, token, true);
};

import { useState } from "react";
import { getReportIssueType, sendReport } from "../api/BookSectionCrop";
import { toast } from "react-toastify";

export const useReportIssues = (userToken) => {
  const [issueTypes, setIssueTypes] = useState([]);
  const [reportModal, setReportModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const getIssues = async () => {
    try {
      setLoader(true);
      const res = await getReportIssueType(userToken);
      if (res.data.ResponseStatus === 1) {
        const updatedData = res.data.ResponseData.map((item) => ({
          ...item,
          DescriptionStatus: false, // for checkBox control
        }));
        setIssueTypes(updatedData);
        setReportModal(true);
      }
    } catch (error) {
      toast.error("Beklenmedik bir hata oluştu");
    } finally {
      setLoader(false);
    }
  };

  const sendIssueReport = async (data) => {
    try {
      setLoader(true);
      const res = await sendReport(data, userToken);
      if (res.data.ResponseStatus === 1) {
        toast.success("Soru Başarıyla raporlandı");
        setReportModal(false);
      }
    } catch (error) {
      toast.error("Raporlanma sırasında bir sorun oluştu");
      setReportModal(false);
    } finally {
      setLoader(false);
    }
  };

  const openReportModal = () => {
    getIssues();
  };

  const onReportSubmit = (data) => {
    sendIssueReport(data);
  };

  return {
    issueTypes,
    reportModal,
    loader,
    openReportModal,
    onReportSubmit,
    setIssueTypes,
    setReportModal,
  };
};

import React, { useState, useEffect } from "react";
import { getTeachers } from "../api/onlineLecture";
import SideBar from "../components/SideBar";
import Loader from "../components/Loader";
import { Components } from "../assets/Dimensions";
// import MuiDialog from "@material-ui/core/Dialog";
// import MuiDialogContent from "@material-ui/core/DialogContent";
// import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from '@material-ui/styles';
import MuiIconButton from '@material-ui/core/IconButton';
import Axios from "axios";
import ResponseStatus from "../util/enums";
import {ToastContainer,toast} from 'react-toastify'
//import { withStyles } from '@material-ui/styles';

const AddTeacher = () => {
    const [teacherList, setTeacherList] = useState([])
    const [selectedTeacher, setSelectedTeacher] = useState(null)
    const [modifyTeacherModal, setModifyTeacherModal] = useState(false)
    const [addTeacherModal, setAddTeacherModal] = useState(false)
    const [thumbnailFile, setThumbnailFile] = useState(null)
    const [thumbnailPreview, setThumbnailPreview] = useState(null)
    const [loading, setLoading] = useState(false)
    const [teacherUpdateConfirmationModal,setTeacherUpdateConfirmationModal] = useState(false)
    const [updatedTeacherData,setUpdatedTeacherData] = useState(null)
    const [isUpdate,setIsUpdate] = useState(false)
    //const [loading, setLoading] = useState(false)
    const user = JSON.parse(localStorage.getItem("user"))
    const userAdminToken = sessionStorage.getItem("jwtP")
    const styles = {
        container: {
            marginLeft: Components.SideBarWidth + 15,
            paddingTop: 20,
            marginRight: 30,
        },
        rowActionIcon: {
            width: Components.Image.Big.Width,
            height: Components.Image.Big.Height
        },
        ChangeDateModalStyle: {
            padding: 20,
            margin: "auto"
        },
        iconButton: {
            right: 0,
            top: 0,
        }
    };
    const Dialog = withStyles({
        paperWidthSm: {
            maxWidth: 720,
            minWidth: 300,
            minHeight: 220,
        },
    })(MuiDialog);
    const DialogContent = withStyles({
        root: {
            paddingTop: 0,
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
        },
    })(MuiDialogContent);

    const DialogTitle = withStyles({
        root: {
            marginLeft: 20,
        },
    })(MuiDialogTitle);

    const IconButton = withStyles({
        label: {
            width: Components.Image.Huge.Width,
            height: Components.Image.Huge.Height
        }
    })(MuiIconButton);
    const imageUpload = async (event) => {
        // //     const file = event.target.files[0];

        // // setThumbnailFile(file);
        console.log("evet.target", event.target)
        // setThumbnailPreview(URL.createObjectURL(event.target.files[0]));
        const selectedFile = event.target.files[0];
        console.log("fileInput", selectedFile)
        setThumbnailFile(selectedFile);

        if (!selectedFile) {
            return;
        }



        const objectUrl = URL.createObjectURL(selectedFile); // Create temporary URL
        setThumbnailPreview(objectUrl);


        //addOnlineLecture(selectedFile)
    }
    const updateTeacher = async (data) =>{
        setLoading(true)
        const headers = {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + userAdminToken,
            "Content-Type": "multipart/form-data",
        };
        try {
            const res = await Axios.post(`https://api.pakodemy.com/api/OnlineLecture/${isUpdate?"updateteacher":"addteacher"}`, data, {
                headers: headers,
            });
            if (res.status == 200) {
                console.log("res==200")
                if (res.data.ResponseStatus == ResponseStatus.Success) {
                    console.log("resStat==1")
                    toast.success(`${isUpdate?"Öğretmen Başarıyla güncellenmiştir":"Ders başarıyla eklenmiştir"}`)
                    teachers()
                } else if (res.data.ResponseStatus == ResponseStatus.Error) {
                    console.log("res==0")
                    toast.error(res.data.ResponseMessage)
                    toast.error(`${res.data.ResponseMessage}`)
                }
            } else {
                console.log("res == error")

                toast.error("bir hata ile karşılaşıldı lütfen tekrar deneyiniz")
            }
        } catch (ex) {
            console.log(ex);
        }
        setLoading(false)
        setTeacherUpdateConfirmationModal(false)
        setSelectedTeacher(null)
        setThumbnailFile(null)
        setThumbnailPreview(null)

    }
    const teachers = async () => {
        setLoading(true)
        const res = await getTeachers(userAdminToken)
        if (res.status == 200) {
            setTeacherList(res.data.ResponseData)
            setLoading(false)
        }
    }
    useEffect(() => {
        teachers()
    }, [])
    const handleSubmit = async (event) => {
        event.preventDefault()
        const form = event.target;
        console.log("formElements", form)
        const formData = new FormData(form);
        formData.append("ImageUrl", thumbnailFile)
        formData.delete("teacherImage")
        formData.append("ChannelCode", "Web")
        if(isUpdate){
            formData.append("Id", selectedTeacher.Id)
            
        }
        console.log("formDataTeacher", formData)
        setUpdatedTeacherData(formData)
        setAddTeacherModal(false)
        setModifyTeacherModal(false)
        setTeacherUpdateConfirmationModal(true)
        // const headers = {
        //     "Access-Control-Allow-Origin": "*",
        //     Authorization: "Bearer " + userAdminToken,
        //     "Content-Type": "multipart/form-data",
        // };

        // try {
        //     const res = await Axios.post("https://devtest.tosanalytics.com/api/OnlineLecture/updateteacher", formData, {
        //         headers: headers,
        //     });
        //     if (res.status == 200) {
        //         console.log("res==200")
        //         if (res.data.ResponseStatus == ResponseStatus.Success) {
        //             console.log("resStat==1")
        //             toast.success("Ders başarıyla eklenmiştir")
        //         } else if (res.data.ResponseStatus == ResponseStatus.Error) {
        //             console.log("res==0")
        //             toast.error(res.data.ResponseMessage)
        //             toast.error(`${res.data.ResponseMessage}`)
        //         }
        //     } else {
        //         console.log("res == error")

        //         toast.error("bir hata ile karşılaşıldı lütfen tekrar deneyiniz")
        //     }
        // } catch (ex) {
        //     console.log(ex);
        // }
        
       
    }
    return (
        <div>
            <SideBar />
            <ToastContainer />
            <div style={{ ...styles.container }}>
                {teacherUpdateConfirmationModal&&(
                    <Dialog
                    open={teacherUpdateConfirmationModal}
                    onClose={() => setTeacherUpdateConfirmationModal(false)}
                >
                    <div style={styles.ChangeDateModalStyle}>
                        
                        <p >{updatedTeacherData.teacherName}</p>
                        <p>Bu Öğretmeni Güncellemek istermisiniz</p>
                        <div>
                           

                               
                                
                                <div style={{ "marginTop": 5 }}>
                                    <button
                                       
                                        onClick={() => {
                                            //deleteHomework(selectedHomework.Id)
                                            //setDisplayDateChange(false);
                                            updateTeacher(updatedTeacherData)
                                        }}
                                        style={{ marginRight: 10 }}
                                    // style={styles.deleteConfirm}
                                    >
                                        {isUpdate?"Öğretmeni Güncele":"Öğretmeni Ekle"}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setModifyTeacherModal(false);
                                            setTeacherUpdateConfirmationModal(false)
                                            setSelectedTeacher(null);
                                        }}
                                    // style={styles.deleteConfirmClose}
                                    >
                                        Vazgeç
                                    </button>
                                </div>
                            
                        </div>

                    </div>
                </Dialog>
                )}
                {modifyTeacherModal && (
                    <Dialog
                        open={modifyTeacherModal}
                        onClose={() => {setModifyTeacherModal(false);
                            setIsUpdate(false)}}
                    >
                        <div style={styles.ChangeDateModalStyle}>
                            <img style={{ "width": "200px" }} src={selectedTeacher.ImageUrl} />
                            <p >{selectedTeacher.TeacherName}</p>
                            <div>
                                <form method="post" onSubmit={handleSubmit}>

                                    <div>
                                        <label htmlFor="teacherImage">Thumbnail dosyasını seçiniz: </label>
                                        <input type="file" name="teacherImage" id="teacherImage" accept="image/png" onChange={imageUpload}></input>
                                        <div>
                                            {thumbnailPreview && <img style={{ "height": "120px", "width": "150px" }} src={thumbnailPreview} />}

                                        </div>

                                    </div>
                                    <div>
                                        <label htmlFor="teacherName">Başlık:</label>
                                        <input type="text" id="teacherName" name="teacherName" defaultValue={selectedTeacher.TeacherName} />
                                    </div>
                                    <div style={{ "marginTop": 5 }}>
                                        <button
                                            type="submit"
                                            onClick={() => {
                                                //deleteHomework(selectedHomework.Id)
                                                //setDisplayDateChange(false);
                                            }}
                                            style={{ marginRight: 10 }}
                                        // style={styles.deleteConfirm}
                                        >
                                            Öğretmeni Güncele
                                        </button>
                                        <button
                                            onClick={() => {
                                                setModifyTeacherModal(false);
                                                setSelectedTeacher(null);
                                                setIsUpdate(false)
                                            }}
                                        // style={styles.deleteConfirmClose}
                                        >
                                            Vazgeç
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </Dialog>
                )}
                 {addTeacherModal && (
                    <Dialog
                        open={addTeacherModal}
                        onClose={() => setAddTeacherModal(false)}
                    >
                        <div style={styles.ChangeDateModalStyle}>
                            <div>
                                <form method="post" onSubmit={handleSubmit}>

                                    <div>
                                        <label htmlFor="teacherImage">Thumbnail dosyasını seçiniz: </label>
                                        <input type="file" name="teacherImage" id="teacherImage" accept="image/png" onChange={imageUpload}></input>
                                        <div>
                                            {thumbnailPreview && <img style={{ "height": "120px", "width": "150px" }} src={thumbnailPreview} />}

                                        </div>

                                    </div>
                                    <div>
                                        <label htmlFor="teacherName">Başlık:</label>
                                        <input type="text" id="teacherName" name="teacherName"  />
                                    </div>
                                    <div style={{ "marginTop": 5 }}>
                                        <button
                                            type="submit"
                                            onClick={() => {
                                                //deleteHomework(selectedHomework.Id)
                                                //setDisplayDateChange(false);
                                            }}
                                            style={{ marginRight: 10 }}
                                        // style={styles.deleteConfirm}
                                        >
                                            Öğretmen Ekle
                                        </button>
                                        <button
                                            onClick={() => {
                                                setAddTeacherModal(false);
                                                setSelectedTeacher(null);
                                            }}
                                        // style={styles.deleteConfirmClose}
                                        >
                                            Vazgeç
                                        </button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </Dialog>
                )}
                <div className="container mb-4">

                    <button onClick={() => { setAddTeacherModal(true);setIsUpdate(false) }}>Öğretmen ekle</button>
                    <table>
                        <thead>
                            <tr>
                                <th>TeacherId</th>
                                <th>TeacherName</th>
                                <th>teacherImage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {teacherList.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.Id}</td>
                                    <td>{item.TeacherName}</td>
                                    <td>
                                        <img style={{ "height": "120px", "width": "150px" }} src={item.ImageUrl}></img>
                                    </td>
                                    <td><button onClick={() => {
                                        setSelectedTeacher(item)
                                        setModifyTeacherModal(true);
                                        setIsUpdate(true)
                                    }} style={{ border: "1px solid red", color: "red" }}>Öğretmeni Düzenle</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {loading ? <Loader></Loader> : <></>}                        
        </div>
    )
}

export default AddTeacher
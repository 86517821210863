import React, { useEffect, useState } from "react";
import ImageComponent from "../../components/kpssCompability/imageComponent";
import SubCourses from "../../components/kpssCompability/copmpatibilityOptions";
import { ToastContainer, toast } from "react-toastify";
import SideBar from "../../components/SideBar";
import { useMediaQuery } from "react-responsive";
import { Components } from "../../assets/Dimensions";
import "./style.css";
import MenuIcon from "@material-ui/icons/Menu";
import { useHistory } from "react-router-dom";
import {
  creatAchievementQuestion,
  getAchievementQuestion,
  updateQuestionAchievements,
} from "../../api/QuestionAchivments/coursesAndQuestions";
import { getKpssQuestion , updateKpssQuestion } from "../../api/kpssCompatibility/kpssQuestions";
import { getQuestionAchievements } from "../../api/QuestionAchivments/coursesAndQuestions";
import Loader from "@material-ui/core/CircularProgress";
import Modal from "../../components/cutomModal";
import ResponseStatus from "../../util/enums";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import QuestionReportModal from "../../components/ReportModal/Components/Modal/Modal";
import AdminPanelPage from "../../enums/AdminPanelPage";
import { useReportIssues } from "../../hooks/useReportIssue";

const QuestionPage = (props) => {
  const { selectedCourseName,selectedSubCourseTopicName,selectedSubCourseData } = props.location.state;
  console.log("<<<<<<selectedSubCourseData>>>>>>", selectedSubCourseData);
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const userAdminToken = sessionStorage.getItem("jwtP");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const [questionData, setQuestionData] = useState(null);
  const [nav, setNav] = useState(false);
  const [achievementsForQuestion, setAchievementsForQuestion] = useState(null);
  const [
    secondaryAchievementsForQuestion,
    setSecondaryAchievementsForQuestion,
  ] = useState(null);
  const [notEnoughQuestionModal, setNotEnoughQuestionModal] = useState(false);
  const [completionModal, setCompletionModal] = useState(false);
  const [noAchievementSelectedModal, setNoAchievementSelectedModal] =
    useState(false);
  const [modalMessage, setModalMessage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isQuestionSkiped, setIsQuestionSkiped] = useState(false);

  const {
    issueTypes,
    reportModal,
    openReportModal,
    onReportSubmit,
    setIssueTypes,
    setReportModal,
  } = useReportIssues(user.Token);

  const style = {
    container: {
      //state e bool değer ekle menü butonuna basıldığında css
      //içinde container a margin eklesin hemde navı açsın

      //marginLeft: isTabletOrMobile ? 0 : Components.SideBarWidth + 15,
      paddingLeft: isTabletOrMobile ? 15 : Components.SideBarWidth + 15,
      paddingTop: 20,
      paddingRight: 15,
      marginBottom: 50,
      marginTop: "4rem",
    },
    editIcon: {
      width: Components.Image.Big.Width,
      height: Components.Image.Big.Height,
    },
    iconButton: {
      marginLeft: 10,
      padding: 0,
    },
  };

  const previousQuestion = () => {
    getKpssCompatibilityQuestions(questionData.Order,true,selectedSubCourseData);
  };
  const nextQuestion = () => {
    getKpssCompatibilityQuestions(questionData.Order,false,selectedSubCourseData);
  };
  const updateKpssQuestionCompatibility = async (question,KpssCompatibility) => {
    setLoader(true)
    const data = {
        BookSectionCropId: question.BookSectionCropId,
        KpssCompatibility: KpssCompatibility,
        
      };
    const res = await updateKpssQuestion(user.Token,data)
    if(res.status == 200){
        if(res.data.ResponseStatus == ResponseStatus.Success){
            getKpssCompatibilityQuestions(question.Order,false,selectedSubCourseData)
            setLoader(false)
        }else{
            toast.error("Bir hata oluştu lütfen yetkililere ulaşınız");
            setLoader(false)
            return
        }
    }else{
        toast.error("Bir hata oluştu lütfen yetkililere ulaşınız");
        setLoader(false)
            return
    }
  }

  const getKpssCompatibilityQuestions = async (order,isPrev,selectedSubCourseData) => {
    setLoader(true)
    try {
      console.log("courseData", selectedSubCourseData);
      const data = {
        Order: order,
        SubCourseTopicUniqueId: selectedSubCourseData,
        isPrev:isPrev
      };
      console.log("<<<<<Requestdata>>>>>",data);
      setLoader(true); // Set loader to true when starting the request
      const res = await getKpssQuestion(user.Token, data);
      console.log("ressss", res);

      if (res.data.ResponseStatus === 1) {
        if(res.data.ResponseData == null){
          toast.error(res.data.ResponseMessage)
          history.goBack()

        }
        setQuestionData(res.data.ResponseData);
        setIsQuestionSkiped(res.data.ResponseData.Skipped);
        console.log("res.data.ResponseData", res.data.ResponseData);


     
      } else if (res.data.ResponseStatus === 3) {
        setLoader(false);
        console.log("No questions found");
        toast.success(res.data.ResponseMessage)
        history.push("/KpssCompatibilityCourses")
      } else {
        setLoader(false);
        toast.error("Bir hata oluştu lütfen yetkililere ulaşınız");
      }
    } catch (error) {
      setLoader(false);
      toast.error("Bir hata oluştu lütfen yetkililerle iletişime geçiniz.");
      console.error("Error fetching achievement questions:", error);
    } finally {
      setLoader(false); // Ensure loader is set to false regardless of success or failure
    }
  };

  useEffect(() => {
    getKpssCompatibilityQuestions(0,false,selectedSubCourseData);
  }, []);


  return (
    <>
      {isTabletOrMobile ? (
        <div>
          <span
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={() => setNav(!nav)}
          >
            <MenuIcon />
          </span>
          {nav && <SideBar />}
        </div>
      ) : (
        <SideBar />
      )}
      <ToastContainer />
      {loader && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader size={100} />
        </div>
      )}
      <div style={style.container}>
      {questionData && (
  <div
    style={{
      position: "absolute",
      marginBottom: 20,
      display: "flex",
      flexDirection: "column",
      top: "1rem",
      left: isTabletOrMobile ? 15 : Components.SideBarWidth + 15,
      gap: 5,
    }}
  >
    <button
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",  // Ensure the content is vertically centered
        width: "100%",          // Make sure the button takes up full width
      }}
      onClick={() => {
        history.goBack();
      }}
      className="prevButton"
    >
      <ArrowBackIcon />
    </button>
    <div>
      {selectedCourseName}-{selectedSubCourseTopicName}
    </div>
  </div>
)}


        {noAchievementSelectedModal && (
          <Modal
            isOpen={noAchievementSelectedModal}
            onClose={() => setNoAchievementSelectedModal(false)}
            title={"Kazanim isartelemediniz."}
            content={
              "Soruyu gecmek icin once kazanim veya “Kazanimi yoktur” secenegini isaretlemilisiniz."
            }
            buttonText={"Dersler Sayfasına Dön"}
            onButtonClick={() => history.goBack()}
            closeButtonText={"Soruda Kal"}
          />
        )}

        {notEnoughQuestionModal && (
          <Modal
            isOpen={notEnoughQuestionModal}
            onClose={() => setNotEnoughQuestionModal(false)}
            title={"Tebrikler"}
            content={modalMessage}
            buttonText={"Dersler Sayfasına Dön"}
            onButtonClick={() => history.goBack()}
            closeButtonText={"Soruda Kal"}
          />
        )}
        {completionModal && (
          <Modal
            isOpen={completionModal}
            onClose={() => {
              setCompletionModal(false);
            }}
            // title={"Tebrikler"}
            content={modalMessage}
            buttonText={"Dersler Sayfasına Dön"}
            onButtonClick={() => history.goBack()}
            closeButtonText={"Devam Et"}
          />
        )}
        {reportModal && (
          <QuestionReportModal
          onClose={() => setReportModal(false)}
          responseData={issueTypes}
          setResponseData={setIssueTypes}
          isLoading={loader}
          onSubmit={onReportSubmit}
          adminPanelPage={AdminPanelPage.KpssCompatibilitySelection}
          BookSectionCropId={questionData.BookSectionCropId}
          />
        )}

        {questionData && (
          <div>
            <div
              className="questionContainer"
              // style={{display:"flex",direction:"column",flexWrap: "wrap",gap:"5rem" }}
            >
              <div
                className="questionImage"
                style={
                  {
                    // Enable vertical scrollbar when content overflows
                    // Set the maximum height of the component to 100vh
                    //flex:70
                    // overflow:"auto",
                    // maxWidth:"40%",
                    // maxHeight:window.screen.height/1.5
                  }
                }
              >
                <ImageComponent openReportModal={openReportModal} questionData={questionData} />
              </div>
              <div
                className="topicsContainer"
                style={
                  {
                    // overflowY: "auto", // Enable vertical scrollbar when content overflows
                    // Set the maximum height of the component to 100vh
                    //flex:30
                    // overflow:"auto",
                    // maxWidth:"40%",
                    // height:"auto",
                    // maxHeight:"100vh"
                  }
                }
              >
                <SubCourses
                  updateKpssQuestionCompatibility={
                    updateKpssQuestionCompatibility
                  }
                  question={questionData}
                  nextQuestion={nextQuestion}
                  previousQuestion={previousQuestion}
                  questionOrder={questionData.Order}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionPage;

import { TaskActions } from './../ActionTypes';

const initialState = {
    Tasks: [],
    RowCount: 0
};

export const taskReducer = (state = initialState, action) => {
    switch (action.type) {
        case TaskActions.SetAll:
            return Object.assign({}, state, { 
                Tasks: action.tasks,
                RowCount: action.rowCount
            });
        default:
            return state;
    }
};
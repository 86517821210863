import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/styles';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiRadio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
//#endregion

//#region Internal
import { Text } from './../../assets/Strings';
import { Components } from './../../assets/Dimensions';

import ApproveButton from './../../components/Base/ApproveButton';
import { setAnswer } from '../../redux/actions/answerAction';
import { Checkbox } from '@material-ui/core';
//#endregion

//#region Override Style
const FormControlLabel = withStyles({
    labelPlacementTop: {
        marginLeft: 2,
        marginRight: 2
    }
})(MuiFormControlLabel);

const Radio = withStyles({
    root: {
        width: Components.Image.Big.Width,
        height: Components.Image.Big.Height
    }
})(MuiRadio);
//#endregion

//#region Inline Style
const styles = {
    container: {
        marginBottom: 10,
        paddingBottom: 5,
        width: '100%',
        position: 'sticky',
        zIndex: 1000,
        top: 75,
        backgroundColor: '#f9f6f6cf'
    },
    button: {
        marginTop: 0,
        marginLeft: 30
    }
};
//#endregion

class AnswerKey extends Component {
    constructor(props){
        super(props);

        this._answers = ['A','B','C','D','E'];
        this._difLevels = [
            {
                Label: Text.SoEasy,
                Value: 1
            },
            {
                Label: Text.Easy,
                Value: 2
            },
            {
                Label: Text.MidLevel,
                Value: 3
            },
            {
                Label: Text.Hard,
                Value: 4
            },
            {
                Label: Text.SoHard,
                Value: 5
            }
        ];
        this._user = JSON.parse(localStorage.getItem("user"));
    }

    _answerChange = (e) => {
        this.props.setAnswer(e.target.value);
    };
   
    render(){
        return(
            <div style={{...styles.container}}>
                <ApproveButton 
                    text={Text.PreviousQuestion} 
                    style={{...styles.button}}
                    onClick={this.props.previousQuestion}    
                />

                <RadioGroup id='answerPanel' value={this.props.Answer} onChange={this._answerChange} row style={{
                    display: 'inline-block',
                    marginLeft: 16
                }}>
                    {this._answers.map((item) => (
                        <FormControlLabel
                            key={item}
                            defaultValue=''
                            value={item}
                            control={
                                    <Radio
                                        key={item}
                                        checked={this.props.Answer === item}
                                        color='primary' 
                                    />
                                }
                            label={item}
                            labelPlacement='top'
                        />
                    ))}
                </RadioGroup>

                <ApproveButton 
                    id={'nextQuestion'}
                    text={this.props.nextQuestionText} 
                    style={{...styles.button}}
                    onClick={this.props.nextQuestion}
                />

                {this.props.croppedDialog &&
                    <ApproveButton
                        text={Text.CroppedImageButton}
                        style={{...styles.button}}
                        onClick={this.props.croppedDialog}
                    />
                }
                
                {this.props.s3CroppedDialog &&
                    <ApproveButton
                        text={Text.FromS3Image}
                        style={{
                            marginTop: 0,
                            display: 'inline-block',
                            padding: '5px 8px',
                            marginLeft: 20
                        }}
                        onClick={this.props.s3CroppedDialog}
                    />
                }
       {   this._user.Role === "Administrators" && <FormControlLabel
            control={<Checkbox value={this.props.SyncWithProduction} checked={this.props.SyncWithProduction} onChange={(s,w)=>{this.props.onChangeSyncWithProduction(w)}} />}
            label="Should Update Prod"
          />}
                          
            </div>
        )
    }
}

AnswerKey.propTypes = {
    previousQuestion: PropTypes.func.isRequired,
    nextQuestion: PropTypes.func.isRequired,
    croppedDialog: PropTypes.func,
    nextQuestionText: PropTypes.string
};

AnswerKey.defaultProps = {
    nextQuestionText: Text.NextQuestion
};

const mapStateToProps = (state) => ({
    Answer: state.answerReducer.Answer
    
});

const mapDispatchToProps = (dispatch) => ({
    setAnswer: (answer) => dispatch(setAnswer(answer))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AnswerKey));
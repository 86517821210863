import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

//#region Internal
import { Primary } from './../assets/Colors';
import { Text } from './../assets/Strings';

import { Notification, error, warning } from './../components/Notification/index';
import FormPanel from './../components/Base/FormPanel';

import EmailIcon from './../assets/images/email.png';
import LockIcon from './../assets/images/lock.png';
import UserIcon from './../assets/images/user.png';
import PhoneIcon from './../assets/images/phone.png';
import Logo from './../assets/images/logo.png';

import { register } from './../api/User';
//#endregion

//#region Inline Style
const styles = {
    main:{
        backgroundColor: Primary.Background,
        width: '100%',
        height: '100%',
        position: 'absolute'
    },
    container:{
        backgroundColor: Primary.ContainerBackground,
        borderRadius: 10,
        boxShadow: '0 1px 4px 0 rgba(0,0,0,.25)',
        display: 'flex',
        width: 680,
        position: 'absolute',
        margin: 'auto',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: 'fit-content'
    },
    logoContainer: {
        backgroundColor: '#003ce5',
        float: 'right',
        width: '40%',
        textAlign: 'center',
        position: 'relative'
    },
    logo: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        width: 210,
        height: 130
    }
};
//#endregion

class SignUpPage extends Component {
    constructor(props){
        super(props);

        this._fieldNames = ['UserName','Name','Surname','Email','PhoneNumber','Password'];

        this.state={};
    }

    _validation = () => {
        for(var key in this._fieldNames){
            let val = this._fieldNames[key];
            if(document.getElementById(val).value === ''){
                let msg = Text[val];
                return `Lütfen '${msg}' kısmını doldurunuz!`;
            }
        }
        return '';
    };

    _signUp = async () => {
        let validationResult = this._validation();
        if(validationResult !== ''){
            warning(validationResult, 2000);
        } else {
            let newUser = {
                UserName: document.getElementById('UserName').value,
                Name: document.getElementById('Name').value,
                Surname: document.getElementById('Surname').value,
                Email: document.getElementById('Email').value,
                PhoneNumber: document.getElementById('PhoneNumber').value,
                Password: document.getElementById('Password').value
            };
            let res = await register(newUser);
            if(res.status === 200){
                this.props.history.push({
                    pathname: "/login",
                    state: {
                        isRegistered: true
                    }
                });
            } else {
                error(res, 2000);
            }
        }
    };

    _getFormComponents = () => {
        let components = [
            {
                id: 'UserName',
                label: Text.UserName,
                type: 'text',
                icon: UserIcon
            },
            {
                id: 'Name',
                label: Text.Name,
                type: 'text',
                icon: UserIcon
            },
            {
                id: 'Surname',
                label: Text.Surname,
                type: 'text',
                icon: UserIcon
            },
            {
                id: 'Email',
                label: Text.Email,
                type: 'email',
                icon: EmailIcon
            },
            {
                id: 'PhoneNumber',
                label: Text.PhoneNumber,
                type: 'text',
                icon: PhoneIcon
            },
            {
                id: 'Password',
                label: Text.Password,
                type: 'password',
                icon: LockIcon
            }
        ];
        return components;
    };
    
    render(){
        let components = this._getFormComponents();

        return(
            <div style={{...styles.main}}>
                <div style={{...styles.container}}>
                    <FormPanel
                        title={Text.SignUp}
                        components={components}
                        footer={{
                            button:{
                                onClick: this._signUp,
                                text: Text.SignUp
                            },
                            text: Text.HaveAccount,
                            link: './login',
                            linkText: Text.Login
                        }}
                    />

                    <div style={{...styles.logoContainer}}>
                        <img
                            alt='logoIcon'
                            src={Logo}
                            style={{...styles.logo}}
                        />
                    </div>
                </div>
                <Notification/>
            </div>
        )
    }
}

SignUpPage.propTypes = {
};

SignUpPage.defaultProps = {
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpPage));

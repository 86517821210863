import { CourseActions } from './../ActionTypes';

const initialState = {
    Courses: []
};

export const courseReducer = (state = initialState, action) => {
    switch (action.type) {
        case CourseActions.SetAll:
            return Object.assign({}, state, { 
                Courses: action.courses,
            });
        default:
            return state;
    }
};
import React, { useState } from 'react';
import {
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

//#endregion

//#region Internal
import { Primary } from '../../assets/Colors';
//#endregion

//#region Inline Style
const styles = {
  container: {
    backgroundColor: Primary.ContainerBackground,
    padding: 10,
    borderRadius: 6,
    boxShadow: '0 2px 5px 0 rgba(0,0,0,.26)',
    minHeight: 55,
    position: 'sticky',
    top: 0,
    zIndex: 1000,
    display: 'flow-root',
  },
  space: { marginLeft: 20 },
};
//#endregion

const FilterHeader = (props) => {
  const [status, setStatus] = useState(2);
  const [dstatus, setdStatus] = useState(3);
  const [cropStatus, setCropStatus] = useState(null);

  return (
    <div style={{ ...styles.container }}>
      <ButtonGroup disableElevation variant="contained" color="primary">
        <Button onClick={() => props.setOrder(0)}>Order By Date</Button>
        <Button onClick={() => props.setOrder(1)}>Order By Count</Button>
      </ButtonGroup>
      <div style={{ height: 1, backgroundColor: '#cdcd' }}></div>
      <ButtonGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={status === 0}
              onChange={() => {
                setStatus(0);
                props.setStatus(0);
              }}
              name="checkedB"
              style={styles.space}
              color="primary"
            />
          }
          label="Status 0"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={status === 1}
              onChange={() => {
                setStatus(1);
                props.setStatus(1);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Status 1"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={status === 2}
              onChange={() => {
                setStatus(2);
                props.setStatus(2);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Status All"
        />
      </ButtonGroup>

      <div style={{ height: 1, backgroundColor: '#cdcd' }}></div>
      <ButtonGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={dstatus === 1}
              onChange={() => {
                setdStatus(1);
                props.setdStatus(1);
              }}
              name="checkedB"
              style={styles.space}
              color="primary"
            />
          }
          label="Son 30 gün"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={dstatus === 3}
              onChange={() => {
                setdStatus(3);
                props.setdStatus(3);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Son 3 ay"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={dstatus === 0}
              onChange={() => {
                setdStatus(0);
                props.setdStatus(0);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label="Tüm Zamanlar"
        />
      </ButtonGroup>
      <div style={{ height: 1, backgroundColor: '#cdcd' }}></div>
      <ButtonGroup>
        <FormControlLabel
        control={
          <input
          type='text'
          required={true}
          value={cropStatus}
          onChange={((e)=>setCropStatus(e.target.value))}
          />
        }
        />
        <button onClick={()=>{
          console.log("cropStatusstate",cropStatus)
          if(cropStatus){
            props.setCropId(cropStatus)
            props.setModal(cropStatus)
          }else{
            props.setCropId()
          }
          }}>check Crop ID</button>
      </ButtonGroup>
    </div>
  );
};

export default FilterHeader;

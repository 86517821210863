import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//#region Internal
import { Primary } from './../assets/Colors';
import { Text } from './../assets/Strings';

import FormPanel from './../components/Base/FormPanel';
import { Notification, success, warning, error } from './../components/Notification/index';

import EmailIcon from './../assets/images/email.png';
import LockIcon from './../assets/images/lock.png';
import Logo from './../assets/images/logo.png';

import { login, loginP } from './../api/User';
import { getUserDetail } from './../api/UserDetail';
import { loginUser } from './../redux/actions/userAction';
//#endregion

//#region Inline Style
const styles = {
    main:{
        backgroundColor: Primary.Background,
        width: '100%',
        height: '100%',
        position: 'absolute'
    },
    container:{
        backgroundColor: Primary.ContainerBackground,
        borderRadius: 10,
        boxShadow: '0 1px 4px 0 rgba(0,0,0,.25)',
        display: 'flex',
        width: 680,
        position: 'absolute',
        margin: 'auto',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: 'fit-content'
    },
    logoContainer: {
        backgroundColor: '#003ce5',
        float: 'right',
        width: '40%',
        textAlign: 'center',
        position: 'relative'
    },
    logo: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        width: 210,
        height: 130
    }
};
//#endregion

class LoginPage extends Component{
    constructor(props){
        super(props);

        this._fieldNames = ['Email','Password'];

        this.state={};
    }

    componentWillUnmount = () => {
        document.removeEventListener('keydown', this._enterFunc, false);
    };

    componentWillMount = () => {
        this._user = JSON.parse(localStorage.getItem('user'));
        if(this._user !== null){
            this.props.history.push('/userprofile');
            return;
        }
    };

    componentDidMount = () => {
        if (this.props.location.state !== undefined) {
            success(Text.RegisterSuccess, 2000);
        }
        document.addEventListener('keydown', this._enterFunc, false);
    };

    _enterFunc = async (e) => {
        if(e.keyCode === 13) {
            await this._login();
        }
    };

    _validation = () => {
        for(var key in this._fieldNames){
            let val = this._fieldNames[key];
            if(document.getElementById(val).value === ''){
                let msg = Text[val];
                return `Lütfen '${msg}' kısmını doldurunuz!`;
            }
        }
        return '';
    };

    _login = async () => {
        let validationResult = this._validation();
        if(validationResult !== ''){
            warning(validationResult, 2000);
        } else {
            let user = {
                UserNameOrEmail: document.getElementById('Email').value,
                Password: document.getElementById('Password').value
            };
            let response = await login(user);
            let responseP = await loginP(user);
            if(responseP.status === 200){
                sessionStorage.setItem("jwtP",responseP.data.Token)
            }
            if(response.status === 200){
                let res = await getUserDetail({UserId: response.data.UserId}, response.data.Token);
                if(res.status === 200){
                    let user = {
                        Id: response.data.UserId,
                        Name: res.data.Name,
                        Surname: res.data.Surname,
                        UserName: res.data.UserName,
                        Email: res.data.Email,
                        PhoneNumber: res.data.PhoneNumber,
                        Iban: res.data.Iban,
                        DeservedAmount: res.data.DeservedAmount,
                        PaidAmount: res.data.PaidAmount,
                        Role: res.data.Role,
                        Token: response.data.Token,
                        TotalEnteredQuestion: res.data.TotalEnteredQuestion,
                        TotalApprovedQuestion: res.data.TotalApprovedQuestion,
                        TCKN: res.data.TCKN
                    };

                    this.props.setUser(user);
                    localStorage.setItem('user', JSON.stringify(user));
                    this.props.history.push('/userprofile');
                } else {
                    error(res, 2000);    
                }
            } else {
                error(response, 2000);
            }
        }
    };

    _getFormComponents = () => {
        let components = [
            {
                id: 'Email',
                label: Text.EmailOrUserName,
                type: 'text',
                icon: EmailIcon
            },
            {
                id: 'Password',
                label: Text.Password,
                type: 'password',
                icon: LockIcon
            }
        ];
        return components;
    };

    render(){
        let components = this._getFormComponents();

        return(
            <div style={{...styles.main}}>
                <div style={{...styles.container}}>
                    <FormPanel
                        title={Text.Login}
                        components={components}
                        footer={{
                            button:{
                                onClick: this._login,
                                text: Text.Login
                            },
                            text: Text.NoAccount,
                            link: './signup',
                            linkText: Text.SignUp
                        }}
                    />

                    <div style={{...styles.logoContainer}}>
                        <img
                            alt='logoIcon'
                            src={Logo}
                            style={{...styles.logo}}
                        />
                    </div>
                </div>
                <Notification/>
            </div>
        );
    }
}

LoginPage.propTypes = {
    User: PropTypes.object
};

LoginPage.defaultProps = {
};

const mapStateToProps = (state) => ({
    User: state.userReducer.User
});

const mapDispatchToProps = (dispatch) => ({
    setUser: (user) => dispatch(loginUser(user))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginPage));
const ResponseStatus =
{
    Error: 0,
    Success: 1,
    Restriction: 2,
    DataIsNull: 3
};

export default ResponseStatus


import { CourseTopicActions } from './../ActionTypes';

const initialState = {
    CourseTopics: []
};

export const courseTopicReducer = (state = initialState, action) => {
    switch (action.type) {
        case CourseTopicActions.SetAll:
            return Object.assign({}, state, { 
                CourseTopics: action.courseTopics
            });
        default:
            return state;
    }
};
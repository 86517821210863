import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

//#region Internal
import SideBar from "../components/SideBar";
import PageHeader from "../components/Base/ReportFilter";
import Grid from "../components/Grid/index";
import DialogPanel from "../components/Base/FeedBackEditDialog";
import {
  Notification,
  success,
  error,
  warning,
} from "../components/Notification/index";
import getRows from "../util/getRows";

import { Components } from "../assets/Dimensions";
import { Text } from "../assets/Strings";

import { sendBackAssignee } from "../api/BookSection";

import { setSelectedBookSectionId } from "../redux/actions/bookSectionAction";
import { setSelectedItem } from "../redux/actions/sidebarAction";
import { setTasks } from "../redux/actions/taskAction";
import { setLinks, addLink } from "../redux/actions/pageHeaderLinkAction";
import { setPageNumber } from "../redux/actions/gridAction";
import { logoutUser } from "../redux/actions/userAction.js";
import {
  getReports,
  updateAnswer,
  updateReport,
  updateCropAnswer,
} from "../api/FeedBacks";
//#endregion

//#region Variables
const _pageSize = 10;
//#endregion

//#region Inline Style
const styles = {
  container: {
    marginLeft: Components.SideBarWidth + 15,
    paddingTop: 20,
    marginRight: 30,
  },
};
//#endregion

class WorkListPage extends Component {
  constructor(props) {
    super(props);

    this._editFieldNames = ["editAssigneeUser"];
    this._editRowData = [];
    this._sendBackRow = [];
    this._rows = [];
    this._columns = [];
    this._user = {};
    this._selectedCropId = [];

    this.state = {
      editDialog: false,
      sendBackDialog: false,
      statusType: 2,
      statusDate: 3,
      orderBy: 0,
      cropId:null,
    };
  }

  componentWillMount() {
    this.props.setLinks([
      {
        Text: "FeedBacks",
        href: "/feedbacks",
      },
    ]);
  }

  async componentDidMount() {
    this._user = JSON.parse(localStorage.getItem("user"));
    if (this._user === null) {
      localStorage.clear();
      this.props.logOutUser();
      this.props.history.push("/login");
      return;
    }

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.isFinished === true
    ) {
      success(Text.FinishedSection, 2000);
    }

    this.props.setPageNumber(0);
    this.props.setSelectedSideBar("FeedBacks");

    let { Tasks, RowCount } = await this._getAllTasks();
    this.props.setTasks(Tasks, RowCount);
  }

  async shouldComponentUpdate(nextProps, nextState) {
    if (this.props.Tasks !== nextProps.Tasks) {
      return true;
    }
    if (this.props.PageNumber !== nextProps.PageNumber) {
      // let { Tasks, RowCount } = await this._getAllTasks(nextProps.PageNumber);
      // this.props.setTasks(Tasks, RowCount);
      const firstIndex = _pageSize * nextProps.PageNumber;
      const secondIndex = firstIndex + _pageSize;
      const rowData = this.props.Tasks.slice(firstIndex, secondIndex);
      this._rows = this._getRows(rowData) || [];
      return true;
    }
    if (this.state !== nextState) {
      return true;
    }
    if (this.state.statusType !== nextState.statusType) {
      this._getAllTasks(1);
    }
    if (this.state.statusDate !== nextState.statusDate) {
      this._getAllTasks(1);
    }
    return false;
  }

  _handleClose = (name) => {
    this.setState({
      [name]: false,
    });
  };
  
  getCropIdFromFilter = (e)=>{
    return this.setState({
      cropId:e
    })
  }
  modalControl = (e)=>{
    return this.setState({
      editDialog:e
    })
  }

  _getAllTasks = async (pageNumber = this.props.PageNumber) => {
    let paging = {
      UserId: this._user.Id,
      PageSize: _pageSize,
      PageNumber: pageNumber,
    };
//filtre payloadları buradan atılıyor
    const params = {
      MonthCount: this.state.statusDate,
      StatusType: this.state.statusType, // 0 --> Calls "Status = 0" ,  1 --> Calls "Status = 1" , 2 --> Calls "Status All"
      OrderBy: this.state.orderBy,// 0 --> Order by date , 1 --> Order By Count
      //CropId: this.state.cropId, 
    };
    let res = await getReports(params, this._user.Token);
    if (res.status === 200) {
      this._columns = res.data.length > 0 ? Object.keys(res.data[0]) : [];
      const firstIndex = _pageSize * pageNumber;
      const secondIndex = firstIndex + _pageSize;
      const rowData = res.data.slice(firstIndex, secondIndex);
      this._rows = this._getRows(rowData) || [];
      return { Tasks: res.data, RowCount: res.data.length };
    } else {
      error(res, 2000);
      return { Tasks: [], RowCount: 0 };
    }
  };

  _editRow = (e) => {
    try {
      this._editRowData = e;
      //this._selectedCropId = e;

      this.setState({
        editDialog: true,
      });
    } catch (error) {}
  };

  markAsDone = async (row) => {
    let res = await updateReport(row[3].content, this._user.Token);
    if (res.status === 200) {
      success(Text.MarkedAsDone, 2000);

      let { Tasks, RowCount } = await this._getAllTasks();

      this.setState({
        editDialog: false,
      });

      this.props.setTasks(Tasks, RowCount);
    } else {
      error(res, 2000);
    }
  };
  markAsDoneNEW = async (row) => {
    //bookSectionCropId
    let res = await updateReport(row, this._user.Token);
    if (res.status === 200) {
      success(Text.MarkedAsDone, 2000);

      let { Tasks, RowCount } = await this._getAllTasks();

      this.setState({
        editDialog: false,
      });

      this.props.setTasks(Tasks, RowCount);
    } else {
      error(res, 2000);
    }
  };

  onClickAnswerChange = async (row, option) => {
    this.setState({
      editDialog: false,
    });
    const params = {
      BookSectionCropId: row[3].content,
      AnswerOption: option,
    };
    const updateCropAnswerRes = await updateCropAnswer(
      params,
      this._user.Token
    );
    if (updateCropAnswerRes.status === 200) {
      let res = await updateAnswer(params, this._user.Token);
      if (res.status === 200) {
        success(Text.UpdatedAnswer, 2000);

        let { Tasks, RowCount } = await this._getAllTasks();

        this.props.setTasks(Tasks, RowCount);
      } else {
        error(res, 2000);
      }
    } else {
      error(updateCropAnswerRes, 2000);
    }
  };
  onClickAnswerChangeNEW = async (row, option) => {
    this.setState({
      editDialog: false,
    });
    const params = {
      BookSectionCropId: row,
      AnswerOption: option,
    };
    const updateCropAnswerRes = await updateCropAnswer(
      params,
      this._user.Token
    );
    if (updateCropAnswerRes.status === 200) {
      let res = await updateAnswer(params, this._user.Token);
      if (res.status === 200) {
        success(Text.UpdatedAnswer, 2000);

        let { Tasks, RowCount } = await this._getAllTasks();

        this.props.setTasks(Tasks, RowCount);
      } else {
        error(res, 2000);
      }
    } else {
      error(updateCropAnswerRes, 2000);
    }
  };

  _getRows = (data) => {
    return getRows(data, null, null, null);
  };

  _onRowClick = (e) => {
    this.props.setSelectedBookSectionId(e[4].content);
    localStorage.setItem("bookSectionId", e[4].content);
    localStorage.setItem("targetQuestion", e[5].content);
    this.props.Links.push({
      Text: "Test",
      href: "/booksectioncrop",
    });
    sessionStorage.setItem("fromFeedBack",true)
    this.props.addLink(this.props.Links);
    const url = `/booksectioncrop`;
    window.open(url, '_blank');
    //this.props.history.push("/booksectioncrop");
  };
  _onRowClickNEW = (e) => {
    //bookSectionId
    //QuestionNumber
    this.props.setSelectedBookSectionId(e.BookSectionId);
    localStorage.setItem("bookSectionId", e.BookSectionId);
    localStorage.setItem("targetQuestion", e.QuestionNumber);
    this.props.Links.push({
      Text: "Test",
      href: "/booksectioncrop",
    });
    sessionStorage.setItem("fromFeedBack",true)
    this.props.addLink(this.props.Links);
    const url = `/booksectioncrop`;
    window.open(url, '_blank');
    //this.props.history.push("/booksectioncrop");
  };
  _sendBack = async (e) => {
    if (this._sendBackRow[0].TaskStatus === 5) {
      let req = {
        BookSectionId: this._sendBackRow[0].Id,
      };
      let res = await sendBackAssignee(req, this._user.Token);
      if (res.status === 200) {
        success(Text.SendBackAssignee);

        let { Tasks, RowCount } = await this._getAllTasks();
        this.props.setTasks(Tasks, RowCount);

        this.setState({
          sendBackDialog: false,
        });
      } else {
        error(res, 2000);
      }
    } else {
      warning(Text.SendBackWarning, 2000);
    }
  };

  _sendBackOpen = (e) => {
    let selectedRow = this.props.Tasks.filter(
      (row) => row.Id.toString() === e.currentTarget.id
    );
    this._sendBackRow = selectedRow;
    this.setState({
      sendBackDialog: true,
    });
  };

  changeFilter = async () => {
    let { Tasks, RowCount } = await this._getAllTasks();

    this.props.setTasks(Tasks, RowCount);
  };

  sorrt = (key) => {
    const compare = (a, b) => {
      if (a[key] < b[key]) {
        return -1;
      }
      if (a[key] > b[key]) {
        return 1;
      }
      return 0;
    };
    return this.props.Tasks.sort(compare);
  };
  render() {
    return (
      <div>
        <SideBar />

        <div style={{ ...styles.container }}>
          <PageHeader
            title="FeedBacks"
            setStatus={(status) => {
              this.setState({ statusType: status });
              setTimeout(() => {
                this.changeFilter();
              }, 100);
            }}
            setdStatus={(status) => {
              this.setState({ statusDate: status });
              setTimeout(() => {
                this.changeFilter();
              }, 100);
            }}
            setOrder={(order) => {
              this.setState({ orderBy: order });
              setTimeout(() => {
                this.changeFilter();
              }, 100);
            }}
            setModal={this._editRow}
            setCropId={this.getCropIdFromFilter}
            
          />

          <Grid
            key={this.props.Tasks}
            stickyColumnCount={0}
            data={{
              columns: this._columns,
              rows: this._rows,
            }}
            paging={{
              rowsPerPage: _pageSize,
              count: this.props.RowCount,
            }}
            headerClick={(s) => {
              // this._rows = this._getRows(arr)
            }}
            options={this._editRow}
          />
        </div>

        <DialogPanel
          user={this._user}
          id="editDialog"
          isOpen={this.state.editDialog}
          onClose={() => this._handleClose("editDialog")}
          title={Text.EditWorkList}
          actions={[
            {
              onClick: () => this._handleClose("editDialog"),
              text: Text.Cancel,
            },
            { onClick: this._editTask, text: Text.Edit },
          ]}
          contentComponentNames={this._editFieldNames}
          editRow={this._editRowData}
          selectedCropId = {this.state.cropId}
          onClickAnswerChange={(row, option) =>
            this.onClickAnswerChange(row, option)
          }
          onClickAnswerChangeNEW={(row, option) =>
            this.onClickAnswerChangeNEW(row.Id, option)
          }
          markAsDone={(row) => this.markAsDone(row)}
          rowClick={this._onRowClick}
          markAsDoneNEW={(row) => this.markAsDoneNEW(row.Id)}
          rowClickNEW={this._onRowClickNEW}
          modalControl={this.modalControl}
        />

        <Notification />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.userReducer.User,
  Tasks: state.taskReducer.Tasks,
  RowCount: state.taskReducer.RowCount,
  PageNumber: state.gridReducer.PageNumber,
  BookSections: state.bookSectionReducer.BookSections,
  Links: state.pageHeaderLinkReducer.Links,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedSideBar: (item) => dispatch(setSelectedItem(item)),
  setTasks: (tasks, rowCount) => dispatch(setTasks(tasks, rowCount)),
  setLinks: (links) => dispatch(setLinks(links)),
  setSelectedBookSectionId: (selectedId) =>
    dispatch(setSelectedBookSectionId(selectedId)),
  addLink: (links) => dispatch(addLink(links)),
  setPageNumber: (pageNumber) => dispatch(setPageNumber(pageNumber)),
  logOutUser: () => dispatch(logoutUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WorkListPage));

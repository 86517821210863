import { ApiUrl } from './common/Config';
import { post } from './common/Helper';

const userDetailUrl = ApiUrl + 'userdetail/';

export const getUserDetail = async(userId, token) => {
    let url = userDetailUrl + 'get';
    return await post(url, userId, token);
};

export const updateUserDetail = async(userDetail, token) => {
    let url = userDetailUrl + 'update';
    return await post(url, userDetail, token);
};
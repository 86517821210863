import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';

//#region Internal
import { setActivity } from './../redux/actions/loadingAction';

import './../index.css';
//#endregion

class LoadingPanel extends Component {
    constructor(props){
        super(props);

        this.state={};
    }

    render(){
        return(
            <LoadingOverlay
                active={this.props.Activity}
                spinner
                text='Loading your content...'
            >
            </LoadingOverlay>
        )
    }
}

LoadingPanel.propTypes = {
};

LoadingPanel.defaultProps = {
};

const mapStateToProps = (state) => ({
    Activity: state.loadingReducer.Activity
});

const mapDispatchToProps = (dispatch) => ({
    setActivity: (state) => dispatch(setActivity(state))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoadingPanel));
import { GridActions } from './../ActionTypes';

const initialState = {
    PageNumber: 0
};

export const gridReducer = (state = initialState, action) => {
    switch (action.type) {
        case GridActions.SetPageNumber:
            return Object.assign({}, state, { 
                PageNumber: action.pageNumber
            });
        default:
            return state;
    }
};
import { ApiUrl } from './common/Config';
import { post, put } from './common/Helper';

const baseUrl = ApiUrl + 'booksection/';

export const getAllBookSections = async(paging, token) => {
    let url = baseUrl + 'getall';
    return await post(url, paging, token);
};

export const createBookSection = async(bookSection, token) => {
    let url = baseUrl + 'create';
    return await post(url, bookSection, token);
};

export const deleteBookSection = async(bookSection, token) => {
    let url = baseUrl + 'delete';
    return await put(url, bookSection, token);
};

export const updateBookSection = async(bookSection, token) => {
    let url = baseUrl + 'update';
    return await put(url, bookSection, token);
};

export const bulkInsertBookSections = async(bookSections, token) => {
    let url = baseUrl + 'excelupload';
    return await post(url, {BookSections: bookSections} , token);
};

export const getAllByRole = async(paging, token) => {
    let url = baseUrl + 'getallbyrole';
    return await post(url, paging, token);
};

export const setAssignee = async(data, token) => {
    let url = baseUrl + 'setassignee';
    return await post(url, data, token);
};

export const finishTask = async(data, token) => {
    let url = baseUrl + 'finishtask';
    return await post(url, data, token);
};

export const sendBackAssignee = async(data, token) => {
    let url = baseUrl + 'sendbackassignee';
    return await post(url, data, token);
};
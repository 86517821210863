import React from 'react'
import { localCacheRequest, localCacheRequestDuello } from '../api/CacheAndLogs'

const CacheButton = ({ notify,apiEndpoint, buttonText,isCleanCache,disabled,isRedis,isAdmin, isDuello,isContentProcess }) => {
    
   console.log("isAdmin Button",isAdmin)
    const pJWT = sessionStorage.getItem("jwtP");
    const _user = JSON.parse(localStorage.getItem("user"));
    const handleButtonClick = async () => {
      if(isDuello){
        const res = await localCacheRequestDuello(apiEndpoint,isContentProcess?_user.Token:pJWT)
        if(res.status.toString().startsWith("2")){
          notify(`${buttonText} Cache Başarı ile kırılmıştır`)
        }
        console.log("res.status",res.status)
      }else{

        const res = await localCacheRequest(String(isRedis?"deleterediscache?key="+apiEndpoint:isCleanCache?apiEndpoint:"clearcache?key="+apiEndpoint),isAdmin?pJWT:_user.Token,isAdmin)
        if(res.status.toString().startsWith("2")){
          notify(`${buttonText} Cache Başarı ile kırılmıştır`)
        }
        console.log("res.status",res.status)
      }
      };
  return (
    <button disabled={disabled} onClick={handleButtonClick}>{buttonText}{isRedis?" Redis":" CACHE"}</button>
  )
}

export default CacheButton
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/styles';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import MuiTextField from '@material-ui/core/TextField';
import MuiInputLabel from '@material-ui/core/InputLabel';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiSwitch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Checkbox } from '@material-ui/core';
//#endregion

//#region Internal
import ApproveButton from './ApproveButton';

import { Components, FontSize } from './../../assets/Dimensions';
import { Text } from './../../assets/Strings';
import { Primary } from './../../assets/Colors';
//#endregion

//#region Override Style
const Dialog = withStyles({
    paperWidthSm: {
        maxWidth: 720,
        minWidth: 300,
        minHeight: 220
    }
})(MuiDialog);

const DialogContentText = withStyles({
    root: {
        fontSize: FontSize.Mid
    }
})(MuiDialogContentText);

const TextField = withStyles({
    root: {
        marginTop: 10,
        '& .MuiFormLabel-root':{
            fontSize: FontSize.Mid
        }
    }
})(MuiTextField);

const InputLabel = withStyles({
    root: {
        fontSize: FontSize.Mid
    }
})(MuiInputLabel);

const DialogContent = withStyles({
    root: {
        paddingTop: 0,
        marginTop: 10
    }
})(MuiDialogContent);

const DialogTitle = withStyles({
    root: {
        marginLeft: 20
    }
})(MuiDialogTitle);

const FormControlLabel = withStyles({
    label: {
        fontSize: FontSize.Small
    }
})(MuiFormControlLabel);

const Switch = withStyles({
    colorPrimary: {
        '&.Mui-disabled + .MuiSwitch-track':{
            backgroundColor: '#41bea0',
            opacity: 0.3
        },
        '&.Mui-checked':{
            color: Primary.ContainerBackground
        },
        '&.Mui-disabled':{
            color: Primary.ContainerBackground
        }
    },
    track: {
        backgroundColor: '#d8d8d8',
        opacity: 0.6
    },
    switchBase: {
        padding: '13px 18px 12px 12px'
    },
    thumb: {
        width: Components.Image.Small.Width,
        height: Components.Image.Small.Height,
    }
})(MuiSwitch)

const VerticalDivider = withStyles({
    root: {
        width: 1,
        height: 500,
        display: 'inline-block',
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 40
    }
})(Divider);
//#endregion

class DialogPanel extends Component {
    constructor(props){
        super(props);

        this._dropdownItems = ['editRole', 'newRole', 'newBookPublisher', 'newBookCourse', 'editPublisherName', 'editCourseName','newBookSectionTypeName',
        'newCourseTopicName','newSubCourseTopicName','editBookSectionTypeName','editCourseTopicName','editSubCourseTopicName','editAssigneeUser',
        'newBookSectionSourceTypeName','editBookSectionSourceTypeName'];
        this._switchItems = ['editAccountStatus','newRowStatus','editRowStatus','editStatus'];
        this._user={};
        this.state={};
    }

    async componentDidMount(){
        this._user = JSON.parse(localStorage.getItem('user'));
        if(this._user === null){
            this.props.history.push('/login');
            return;
        }
    };

    _getSelectOptions = (item) => {
        let items = {};
        if(item === 'editRole' || item === 'newRole'){
            items = this.props.Roles;
        } else if (item === 'newBookPublisher' || item === 'editPublisherName') {
            items = this.props.Publishers;
        } else if(item === 'newBookCourse' || item === 'editCourseName') {
            items = this.props.Courses;
        } else if(item === 'newBookSectionTypeName' || item === 'editBookSectionTypeName') {
            items = this.props.BookSectionTypes;
        } else if(item === 'newCourseTopicName' || item === 'editCourseTopicName') {
            items = this.props.CourseTopics;
        } else if(item === 'newSubCourseTopicName' || item === 'editSubCourseTopicName') {
            items = this.props.SubCourseTopics;
        } else if(item === 'newBookSectionSourceTypeName' || item === 'editBookSectionSourceTypeName'){
            items = this.props.BookSectionSourceTypes;
        } else if(item === 'editAssigneeUser'){
            return (
                <option key={this._user.Id} value={this._user.Name}>{this._user.Name}</option>
            )
        }
        
        return (items.map((item) => (
            <option key={item.Id} value={item.Name}>{item.Name}</option>
        )))
    };

    _generateDialogContent = (components) => {
        return(
            <DialogContent>
                {components.map((item) => {
                    if(this._dropdownItems.includes(item)){
                        return (
                            <FormControl 
                                key={item} 
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                            >
                                <InputLabel>
                                    {Text[item]}
                                </InputLabel>
                                <Select
                                    id={item}
                                    ref={item}
                                    key={item}
                                    name={item}
                                    defaultValue={this.props.editRow.length > 0 ? this.props.editRow[0][item.replace('edit','')] : ''}
                                    native
                                >
                                    <option value='' />
                                    {this._getSelectOptions(item)}
                                </Select>
                            </FormControl>
                        )
                    } else if(this._switchItems.includes(item)){
                        return(
                            <FormControlLabel
                                id={item + '_form'}
                                key={item}
                                name={item}
                                control={
                                    <Switch
                                        id={item}
                                        defaultChecked={this.props.editRow.length > 0 && this.props.editRow[0][item.replace('edit','')]}
                                        color='primary'
                                    />
                                }
                                label={Text[item]}
                                labelPlacement='end'
                            />
                        )
                    } else {
                        return(
                            <TextField
                                fullWidth
                                ref={item}
                                id={item}
                                key={item}
                                name={item}
                                label={Text[item]}
                                type={'text'}
                                defaultValue={this.props.editRow.length > 0 && this.props.editRow[0][item.replace('edit','')] !== null ? this.props.editRow[0][item.replace('edit','')] : ''}
                            />
                        )
                    } 
                })}    
            </DialogContent>
        )
    };

    render(){
        return(
            <Dialog id={this.props.id} open={this.props.isOpen} onClose={this.props.onClose}>
                {this.props.title &&
                    <div style={{
                        backgroundColor: Primary.Background
                    }}>
                        <DialogTitle>
                            {this.props.title}
                        </DialogTitle>
                        <Divider/>
                    </div>
                }
                <DialogContent>
                    {this.props.contentText &&
                        <DialogContentText>
                            {this.props.contentText}
                        </DialogContentText>
                    }

                    {this.props.contentComponentNames &&
                        this._generateDialogContent(this.props.contentComponentNames)
                    }
                    <div style={{display:"flex"}}>

                    {(this.props.croppedImage && this.props.croppedList.length === 0) &&
                        <div>
                           {sessionStorage.getItem("fromFeedBack")&&this.props.title=="Kayıtlı Görüntü"&& <p>Dev Image</p>}
                        <img
                            alt={'alt'}
                            src={typeof this.props.croppedImage === 'string' ? this.props.croppedImage : window.URL.createObjectURL(this.props.croppedImage)}
                            style={{
                                width: 300,
                                float: 'left'
                            }}
                            />
                            </div>
                    }
                    {(this.props.croppedImageProd && this.props.croppedListProd.length === 0 && sessionStorage.getItem("fromFeedBack") && this.props.title=="Kayıtlı Görüntü") &&
                    <div style={{borderLeft:"1px solid black"}}>
                        {sessionStorage.getItem("fromFeedBack")&& <p>Prod Image</p>}
                        <img
                            alt={'alt'}
                            src={typeof this.props.croppedImageProd === 'string' ? this.props.croppedImageProd : window.URL.createObjectURL(this.props.croppedImageProd)}
                            style={{
                                width: 300,
                                float: 'right'
                            }}
                            />
                            </div>
                    }
                    </div>
                   
                    <div style={{ display: "flex" }}>
                    

                            {this.props.croppedList.length !== 0 &&
                                <div>

                                    {sessionStorage.getItem("fromFeedBack") && this.props.title == "Kayıtlı Görüntü" && <p>Dev Image</p>}
                                    {this.props.croppedList.map(item => (
                                        <div>
                                            <img
                                                alt={'alt'}
                                                src={typeof item.ImageData === 'string' ? item.ImageData : window.URL.createObjectURL(item.ImageData)}
                                                style={{
                                                    width: 300,
                                                    display: 'block',
                                                    marginBottom: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                            <Divider />
                                        </div>
                                    ))}
                                </div>
                            }
                        
                        
                            {this.props.croppedListProd.length !== 0 && this.props.title == "Kayıtlı Görüntü" && sessionStorage.getItem("fromFeedBack") &&
                                <div style={{ borderLeft: "1px solid black" }}>
                                    {sessionStorage.getItem("fromFeedBack") && <p>Prod Image</p>}

                                    {this.props.croppedListProd.map(item => (
                                        <div >
                                            <img
                                                alt={'alt'}
                                                src={typeof item.ImageData === 'string' ? item.ImageData : window.URL.createObjectURL(item.ImageData)}
                                                style={{
                                                    width: 300,
                                                    display: 'block',
                                                    marginBottom: 10,
                                                    marginTop: 10
                                                }}
                                            />
                                            <Divider />
                                        </div>
                                    ))}
                                </div>
                            }
                        
                    </div>

                    {this.props.answerCroppedImage && 
                        <VerticalDivider/>
                    }

                    {this.props.answerCroppedImage &&
                        <img
                            alt={'alt'}
                            src={typeof this.props.answerCroppedImage === 'string' ? this.props.answerCroppedImage : window.URL.createObjectURL(this.props.answerCroppedImage)}
                            style={{
                                width: 300,
                                float: 'right'
                            }}
                        />
                    }
                </DialogContent>
                {/* {   sessionStorage.getItem("fromFeedBack")&& this.props.contentText==Text.CropUpdateQuestion && <FormControlLabel
            control={<Checkbox value={this.props.syncWithProductionCrop} checked={this.props.syncWithProductionCrop} onChange={(s,w)=>{this.props.onChangeSyncWithProductionCrop(w)}} />}
            label="Should Update Prod"
          />} */}
                {this.props.actions &&
                    <DialogActions>
                        {this.props.actions.map(item => (
                            <ApproveButton key={item.text} onClick={item.onClick} text={item.text}/>
                        ))}
                    </DialogActions>   
                }
            </Dialog>
        )
    }
}

DialogPanel.propTypes = {
    isOpen: PropTypes.bool,
    title: PropTypes.string,
    contentText: PropTypes.string,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func,
            text: PropTypes.string
        })
    ),
    contentComponentNames: PropTypes.array,
    editRow: PropTypes.array,
    onClose: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    croppedImage: PropTypes.node,
    croppedList: PropTypes.array
};

DialogPanel.defaultProps = {
    isOpen: false,
    editRow: [],
    croppedList: [],
    croppedListProd: []
};

const mapStateToProps = (state) => ({
    Roles: state.roleReducer.Roles,
    Courses: state.courseReducer.Courses,
    Publishers: state.publisherReducer.Publishers,
    BookSectionTypes: state.bookSectionTypeReducer.BookSectionTypes,
    CourseTopics: state.courseTopicReducer.CourseTopics,
    SubCourseTopics: state.subCourseTopicReducer.SubCourseTopics,
    User: state.userReducer.User,
    BookSectionSourceTypes: state.bookSectionSourceTypeReducer.BookSectionSourceTypes
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogPanel);
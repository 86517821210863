import { ApiUrl, ApiUrlAdmin } from "./common/Config";
import { postAdmin,post,get,getAdmin,postFormData } from "./common/Helper";

export const getTeachers = async (token) => {
    let url = ApiUrlAdmin + "OnlineLecture/teachers";
    return await getAdmin(url, token);
  };
  export const getTeachersDev = async (token) => {
    let url = ApiUrlAdmin + "OnlineLecture/teachers";
    return await getAdmin(url, token);
  };
  export const onlineLecture = async (token) =>{
      let url = ApiUrlAdmin + "OnlineLecture/lectures"
      return await get(url,token)
  }
  export const updateOnlineLecture = async(lectureData,token) =>{
    let url =ApiUrlAdmin +"OnlineLecture/updatelecture"
    return await post(url,lectureData,token)
  }
  export const deleteOnlineLecture = async(lectureData,token) =>{
    let url =ApiUrlAdmin +"OnlineLecture/deletelecture"
    return await post(url,lectureData,token)
  }
  export const deleteOnlineLectureHomeWork = async(lectureData,token) =>{
    let url =ApiUrlAdmin +"OnlineLecture/deletehomework"
    return await post(url,lectureData,token)
  }
  export const nestedTitles = async (token) =>{
    let url = ApiUrlAdmin + "Book/getnestedtitles"
    return await get(url,token)
}
export const addLecture = async (lectureData,token) =>{
  let url = ApiUrlAdmin + "OnlineLecture/addLecture"
  return await postFormData(url,lectureData,token)
}
export const updateOnlineLectureHomeWork = async(lectureData,token) =>{
  let url =ApiUrlAdmin +"OnlineLecture/updatehomework"
  return await post(url,lectureData,token)
}
export const addOnlineLectureHomeWork = async(lectureData,token) =>{
  let url =ApiUrlAdmin +"OnlineLecture/addhomework"
  return await post(url,lectureData,token)
}
export const addGeneralLecture = async (lectureData,token) =>{
  let url = ApiUrlAdmin +"OnlineLecture/addGeneralLecture"
  return await post(url,lectureData,token)
}
export const updateGeneralLecture = async (lectureData,token) =>{
  let url = ApiUrlAdmin +"OnlineLecture/updategenerallecture"
  return await post(url,lectureData,token)
}
export const onlineLectureClearCache = async (token) =>{
  let url = ApiUrlAdmin +"media/clear-online-lecture"
  return await get(url,token)
}
export const onlineLectureUpdateUrlType = async (data,token) => {
  let url = ApiUrlAdmin + "OnlineLecture/updateonlinelecturevideotype"
  return await post(url,data,token)
}

import { SideBarActions } from './../ActionTypes';

const initialState = {
    SelectedItem: []
};

export const sidebarReducer = (state = initialState, action) => {
    switch (action.type) {
        case SideBarActions.SetSelectedItem:
            return Object.assign({}, state, { 
                SelectedItem: action.selectedItem
            });
        default:
            return state;
    }
};
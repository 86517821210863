import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/styles';
import MuiTable from '@material-ui/core/Table';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiTextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import InputAdornment from '@material-ui/core/InputAdornment';
import EditIcon from '@material-ui/icons/Edit';
import RemoveIcon from '@material-ui/icons/Delete';
import MoreIcon from '@material-ui/icons/MoreVert';
import SendBackIcon from '@material-ui/icons/SettingsBackupRestore';
import SearchIcon from '@material-ui/icons/Search';
//#endregion

//#region Internal Components
import { Components, FontSize } from './../../assets/Dimensions';
import { Primary } from './../../assets/Colors';
import GridPaging from './GridPaging';
import GridToolbar from './GridToolbar';
import GridExpansion from './GridExpansion';

import './index.css';
//#endregion

//#region Constant Variables
const stickyWidth = 120;
//#endregion

//#region Override Style
const TextField = withStyles({
    root: {
        display: 'block',
        backgroundColor: 'white',
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 15
    }
})(MuiTextField);

const Table = withStyles({
    root: {
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.15)',
        borderCollapse: 'separate'
    }
})(MuiTable);

const TableRow = withStyles({
    root: {
        '&:hover':{
            backgroundColor: '#f1ecec4a'
        }
    }
})(MuiTableRow);

const TableCell = withStyles({
    root: {
        padding: '4px'
    },
    body: {
        fontSize: FontSize.Small
    },
    head: {
        fontWeight: 'bold'
    }
})(MuiTableCell);

const HeaderTableCell = withStyles({
    root: {
        '&:hover':{
            backgroundColor: '#f1ecec4a',
            color: 'rgba(0, 0, 0, 0.35)'
        }
    }
})(TableCell);

const IconButton = withStyles({
    label: {
        width: Components.Image.Huge.Width,
        height: Components.Image.Huge.Height
    }
})(MuiIconButton);
//#endregion

//#region Inline Style
const styles = {
    scrollBar: {
        width: '100%',
        height: '100%',
        overflow: 'auto'
    },
    stickyColumn: {
        position: 'sticky',
        zIndex: 300,
        backgroundColor: Primary.ContainerBackground,
        backgroundClip: 'padding-box',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)'
    },
    rowActionIcon: {
        width: Components.Image.Big.Width,
        height: Components.Image.Big.Height
    },
    iconButton: {
        marginLeft: 10,
        padding: 0
    }
}
//#endregion

class Grid extends Component {
    constructor(props){
        super(props);

        this.state = {
            expandedRow: ''
        };
    }

    _expansionRowChange = (rowName) => {
        this.setState({
            expandedRow: rowName
        });
    };

    render(){
        this._user = JSON.parse(localStorage.getItem('user'));
        let stickyHeaderCount = 0;
        let stickyRowCount = 0; 

        return(
            <div>
                {(this.props.toolbar.create || this.props.toolbar.save || this.props.toolbar.excelImport) &&
                    <GridToolbar 
                        create={this.props.toolbar.create}
                        save={this.props.toolbar.save}
                        excelImport={this.props.toolbar.excelImport}
                        createClick={this.props.toolbar.createClick}
                        saveClick={this.props.toolbar.saveClick}
                        excelImportClick={this.props.toolbar.excelImportClick}
                    />
                }
                
        

                <div style={{
                    ...styles.scrollBar,
                    marginTop: (!this.props.toolbar.create && !this.props.toolbar.save && !this.props.toolbar.excelImport) && 30
                }}>
                            {(this.props.searchOnChange && this._user.Role === 'Administrators') &&
                    <TextField
                        placeholder={'Search'}
                        value={this.props.searchValue}
                        onChange={this.props.searchOnChange}
                        InputProps={{ 
                            style: { fontSize: FontSize.Mid },
                            startAdornment: (
                                <InputAdornment position='end'>
                                    <SearchIcon/>
                                </InputAdornment>
                            )
                        }}
                    /> 
                }
                    <Table>
                        <TableHead>
                            <MuiTableRow>
                                {(this.props.editRow || this.props.deleteRow || this.props.sendBack||this.props.options) &&
                                    <TableCell style={{
                                        ...styles.stickyColumn,
                                        left: 0
                                    }}/>
                                }
                                
                                {this.props.data.columns.map((headCellData, headCellIndex) => {
                                    if(this.props.stickyColumnCount > stickyHeaderCount){
                                        stickyHeaderCount++;
                                        return(
                                            <HeaderTableCell onClick={this.props.headerClick}  key={headCellIndex +'_stickyCell'} style={{
                                                ...styles.stickyColumn,
                                                minWidth: 80,
                                                left: this.props.editRow && this.props.deleteRow ? ((stickyHeaderCount-1) * stickyWidth) + 100 : 0
                                            }}>
                                                {headCellData}
                                            </HeaderTableCell>
                                        )
                                    } else {
                                        return(
                                            <HeaderTableCell onClick={()=>this.props.headerClick(headCellData)} key={headCellIndex}>{headCellData}</HeaderTableCell>
                                        )
                                    }
                                })}
                             
                            </MuiTableRow>
                        </TableHead>
                        
                        {this.props.expansionTableData ? 
                            <GridExpansion
                                data={this.props.data}
                                expansionTableData={this.props.expansionTableData}
                            />
                        :
                            <TableBody>
                                {this.props.data.rows.map((rowData, rowIndex) => {
                                    stickyRowCount = 0;
                                    return(
                                        <div 
                                            key={rowIndex+'-div'}
                                            style={{
                                                display: 'contents'
                                            }}
                                        >
                                            <TableRow id={rowData.data.id} key={rowIndex} onClick={(e) => this.props.rowClick(e)}>
                                                {(this.props.editRow || this.props.deleteRow || this.props.sendBack || this.props.options) &&
                                                    <TableCell style={{
                                                    ...styles.stickyColumn,
                                                    left: 0
                                                    }}>
                                                        <div style={{display: '-webkit-inline-box'}}>
                                                            {this.props.deleteRow &&
                                                                <IconButton id={rowData.data.id} style={{...styles.iconButton, float: 'left'}} onClick={this.props.deleteRow}>
                                                                    <RemoveIcon style={{...styles.rowActionIcon}}/>
                                                                </IconButton>
                                                            }
                                                            {this.props.sendBack &&
                                                                <IconButton id={rowData.data.id} style={{...styles.iconButton, float: 'left'}} onClick={this.props.sendBack}>
                                                                    <SendBackIcon style={{...styles.rowActionIcon}}/>
                                                                </IconButton>
                                                            }
                                                            {this.props.editRow &&
                                                                <IconButton id={rowData.data.id} style={{...styles.iconButton, float: 'right'}} onClick={this.props.editRow}>
                                                                    <EditIcon style={{...styles.rowActionIcon}}/>
                                                                </IconButton>
                                                            }
                                                        
                                                              {this.props.options &&
                                                                <IconButton id={rowData.data.id} style={{...styles.iconButton, float: 'right'}} onClick={()=>this.props.options(rowData.data)}>
                                                                    <MoreIcon style={{...styles.rowActionIcon}}/>
                                                                </IconButton>
                                                            }
                                                        </div>
                                                    </TableCell>
                                                }
                                                
                                                {rowData.data.content.map((cellData, cellIndex) => {
                                                    if(this.props.stickyColumnCount > stickyRowCount){
                                                        stickyRowCount++;
                                                        return(
                                                            <TableCell key={cellIndex} style={{
                                                                ...styles.stickyColumn,
                                                                left: this.props.editRow && this.props.deleteRow ? ((stickyRowCount-1) * stickyWidth) + 100 : 0
                                                            }}>
                                                                {cellData.content}
                                                                {cellData.component}
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        return(
                                                            <TableCell key={cellIndex}>
                                                                {cellData.content}
                                                                {cellData.component}
                                                            </TableCell>
                                                        );
                                                    }
                                                })}
                                                {rowData.component !== null &&
                                                    <TableCell style={{
                                                        width: Components.GridButton.Width
                                                    }}>
                                                        {rowData.component}
                                                    </TableCell>
                                                }
                                            </TableRow>
                                            {rowData.comment !== null && rowData.comment}
                                        </div>
                                )})}
                            </TableBody>
                        }
                    </Table>
                </div>
                <GridPaging paging={this.props.paging}/>
            </div>
        );
    }
}

Grid.propTypes = {
    data: PropTypes.shape({
        columns: PropTypes.array.isRequired,
        rows: PropTypes.array.isRequired
    }),
    expansionTableData: PropTypes.shape({
        columns: PropTypes.array,
        rows: PropTypes.array
    }),
    stickyColumnCount: PropTypes.number,
    toolbar: PropTypes.shape({
        create: PropTypes.bool,
        save: PropTypes.bool,
        excelImport: PropTypes.bool,
        createClick: PropTypes.func,
        saveClick: PropTypes.func,
        excelImportClick: PropTypes.func,
    }),
    deleteRow: PropTypes.func,
    editRow: PropTypes.func,
    rowClick: PropTypes.func,
    paging: PropTypes.shape({
        rowsPerPage: PropTypes.number,
        count: PropTypes.number
    }),
    sendBack: PropTypes.func,
    searchValue: PropTypes.string,
    options:PropTypes.func
};

Grid.defaultProps = {
    stickyColumnCount: 0,
    toolbar: {
        create: false,
        save: false,
        excelImport: false,
    },
    rowClick: () => null
};

export default withRouter(Grid);
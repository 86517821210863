import React, { useEffect, useState } from "react";
import Toggle from "../../scenes/questionVideoSolution/components/toggle/toggle";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import ExamType from "../../enums/ExamType";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import DeleteIcon from "@material-ui/icons/RemoveCircle";
import AchievementType from "../../enums/AchievementType";

import "./achievementsStyle.css";

const SubCourses = ({
  achievements,
  secondaryAchievementsForQuestion,
  selectPrimaryAchievement,
  removeSelectedAchievement,
  previousQuestion,
  nextQuestion,
  questionOrder,
  setNoAchievementSelectedModal,
  IsSkiped,
}) => {
  const [primaryAchievement, setPrimaryAchievement] = useState(null);
  const [isSkipedSelected, setIsSkipedSelected] = useState(false);
  const [selectedSecondaryAchievements, setSelectedSecondaryAchievements] = useState([]);


  const isAnySelected = achievements&&achievements.some((obj) => obj.IsSelect === true);
  const handlePrimaryAchievementSelection = (
    SelectedAchievement,
    isSkipped
  ) => {
    if (isSkipped) {
      selectPrimaryAchievement(
        SelectedAchievement,
        AchievementType.IsSkiped,
        isSkipped
      );
    } else {
      setIsSkipedSelected(false);
      selectPrimaryAchievement(SelectedAchievement, AchievementType.Primary);
    }
    if (primaryAchievement) {
      if (primaryAchievement == SelectedAchievement) {
        setPrimaryAchievement(null);
      } else {
        setPrimaryAchievement(SelectedAchievement);
      }
    } else {
      setPrimaryAchievement(SelectedAchievement);
    }
  };
  const handleSecondaryAchievementSelection = (selectedAchievement) => {
    setSelectedSecondaryAchievements((prevSelected) => {
      // Check if the selectedAchievementId is already in the array
      const isSelected = prevSelected.includes(selectedAchievement);

      if (isSelected) {
        // If already selected, remove it from the array
        return prevSelected.filter((id) => id !== selectedAchievement);
      } else {
        // If not selected, add it to the array
        return [...prevSelected, selectedAchievement];
      }
    });
  };

  const getCourseNamesFromExamType = () => {};
  const getExamTypeName = (value) => {
    for (const key in ExamType) {
      if (ExamType[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };

  const getNextQuestion = () => {
    // Check if primaryAchievement is not set
    if (!primaryAchievement) {
      // Check if any achievement is selected
      const isAnySelected = achievements.some((obj) => obj.IsSelect === true);
      // If none selected, show modal; otherwise proceed to next question
      isAnySelected || isSkipedSelected || IsSkiped
        ? nextQuestion()
        : setNoAchievementSelectedModal(true);
    } else {
      // If primaryAchievement is set, proceed to next question
      nextQuestion();
    }

    // Reset selectedTopic and primaryAchievement

    setPrimaryAchievement(null);
    setIsSkipedSelected(false);
  };

  return (
    <div style={{ width: "100%", overflow: "hidden" }}>
      {/* {checkedItems && (<div style={{backgroundColor:"green",color:"white"}}>
        {checkedItems.NewSubCourseTopicName}
      </div>)} */}
      {/* <div style={{zIndex:200,position:"fixed",marginTop:"-50px",display:"flex",flexDirection:"row",justifyContent:"space-between"}}> */}

      <div className="buttonContainer">
        <button
          disabled={questionOrder <= 1}
          onClick={() => {
            setPrimaryAchievement(null);
            setIsSkipedSelected(false);
            previousQuestion();
          }}
          className="prevButton"
        >
          <ArrowBackIcon />
        </button>
        <button
          className="nextButton"
          onClick={() => {
            // setPrimaryAchievement(null);
            // nextQuestion();
            getNextQuestion();
          }}
        >
          <ArrowForwardIcon />
        </button>
      </div>

      {/* </div> */}
      <div
        style={{
          width: "100%",
          overflow: "scroll",
          //maxHeight:window.screen.height
        }}
      >
        <div style={{ width: "100%" }}>
          {(IsSkiped || isSkipedSelected) && !primaryAchievement && (
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  padding: 10,
                  borderRadius: 8,
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                <div>Kazanım Seçilememiştir</div>
                <div
                  onClick={() => {
                    setIsSkipedSelected(false);
                    removeSelectedAchievement(AchievementType.IsSkiped);
                  }}
                >
                  <DeleteIcon />
                </div>
              </div>
            </div>
          )}

          {achievements && (
            <div style={{ height: window.screen.height / 2 }}>
              {primaryAchievement ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    padding: 10,
                    borderRadius: 8,
                  }}
                >
                  <div>{primaryAchievement.AchievementName}</div>
                  <div
                    onClick={() => {
                      setPrimaryAchievement(null);
                      removeSelectedAchievement(AchievementType.Primary);
                    }}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              ) : isSkipedSelected ? <></> : (
                <div>
                  Birincil Kazanımlar
                  {achievements.map((topic) => (
                    <div
                      style={{
                        padding: 10,
                        borderWidth: "2px",
                        border: 1,
                        borderRadius: 8,
                        borderStyle: "solid",
                        marginBottom: 10,
                      }}
                      key={topic.AchievementId}
                    >
                      <label style={{ display: "block" }}>
                        <input
                          disabled={IsSkiped ? false : topic.IsSelect}
                          type="checkbox"
                          name={topic.AchievementName}
                          value={topic.SubCourseTopicUniqueId}
                          checked={IsSkiped ? false : topic.IsSelect}
                          onChange={() => {
                            handlePrimaryAchievementSelection(topic);
                          }}

                          // Handle radio button selection
                        />
                        <span>{topic.AchievementName}</span>
                      </label>
                    </div>
                  ))}
                  {(!isSkipedSelected || !IsSkiped) && (
                    <div
                      style={{
                        padding: 10,
                        borderWidth: "2px",
                        border: 1,
                        borderRadius: 8,
                        borderStyle: "solid",
                        marginBottom: 10,
                        backgroundColor: "red",
                        color: "white",
                      }}
                    >
                      <label style={{ display: "block" }}>
                        <input
                          //disabled={isAnySelected}
                         //disabled={IsSkiped}
                          type="checkbox"
                          // name={topic.AchievementName}
                          value={isSkipedSelected}
                          checked={IsSkiped}
                          onChange={() => {
                            setIsSkipedSelected(!isSkipedSelected);
                            handlePrimaryAchievementSelection(null, true);
                          }}
                          // Handle radio button selection
                        />
                        <span>Kazanım Seçilemiyor</span>
                      </label>
                    </div>
                  )}
                </div>
              )}
              {/* {primaryAchievement && (
                <div>
                  İkincil Kazanımlar
                  {achievements.filter((selected)=>(selected!==primaryAchievement)).map((topic) => (
                    <div
                      style={{
                        padding: 10,
                        borderWidth: "2px",
                        border: 1,
                        borderRadius: 8,
                        borderStyle: "solid",
                        marginBottom: 10,
                      }}
                      key={topic.AchievementId}
                    >
                      <label style={{ display: "block" }}>
                        <input
                          type="checkbox"
                          name={topic.AchievementName}
                          value={topic.AchievementId}
                          // checked={topic.IsSelect}
                          onChange={() => {
                            handleSecondaryAchievementSelection(
                              topic.AchievementId
                            );
                          }}

                          // Handle radio button selection
                        />
                        <span>{topic.AchievementName}</span>
                      </label>
                    </div>
                  ))}
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubCourses;

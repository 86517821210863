import React, { Component } from 'react';
import PropTypes from 'prop-types';

//#region Material Components
import ApproveButton from './../Base/ApproveButton';
//#endregion

//#region Internal
import { Text } from './../../assets/Strings';
//#endregion

//#region Inline Style
const styles= {
    toolbar:{
        marginTop: 20,
        marginBottom: 20
    }
}
//#endregion

class GridToolbar extends Component {
    constructor(props){
        super(props);

        this.state={}
    }

    render(){
        return(
            <div style={{...styles.toolbar}}>
                {this.props.save &&
                    <ApproveButton onClick={this.props.saveClick} text={Text.ToolbarSave} style={{marginRight: 10}}/>
                }
                
                {this.props.create &&
                    <ApproveButton onClick={this.props.createClick} text={Text.ToolbarCreate} style={{marginRight: 10}}/>
                }

                {this.props.excelImport &&
                    <ApproveButton excelImport={true} onClick={this.props.excelImportClick} text={Text.ToolbarExcelImport} style={{marginRight: 10}}/>
                }
            </div>
        )
    }
}

GridToolbar.propTypes = {
    create: PropTypes.bool,
    save: PropTypes.bool,
    excelImport: PropTypes.bool,
    createClick: PropTypes.func,
    saveClick: PropTypes.func,
    excelImportClick: PropTypes.func
};

export default GridToolbar;
import * as React from "react";
const InfoIcon = (props) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    enableBackground="new 0 0 48 48"
  
  >
    <circle fill="#EE5C42" cx={24} cy={24} r={21} />
    <rect x={22} y={22} fill="#ffffff" width={4} height={11} />
    <circle fill="#ffffff" cx={24} cy={16.5} r={2.5} />
  </svg>
);
export default InfoIcon;



import { ApiUrl, ApiUrlAdmin } from "./common/Config";
import { postAdmin,post,get,getAdmin,postFormData } from "./common/Helper";

const localCacheWithKey = "media/"

export const localCacheRequest = async (data,token,isAdmin) =>{
    let url = (isAdmin?ApiUrlAdmin:ApiUrl )+ localCacheWithKey+ data
    return await get(url,token)
}
export const corprateInfos = async (token,isAdmin) =>{
    let url = (isAdmin?ApiUrlAdmin:ApiUrl) + "lottery/corporates"
    return await get(url,token)
}
export const couponconfigInfos = async (token,isAdmin) =>{
    let url = (isAdmin?ApiUrlAdmin:ApiUrl) + "coupon/couponconfigtypes"
    return await get(url,token)
}
export const localCacheRequestDuello = async (data,token) =>{
    let url = data
    return await get(url,token)
}
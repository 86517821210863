import { CropActions } from "./../ActionTypes";

const initialState = {
  CropDimensions: {
    x: 62,
    y: 70,
    width: 225,
    height: 200,
  },
  AnswerCropDimensions: {},
  CroppedImage: "",
  AnswerCroppedImage: "",
  CropSrc: "",
  AnswerCropSrc: "",
  CroppedList: [],
  MultiCropChecked: false,
  isFirstLoad: true,
};

export const cropReducer = (state = initialState, action) => {
  switch (action.type) {
    case CropActions.SetAllOptions:
      return Object.assign({}, state, {
        CropDimensions: action.cropDimensions,
        AnswerCropDimensions: action.answerCropDimensions,
        CroppedImage: action.croppedImage,
        AnswerCroppedImage: action.answerCroppedImage,
        CropSrc: action.cropSrc,
        AnswerCropSrc: action.answerCropSrc,
      });
    case CropActions.AddCroppedList:
      if (state.isFirstLoad) return { ...state, isFirstLoad: false };
      else
        return {
          ...state,
          CroppedList: [...state.CroppedList, action.cropped],
        };
    case CropActions.RemoveCroppedList:
      return {
        ...state,
        CroppedList: state.CroppedList.filter(
          (item) => item.ImageData !== action.cropped
        ),
      };
    case CropActions.ClearCroppedList:
      return {
        ...state,
        CroppedList: [],
      };
    case CropActions.SetCroppedList:
      return Object.assign({}, state, {
        CroppedList: action.croppedList,
      });
    case CropActions.SetMultiCropChecked:
      return Object.assign({}, state, {
        MultiCropChecked: action.multiCropChecked,
      });
    case CropActions.SetFirstLoad:
      return Object.assign({}, state, {
        isFirstLoad: action.data,
      });
    default:
      return state;
  }
};

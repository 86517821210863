import React from "react";
import "./style.css";

const Modal = ({
  isOpen,
  onClose,
  title,
  content,
  buttonText,
  onButtonClick,
  closeButtonText,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div style={{ textAlign: "center" }} className="modal-content">
        <h2>{title}</h2>
        <p>{content}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <button
            style={{
              color: "white",
              backgroundColor: "#8A92A6",

              padding: 10,
              width: "30%",
              borderColor: "transparent",
              borderRadius: 8,
            }}
            onClick={onClose}
          >
            {closeButtonText}
          </button>
          <button
            style={{
              backgroundColor: "#054EDD",

              color: "white",
              width: "30%",
              borderColor: "transparent",
              borderRadius: 8,
            }}
            onClick={onButtonClick}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;

import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

//#region Internal
import SideBar from "./../components/SideBar";
import PageHeader from "./../components/Base/PageHeader";
import DialogPanel from "./../components/Base/DialogPanel";
import Grid from "./../components/Grid/index";
import GridSwitchButton from "./../components/Grid/GridSwitchButton";

import { Components } from "./../assets/Dimensions";
import { Text } from "./../assets/Strings";
import {
  Notification,
  success,
  error,
  warning,
} from "./../components/Notification/index";
import excelImport from "./../util/excelImport";
import getRows from "./../util/getRows";

import {
  getAllBooks,
  createBook,
  deleteBook,
  updateBook,
  bulkInsertBooks,
} from "./../api/Book";
import { getAllCourses } from "./../api/Course";
import { getAllPublishers } from "./../api/Publisher";

import { setAllBooks, setSelectedBookId } from "./../redux/actions/bookAction";
import { setAllCourses } from "./../redux/actions/courseAction";
import { setAllPublishers } from "./../redux/actions/publisherAction";
import { setSelectedItem } from "./../redux/actions/sidebarAction";
import { setLinks, addLink } from "./../redux/actions/pageHeaderLinkAction";
import { logoutUser } from "./../redux/actions/userAction";
import { setPageNumber } from "./../redux/actions/gridAction";
//#endregion

//#region Variables
const _pageSize = 6;
//#endregion

//#region Inline Style
const styles = {
  container: {
    marginLeft: Components.SideBarWidth + 15,
    paddingTop: 20,
    marginRight: 30,
  },
};
//#endregion

class BooksPage extends Component {
  constructor(props) {
    super(props);

    this._createFieldNames = [
      "newBookName",
      "newBookImageExtension",
      "newPrintPublishYear",
      "newBookPublisher",
      "newBookCourse",
      "newPageCount",
      "newPublisherBookId",
      "newOriginalFileName",
    ];
    this._editFieldNames = [
      "editStatus",
      "editName",
      "editFileExtension",
      "editPrintPublishYear",
      "editPublisherName",
      "editCourseName",
      "editPageCount",
      "editPublisherBookId",
      "editOriginalFileName",
    ];
    this._csvHeader = [
      "Name",
      "PublishDate",
      "PublisherId",
      "CourseId",
      "PublisherBookId",
      "OriginalFileName",
      "PageCount",
    ];
    this._rows = [];
    this._columns = [];
    this._editRowData = [];
    this._user = {};
    this._orderColumn = "";
    this._orderByAsc = true;

    this.state = {
      createDialog: false,
      deleteUserId: false,
      editDialog: false,
      deleteBookId: 0,
      searchValue: "",
    };
  }

  componentWillMount() {
    this.props.setLinks([
      {
        Text: Text.Books,
        href: "/books",
      },
    ]);
  }

  async componentDidMount() {
    this._user = JSON.parse(localStorage.getItem("user"));
    if (this._user === null || this._user.Role !== "Administrators") {
      localStorage.clear();
      this.props.logOutUser();
      this.props.history.push("/login");
      return;
    }

    this.props.setPageNumber(0);
    this.props.setSelectedSideBar(Text.Books);

    let { Books, RowCount } = await this._getAllBooks("", "", 1);
    this.props.setBooks(Books, RowCount);

    let courseRes = await getAllCourses(this._user.Token);
    if (courseRes.status === 200) {
      this.props.setCourses(courseRes.data);
    } else {
      error(courseRes, 2000);
    }

    let publisherRes = await getAllPublishers(this._user.Token);
    if (publisherRes.status === 200) {
      this.props.setPublishers(publisherRes.data);
    } else {
      error(publisherRes, 2000);
    }
  }

  async shouldComponentUpdate(nextProps, nextState) {
    if (this.props.Books !== nextProps.Books) {
      return true;
    }
    if (this.props.PageNumber !== nextProps.PageNumber) {
      let { Books, RowCount } = await this._getAllBooks(
        "",
        "",
        1,
        nextProps.PageNumber
      );
      this.props.setBooks(Books, RowCount);
      return true;
    }
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  _handleClose = (name) => {
    this.setState({
      [name]: false,
    });
  };

  _createBook = async () => {
    this.setState({
      createDialog: true,
    });
  };

  _deleteRow = (e) => {
    this.setState({
      deleteDialog: true,
      deleteBookId: e.currentTarget.id,
    });
  };

  _editRow = (e) => {
    let selectedRow = this.props.Books.filter(
      (row) => row.Id.toString() === e.currentTarget.id
    );
    this._editRowData = selectedRow;

    this.setState({
      editDialog: true,
    });
  };

  _validation = (fields) => {
    for (var key in fields) {
      let val = fields[key];
      if (
        val.indexOf("editStatus") === -1 &&
        document.getElementById(val).value === ""
      ) {
        let msg = Text[val];
        return `Lütfen '${msg}' kısmını doldurunuz!`;
      }
    }
    return "";
  };

  _addBook = async () => {
    let validationResult = this._validation(this._createFieldNames);
    if (validationResult !== "") {
      warning(validationResult, 2000);
    } else {
      let courseId = this.props.Courses.filter(
        (item) => item.Name === document.getElementById("newBookCourse").value
      )[0].Id;
      let publisherId = this.props.Publishers.filter(
        (item) =>
          item.Name === document.getElementById("newBookPublisher").value
      )[0].Id;
      let newBook = {
        Name: document.getElementById("newBookName").value,
        CourseId: parseInt(courseId),
        PublisherId: parseInt(publisherId),
        PageImagesFileExtension: document.getElementById(
          "newBookImageExtension"
        ).value,
        PrintPublishYear: parseInt(
          document.getElementById("newPrintPublishYear").value
        ),
        PageCount: parseInt(document.getElementById("newPageCount").value),
        PublisherBookId: document.getElementById("newPublisherBookId").value,
        OriginalFileName: document.getElementById("newOriginalFileName").value,
      };
      let res = await createBook(newBook, this._user.Token);
      if (res.status === 200) {
        success(Text.SuccessCreateBook, 2000);

        this.setState({
          createDialog: false,
        });

        let { Books, RowCount } = await this._getAllBooks("", "", 1);
        this.props.setBooks(Books, RowCount);
      } else {
        error(res, 2000);
      }
    }
  };

  _deleteBook = async () => {
    let book = {
      Id: this.state.deleteBookId,
    };
    let res = await deleteBook(book, this._user.Token);
    if (res.status === 200) {
      success(Text.DeletedBook, 2000);

      let { Books, RowCount } = await this._getAllBooks("", "", 1);

      this.setState({
        deleteDialog: false,
      });

      this.props.setBooks(Books, RowCount);
    } else {
      error(res, 2000);
    }
  };

  _editBook = async () => {
    let validationResult = this._validation(this._editFieldNames);
    if (validationResult !== "") {
      warning(validationResult, 2000);
    } else {
      var publisher = this.props.Publishers.filter((item) => {
        return item.Name === document.getElementById("editPublisherName").value;
      });
      var course = this.props.Courses.filter((item) => {
        return item.Name === document.getElementById("editCourseName").value;
      });
      let book = {
        Id: this._editRowData[0].Id,
        Name: document.getElementById("editName").value,
        CourseId: course[0].Id,
        PublisherId: publisher[0].Id,
        PageImagesFileExtension: document.getElementById("editFileExtension")
          .value,
        PrintPublishYear: parseInt(
          document.getElementById("editPrintPublishYear").value
        ),
        PageCount: parseInt(document.getElementById("editPageCount").value),
        PublisherBookId: document.getElementById("editPublisherBookId").value,
        OriginalFileName: document.getElementById("editOriginalFileName").value,
        RowStatus: document.getElementById("editStatus").checked,
      };
      let res = await updateBook(book, this._user.Token);
      if (res.status === 200) {
        success(Text.EditedBook, 2000);

        let { Books, RowCount } = await this._getAllBooks("", "", 1);

        this.setState({
          editDialog: false,
        });

        this.props.setBooks(Books, RowCount);
      } else {
        error(res, 2000);
      }
    }
  };

  _getAllBooks = async (
    searchValue,
    orderColumn,
    orderBy,
    pageNumber = this.props.PageNumber
  ) => {
    let paging = {
      PageSize: _pageSize,
      PageNumber: pageNumber,
      SearchValue: searchValue,
      OrderColumn: orderColumn,
      OrderBy: orderBy,
    };

    let res = await getAllBooks(paging, this._user.Token);
    if (res.status === 200) {
      this._columns =
        res.data.Books.length > 0 ? Object.keys(res.data.Books[0]) : [];
      this._rows = this._getRows(res.data.Books) || [];
      return { Books: res.data.Books, RowCount: res.data.RowCount };
    } else {
      error(res, 2000);
    }
  };

  _getRows = (data) => {
    let cellComponentArray = [
      {
        Name: "Status",
        FalseComponent: (
          <GridSwitchButton checked={false} text={Text.DeActive} />
        ),
        TrueComponent: <GridSwitchButton checked={true} text={Text.Active} />,
      },
    ];
    return getRows(data, null, null, cellComponentArray);
  };

  _excelImport = async (e) => {
    let rows = await excelImport(e.target.files[0], this._csvHeader);
    let books = [];
    for (let i = 0; i < rows.length; i++) {
      var row = rows[i].split("\t");

      if (row[row.length - 1] === "") {
        row.pop();
      }

      let book = {
        Name: row[0],
        PublisherId: parseInt(row[2]),
        CourseId: parseInt(row[3]),
        PageImagesFileExtension: ".jpg",
        PrintPublishYear: row[1] !== "" ? parseInt(row[1]) : 0,
        PageCount: parseInt(row[6]),
        PublisherBookId: row[4],
        OriginalFileName: row[5],
      };
      books.push(book);
    }

    var filteredBooks = books.filter((item) => item.Name !== "");
    let res = await bulkInsertBooks(filteredBooks, this._user.Token);
    if (res.status === 200) {
      success(Text.ExcelBookDataUploadSuccess, 2000);

      let { Books, RowCount } = await this._getAllBooks("", "", 1);
      this.props.setBooks(Books, RowCount);
    } else {
      error(res, 2000);
    }
  };

  _onRowClick = (e) => {
    if (e.target.localName === "td") {
      var book = this.props.Books.filter(
        (item) => item.Id.toString() === e.currentTarget.id
      )[0];
      this.props.Links.push({
        Text: book.Name,
        href: "/booksections",
      });
      this.props.addLink(this.props.Links);

      this.props.setSelectedBookId(e.currentTarget.id);
      this.props.history.push("/booksections");
    }
  };

  _handleSearchChange = async (e) => {
    this.setState({
      searchValue: e.target.value,
    });

    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      let { Books, RowCount } = await this._getAllBooks(e.target.value, "", 1);
      this.props.setBooks(Books, RowCount);
    }
  };

  _headerClick = async (e) => {
    if (e.target.innerHTML !== "") {
      let column = e.target.innerHTML;
      if (column === "Status") {
        column = "RowStatus";
      }

      if (column === "Location") {
        column = "PageImagesLocation";
      }

      if (column === "FileExtension") {
        column = "PageImagesFileExtension";
      }

      if (
        column === "PublisherName" ||
        column === "CourseName" ||
        column === "ContentCount" ||
        column === "EnteredContentCount" ||
        column === "ApprovedContentCount"
      ) {
        warning(Text.DoesNotOrderColumn, 2000);
        return;
      }

      let orderBy =
        this._orderColumn !== column ? this._orderByAsc : !this._orderByAsc;
      this._orderByAsc = orderBy;
      this._orderColumn = column;

      let { Books, RowCount } = await this._getAllBooks(
        this.state.searchValue,
        column,
        this._orderByAsc === true ? 1 : 2
      );
      this.props.setBooks(Books, RowCount);
    }
  };

  render() {
    return (
      <div>
        <SideBar />

        <div style={{ ...styles.container }}>
          <PageHeader title={Text.Books} />
          <Grid
            stickyColumnCount={1}
            data={{
              columns: this._columns,
              rows: this._rows,
            }}
            toolbar={{
              create: true,
              createClick: this._createBook,
              excelImport: true,
              excelImportClick: this._excelImport,
            }}
            paging={{
              rowsPerPage: _pageSize,
              count: this.props.RowCount,
            }}
            editRow={this._editRow}
            deleteRow={this._deleteRow}
            rowClick={this._onRowClick}
            searchOnChange={this._handleSearchChange}
            searchValue={this.state.searchValue}
            headerClick={this._headerClick}
          />
        </div>

        <DialogPanel
          id="createDialog"
          isOpen={this.state.createDialog}
          onClose={() => this._handleClose("createDialog")}
          title={Text.CreateBook}
          actions={[
            {
              onClick: () => this._handleClose("createDialog"),
              text: Text.Cancel,
            },
            { onClick: this._addBook, text: Text.Add },
          ]}
          contentComponentNames={this._createFieldNames}
        />

        <DialogPanel
          id="deleteDialog"
          isOpen={this.state.deleteDialog}
          onClose={() => this._handleClose("deleteDialog")}
          title={Text.DeleteBookTitle}
          contentText={Text.DeleteBookQuestion}
          actions={[
            {
              onClick: () => this._handleClose("deleteDialog"),
              text: Text.Cancel,
            },
            { onClick: this._deleteBook, text: Text.Delete },
          ]}
        />

        <DialogPanel
          id="editDialog"
          isOpen={this.state.editDialog}
          onClose={() => this._handleClose("editDialog")}
          title={Text.EditBookTitle}
          actions={[
            {
              onClick: () => this._handleClose("editDialog"),
              text: Text.Cancel,
            },
            { onClick: this._editBook, text: Text.Edit },
          ]}
          contentComponentNames={this._editFieldNames}
          editRow={this._editRowData}
        />

        <Notification />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.userReducer.User,
  Books: state.bookReducer.Books,
  RowCount: state.bookReducer.RowCount,
  PageNumber: state.gridReducer.PageNumber,
  Publishers: state.publisherReducer.Publishers,
  Courses: state.courseReducer.Courses,
  Links: state.pageHeaderLinkReducer.Links,
});

const mapDispatchToProps = (dispatch) => ({
  setBooks: (books, rowCount) => dispatch(setAllBooks(books, rowCount)),
  setCourses: (courses) => dispatch(setAllCourses(courses)),
  setPublishers: (publishers) => dispatch(setAllPublishers(publishers)),
  setSelectedBookId: (bookId) => dispatch(setSelectedBookId(bookId)),
  setSelectedSideBar: (item) => dispatch(setSelectedItem(item)),
  setLinks: (links) => dispatch(setLinks(links)),
  addLink: (links) => dispatch(addLink(links)),
  logOutUser: () => dispatch(logoutUser()),
  setPageNumber: (pageNumber) => dispatch(setPageNumber(pageNumber)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BooksPage));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/styles';
import MuiTable from '@material-ui/core/Table';
import MuiTablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
//#endregion

//#region Internal
import { setPageNumber } from './../../redux/actions/gridAction';

import { Primary } from './../../assets/Colors';
//#endregion

//#region Override Style
const Table = withStyles({
    root: {
        backgroundColor: Primary.ContainerBackground,
        borderRadius: 4,
        boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.15)',
        borderCollapse: 'separate'
    }
})(MuiTable);

const TablePagination = withStyles({
    input:{
        display: 'none'
    },
    toolbar: {
        minHeight: 45,
        height: 45
    }
})(MuiTablePagination);
//#endregion

class GridPaging extends Component {
    constructor(props){
        super(props);

        this.state ={
        };
    }

    _previousPage = () => {
        this.props.setPageNumber(this.props.PageNumber - 1);
    };

    _nextPage = () => {
        this.props.setPageNumber(this.props.PageNumber + 1);
    };

    _handleChangePage = (e, newPage) => {
        this.props.setPageNumber(newPage);
    };

    render(){
        return(
            <div>
                <Table>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPage={this.props.paging.rowsPerPage}
                                count={this.props.paging.count}
                                onChangePage={this._handleChangePage}
                                page={this.props.PageNumber || 0}
                            >
                                <IconButton 
                                    disabled={this.props.PageNumber === 0}
                                    onClick={this._previousPage}
                                >
                                    <KeyboardArrowLeft/>
                                </IconButton>
                                <IconButton 
                                    disabled={this.props.PageNumber >= Math.ceil(this.props.paging.count / this.props.paging.rowsPerPage) - 1}
                                    onClick={this._nextPage}
                                >
                                    <KeyboardArrowRight/>
                                </IconButton>
                            </TablePagination>
                        </TableRow>
                    </TableFooter>
                </Table>
            </div>
        )
    }
};

GridPaging.propTypes = {
    paging: PropTypes.shape({
        rowsPerPage: PropTypes.number,
        count: PropTypes.number
    })
};

GridPaging.defaultProps = {
};

const mapStateToProps = (state) => ({
    PageNumber: state.gridReducer.PageNumber
});

const mapDispatchToProps = (dispatch) => ({
    setPageNumber: (pageNumber) => dispatch(setPageNumber(pageNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(GridPaging);
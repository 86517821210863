import { ApiUrl, ApiUrlAdmin } from "../common/Config";
import { postAdmin,post,get,getAdmin,postFormData } from "../common/Helper";

export const getAchievementCourses = async (token,examCategoryId) =>{
    let url = ApiUrl + `BookSectionCrop/getachivementcourses?examType=${examCategoryId}`
    return await get(url,token)
}
export const getAchievementSubCourseTopics = async (token,examType,courseId) =>{
    let url = ApiUrl + `BookSectionCrop/getachivementsubcourses?courseId=${courseId}&&examType=${examType}`
    return await get(url,token)
}
export const getAchievementQuestion = async (token,data) =>{
    let url = ApiUrl + `BookSectionCrop/getachievementquestion`
    return await post(url,data,token)
}
export const creatAchievementQuestion = async (token,data) =>{
    let url = ApiUrl + `BookSectionCrop/createachievementquestion`
    return await post(url,data,token)
}
export const getQuestionAchievements = async (token,data) =>{
    let url = ApiUrl + `BookSectionCrop/getachievements`
    return await post(url,data,token)
}
export const updateQuestionAchievements = async (token,data) =>{
    let url = ApiUrl + `BookSectionCrop/updateachievements`
    return await post(url,data,token)
}
export const nestedTitles = async (token) =>{
    let url = ApiUrl + "Book/getnestedtitles"
    return await get(url,token)
}

import React, {Component} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//#region Internal
import exceptionHandler from './exceptionHadler';
import './index.css';
//#endregion

export class Notification extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <ToastContainer
                position='top-right'
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnVisibilityChange
                draggable={false}
                pauseOnHover
            />
        );
    }
}

export const success = (message, autoClose = false) => {
    return (
        toast.success(message, {
            autoClose: autoClose,
            className: 'success',
            progressClassName: 'progress-bar'
        })
    );
};

export const error = (message, autoClose = false) => {
    let errors = exceptionHandler(message);
    errors.map(item => {
        toast.error(item, {
            autoClose: autoClose,
            className: 'error',
            progressClassName: 'progress-bar'
        })
    });
};

export const warning = (message, autoClose = false) => {
    return (
        toast.warn(message, {
            autoClose: autoClose,
            className: 'warning',
            progressClassName: 'progress-bar'
        })
    );
};
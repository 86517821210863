import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import ReactCrop from "react-image-crop";
import "./index.css";

//#region Material Components
import { withStyles } from "@material-ui/styles";
import MuiGrid from "@material-ui/core/Grid";
//#endregion

//#region Internal
import { Primary } from "./../../assets/Colors";

import Footer from "./Footer";
import AnswerKey from "./AnswerKey";
import CroppedPanel from "./CroppedPanel";

import {
  setCrop,
  addCroppedList,
  setMultiCropChecked,
  setCroppedList,
} from "./../../redux/actions/cropAction";
import { setActivity } from "./../../redux/actions/loadingAction";
//#endregion

//#region Override Style
const Grid = withStyles({
  "grid-xs-true": {
    flexGrow: 0,
  },
})(MuiGrid);
//#endregion

//#region Inline Style
const styles = {
  cropContainer: {
    backgroundColor: Primary.ContainerBackground,
    marginTop: 10,
    borderRadius: 10,
  },
  cropPanel: {
    width: 576,
    height: 821.1,
    border: "1px solid",
  },
};
//#endregion

class Crop extends Component {
  constructor(props) {
    super(props);

    this._naturalWidth = 0;
    this._naturalHeight = 0;

    this.state = {};
  }

  _handleMultiCropChange = () => {
    this.props.setMultiCropChecked(!this.props.MultiCropChecked);
  };
  _onImageLoaded = (image) => {
    let downloadedImage = new Image();
    downloadedImage.crossOrigin = "Anonymous";
    downloadedImage.src = image.src + "?XHRProtection=true";
    downloadedImage.width = image.width;
    downloadedImage.height = image.height;
    this._naturalWidth = image.naturalWidth;
    this._naturalHeight = image.naturalHeight;
    this._cropImg = downloadedImage;
  };

  _onAnswerImageLoaded = (image) => {
    let downloadedImage = new Image();
    downloadedImage.crossOrigin = "Anonymous";
    downloadedImage.src = image.src + "?XHRProtection=true";
    downloadedImage.width = image.width;
    downloadedImage.height = image.height;
    this._answerNaturalWidth = image.naturalWidth;
    this._answerNaturalHeight = image.naturalHeight;
    this._answerCropImg = downloadedImage;
  };

  _onCropComplete = (crop) => {
    this._makeClientCrop(crop, true);
  };

  _onAnswerCropComplete = (crop) => {
    this._answerMakeClientCrop(crop, true);
  };

  _onCropChange = (crop) => {
    if (crop.x !== 0 && crop.y !== 0) {
      this.props.setCrop(
        {
          ...crop,
          NaturalWidth: this._naturalWidth,
          NaturalHeight: this._naturalHeight,
        },
        this.props.AnswerCropDimensions,
        this.props.CroppedImage,
        this.props.AnswerCroppedImage,
        this.props.CropSrc,
        this.props.AnswerCropSrc
      );
    }
  };

  _onAnswerCropChange = (crop) => {
    if (crop.x !== 0 && crop.y !== 0) {
      this.props.setCrop(
        this.props.CropDimensions,
        {
          ...crop,
          NaturalWidth: this._naturalWidth,
          NaturalHeight: this._naturalHeight,
        },
        this.props.CroppedImage,
        this.props.AnswerCroppedImage,
        this.props.CropSrc,
        this.props.AnswerCropSrc
      );
    }
  };

  _makeClientCrop = (crop, status) => {
    if (this._cropImg && crop.width && crop.height) {
      if (!status) {
        this.props.setActivity(true);
      }

      if (this._cropImg.complete) {
        this._getCroppedImg(this._cropImg, crop, "newFile.jpeg");
      }
      //REmoved This becasue Of Multiple Calls
      /*
      this._cropImg.onload = (() => {
        this._getCroppedImg(this._cropImg, crop, "newFile.jpeg");
      }).bind(this);
      */
    } else {
      this.props.setCrop(
        {
          ...crop,
          NaturalWidth: this._naturalWidth,
          NaturalHeight: this._naturalHeight,
        },
        this.props.AnswerCropDimensions,
        null,
        this.props.AnswerCroppedImage,
        this.props.CropSrc,
        this.props.AnswerCropSrc
      );
    }
  };

  _answerMakeClientCrop = (crop, status) => {
    if (this._answerCropImg && crop.width && crop.height) {
      if (!status) {
        this.props.setActivity(true);
      }

      if (this._answerCropImg.complete) {
        this._getCroppedImg(this._answerCropImg, crop, "newFile.jpeg", true);
      }

      //MTN Removed This Because Multiple Add
      /*
      this._answerCropImg.onload = (() => {
        this._getCroppedImg(this._answerCropImg, crop, "newFile.jpeg", true);
      }).bind(this);
      */
    } else {
      this.props.setCrop(
        this.props.CropDimensions,
        {
          ...crop,
          NaturalWidth: this._naturalWidth,
          NaturalHeight: this._naturalHeight,
        },
        this.props.CroppedImage,
        null,
        this.props.CropSrc,
        this.props.AnswerCropSrc
      );
    }
  };

  _getCroppedImg = (image, crop, fileName, answer = false) => {
    const canvas = document.createElement("canvas");
    const scaleX = this._naturalWidth / image.width;
    const scaleY = this._naturalHeight / image.height;
    canvas.width = crop.width * scaleX;
    canvas.height = crop.height * scaleY;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    canvas.toBlob((blob) => {
      if (!blob) {
        return;
      }
      const newCrop = {
        ImageData: blob,
        Path: this.props.CropDimensions,
      };
      if (this.props.MultiCropChecked) {
        this.props.addCroppedList(newCrop);
      } else {
        this.props.setCroppedList([newCrop]);
      }

      blob.name = fileName;
      blob.lastModifiedDate = new Date();

      if (answer) {
        this.props.setCrop(
          this.props.CropDimensions,
          {
            ...crop,
            NaturalWidth: this._naturalWidth,
            NaturalHeight: this._naturalHeight,
          },
          this.props.CroppedImage,
          blob,
          this.props.CropSrc,
          this.props.AnswerCropSrc
        );
        return;
      }
      this.props.setCrop(
        {
          ...crop,
          NaturalWidth: this._naturalWidth,
          NaturalHeight: this._naturalHeight,
        },
        this.props.AnswerCropDimensions,
        blob,
        this.props.AnswerCroppedImage,
        this.props.CropSrc,
        this.props.AnswerCropSrc
      );

      this.props.setActivity(false);
    }, "image/jpeg");
  };

  render() {
    return (
      <div>
        <Grid container style={{ ...styles.cropContainer }}>
          {this.props.answerSrc !== null && (
            <AnswerKey
              onChangeSyncWithProduction={this.props.onChangeSyncWithProduction}
              SyncWithProduction={this.props.SyncWithProduction}
              previousQuestion={this.props.previousQuestion}
              nextQuestion={this.props.nextQuestion}
              croppedDialog={this.props.croppedDialog}
              nextQuestionText={this.props.nextQuestionText}
              s3CroppedDialog={this.props.s3CroppedDialog}
            />
          )}

          <Grid container style={{ padding: 10 }}>
            <Grid item>
              <ReactCrop
                src={this.props.CropSrc}
                crop={this.props.CropDimensions}
                onImageLoaded={this._onImageLoaded}
                onComplete={this._onCropComplete}
                onChange={this._onCropChange}
                style={{ ...styles.cropPanel }}
                imageStyle={{
                  width: "100%",
                  height: "100%",
                }}
              />

              <Footer
                firstPage={this.props.questionFirstPage}
                lastPage={this.props.questionLastPage}
                previousPage={this.props.questionPreviousPage}
                nextPage={this.props.questionNextPage}
                pageNumber={this.props.questionPageNumber}
                isMultiCrop={true}
                onChange={this._handleMultiCropChange}
              />
            </Grid>

            {this.props.AnswerCropSrc !== null && (
              <Grid
                item
                style={{
                  marginLeft: 5,
                }}
              >
                <ReactCrop
                  src={this.props.AnswerCropSrc}
                  crop={
                    this.props.typeId === 2 && this.props.AnswerCropDimensions
                  }
                  onImageLoaded={this._onAnswerImageLoaded}
                  onComplete={this._onAnswerCropComplete}
                  onChange={this._onAnswerCropChange}
                  style={{ ...styles.cropPanel }}
                  imageStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                />

                <Footer
                  firstPage={this.props.answerFirstPage}
                  lastPage={this.props.answerLastPage}
                  previousPage={this.props.answerPreviousPage}
                  nextPage={this.props.answerNextPage}
                  pageNumber={this.props.answerPageNumber}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        {this.props.MultiCropChecked && <CroppedPanel />}
      </div>
    );
  }
}

Crop.propTypes = {
  previousQuestion: PropTypes.func.isRequired,
  nextQuestion: PropTypes.func.isRequired,
  questionFirstPage: PropTypes.func.isRequired,
  questionLastPage: PropTypes.func.isRequired,
  questionPreviousPage: PropTypes.func.isRequired,
  questionNextPage: PropTypes.func.isRequired,
  answerFirstPage: PropTypes.func.isRequired,
  answerLastPage: PropTypes.func.isRequired,
  answerPreviousPage: PropTypes.func.isRequired,
  answerNextPage: PropTypes.func.isRequired,
  questionPageNumber: PropTypes.number.isRequired,
  answerPageNumber: PropTypes.number.isRequired,
  croppedDialog: PropTypes.func,
  nextQuestionText: PropTypes.string,
  typeId: PropTypes.number,
};

Crop.defaultProps = {};

const mapStateToProps = (state) => ({
  CropDimensions: state.cropReducer.CropDimensions,
  AnswerCropDimensions: state.cropReducer.AnswerCropDimensions,
  CroppedImage: state.cropReducer.CroppedImage,
  AnswerCroppedImage: state.cropReducer.AnswerCroppedImage,
  CropSrc: state.cropReducer.CropSrc,
  AnswerCropSrc: state.cropReducer.AnswerCropSrc,
  CroppedList: state.cropReducer.CroppedList,
  MultiCropChecked: state.cropReducer.MultiCropChecked,
});

const mapDispatchToProps = (dispatch) => ({
  setCrop: (
    cropDimensions,
    answerCropDimensions,
    croppedImage,
    answerCroppedImage,
    cropSrc,
    answerCropSrc
  ) =>
    dispatch(
      setCrop(
        cropDimensions,
        answerCropDimensions,
        croppedImage,
        answerCroppedImage,
        cropSrc,
        answerCropSrc
      )
    ),
  addCroppedList: (cropped) => dispatch(addCroppedList(cropped)),
  setMultiCropChecked: (multiCropChecked) =>
    dispatch(setMultiCropChecked(multiCropChecked)),
  setCroppedList: (croppedList) => dispatch(setCroppedList(croppedList)),
  setActivity: (state) => dispatch(setActivity(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Crop));

import React, { useState, useEffect, useRef } from 'react';
import './Dropzone.css'
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'

const Dropzone = (folderNames) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [validFiles, setValidFiles] = useState([]);
    const [unsupportedFiles, setUnsupportedFiles] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState('default');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [filesFinished, setFilesFinished] = useState(0);
    const [lastFileLoaded, setLastFileLoaded] = useState(false);
    const modalImageRef = useRef();
    const modalRef = useRef();
    const fileInputRef = useRef();
    const uploadModalRef = useRef();
    const uploadRef = useRef();
    const progressRef = useRef();

    const user = JSON.parse(localStorage.getItem("user"))
    const userAdminToken = sessionStorage.getItem("jwtP")

    const handleSelectChange = (e) => {
        setSelectedFolder(e.target.value);
    };

    const validateFile = (file) => {
        const validTypes = ['image/png', 'application/pdf'];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    }
    useEffect(() => {
        let filteredArray = selectedFiles.reduce((file, current) => {
            const x = file.find(item => item.name === current.name);
            if (!x) {
                return file.concat([current]);
            } else {
                return file;
            }
        }, []);
        setValidFiles([...filteredArray]);

    }, [selectedFiles]);
    const handleFileProcessed = (files) => {
        setFilesFinished((prevFilesFinished) => prevFilesFinished + 1);

        // Check if all files are processed (when filesFinished equals the total number of files)
        if (filesFinished + 1 === files.length) {
            console.log('Last file processed!');
            // Perform any other actions or set states accordingly
        }
    };
    const handleFiles = (files) => {
        //const file = files[0];




        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (validateFile(file)) {
                setSelectedFiles(prevArray => [...prevArray, file]);
                // add to an array so we can display the name of file
                if (file.type === 'application/pdf') {
                    // For PDF files, create a blob URL to be used as the iframe source
                    const filePreview = URL.createObjectURL(file);
                    setFilePreviews((prevPreviews) => [...prevPreviews, filePreview]);
                } else {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const filePreview = e.target.result;
                        setFilePreviews((prevPreviews) => [...prevPreviews, filePreview]);
                    };
                    reader.readAsDataURL(file);
                }
            } else {
                toast.error("Sadece .png ve.pdf uzantılar dosyaları yükleyebilirisniz")
                setUnsupportedFiles(prevArray => [...prevArray, files[i]]);


                // add a new property called invalid
                // add to the same array so we can display the name of the file
                // set error message
            }
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                // Dosya başarıyla okundu
                // Burada dosya verileri ile bir şeyler yapabiliriz, örneğin önizleme sağlayabiliriz
                //console.log('File content:', e.target.result);
                if (i === files.length - 1) {
                    console.log('Last file processed!');
                    setLastFileLoaded(true)
                }

            };

            fileReader.onprogress = (e) => {
                // Dosya okuma ilerlemesi hesaplaması
                if (e.lengthComputable) {
                    const progress = (e.loaded / e.total) * 100;
                    setUploadProgress(progress); // İlerleme yüzdesini güncelle

                }

            };

            fileReader.onerror = (e) => {
                // Dosya okuma sırasında bir hata oluştu
                console.error('Error reading file:', e);
            };

            // Dosyayı okuma işlemine başla
            fileReader.readAsArrayBuffer(file);
        }
    }
    const dragOver = (e) => {
        e.preventDefault();
    }

    const dragEnter = (e) => {
        e.preventDefault();
    }

    const dragLeave = (e) => {
        e.preventDefault();
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
            handleFiles(files);
        }
        //console.log(files);
    }
    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }
    const fileType = (fileName) => {
        return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
    }
    const removeFile = (name) => {
        // find the index of the item
        // remove the item from array

        const validFileIndex = validFiles.findIndex(e => e.name === name);
        validFiles.splice(validFileIndex, 1);
        // update validFiles array
        setValidFiles([...validFiles]);
        filePreviews.splice(validFileIndex, 1)
        setFilePreviews([...filePreviews])
        const selectedFileIndex = selectedFiles.findIndex(e => e.name === name);
        selectedFiles.splice(selectedFileIndex, 1);
        // update selectedFiles array
        setSelectedFiles([...selectedFiles]);
        const unsupportedFileIndex = unsupportedFiles.findIndex(e => e.name === name);
        if (unsupportedFileIndex !== -1) {

            unsupportedFiles.splice(unsupportedFileIndex, 1);
            // update unsupportedFiles array
            setUnsupportedFiles([...unsupportedFiles]);
        }
    }
    const openImageModal = (file) => {

        const reader = new FileReader();
        modalRef.current.style.display = "block";
        reader.readAsDataURL(file);
        reader.onload = function (e) {
            modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
        }




    }
    const fileInputClicked = () => {
        fileInputRef.current.click();
    }
    const filesSelected = () => {
        if (fileInputRef.current.files.length) {
            handleFiles(fileInputRef.current.files);
        }
    }
    const uploadFiles = () => {
        uploadModalRef.current.style.display = 'block';
        uploadRef.current.innerHTML = 'File(s) Uploading...';
        const formData = new FormData();
        formData.append('Folder', selectedFolder);
        for (let i = 0; i < validFiles.length; i++) {
            formData.append('FormFile', validFiles[i]);
        }
        const headers = {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + userAdminToken,
            "Content-Type": "multipart/form-data",
        };
        Axios.post('https://contentprocess.tosanalytics.com/api/Mediafile/uploads', formData, {
            headers: headers,
            onUploadProgress: (progressEvent) => {
                const progress = (progressEvent.loaded / progressEvent.total) * 100;
                // Update the progress bar width and style
                progressRef.current.style.width = `${progress}%`;
                progressRef.current.style.color = `green`;
            },
        }).then((response) => {
            if (response.data.responseStatus === 0) {
                // Handle error here (response.data.responseMessage contains the error message)
                console.error('Error uploading files:', response.data.responseMessage);
                uploadRef.current.innerHTML = `<span class="error">Error Uploading File(s): ${response.data.responseMessage}</span>`;
                progressRef.current.style.backgroundColor = 'red';
            } else {
                // Handle successful upload here (if needed)
                console.log('Upload successful', response);
                uploadRef.current.innerHTML = 'File(s) Uploaded Successfully';
                progressRef.current.style.backgroundColor = 'green';
            }
        })
            .catch((error) => {
                // Handle any other errors (e.g., network errors)
                console.error('Error uploading files:', error);
                uploadRef.current.innerHTML = `<span class="error">Error Uploading File(s)</span>`;
                progressRef.current.style.backgroundColor = 'red';
            });

    }
    const closeModal = () => {
        modalRef.current.style.display = "none";
        modalImageRef.current.style.backgroundImage = 'none';
    }

    const closeUploadModal = () => {
        uploadModalRef.current.style.display = 'none';
        setValidFiles([])
        setSelectedFolder("default")
        setSelectedFiles([])

    }
    const folderNamesAsOptions = folderNames.folderNames;
    return (
        <div className='container-wrapper'>
            <ToastContainer />
            {folderNamesAsOptions.length > 0 && <select defaultValue="default" onChange={handleSelectChange} value={selectedFolder} >
                <option value="default"  >
                    Dosya Seçiniz
                </option>
                {folderNamesAsOptions.map((folder, index) => (
                    <option key={index} value={folder.folderName}>
                        {folder.folderName}
                    </option>
                ))}
            </select>}
            <div className='upload-btn-container' style={{}}>

{unsupportedFiles.length === 0 && validFiles.length && selectedFolder !== 'default' ? <button className="file-upload-btn" onClick={() => uploadFiles()}>Upload Files</button> : ''}
</div>
            <div className="container">
                {/* {unsupportedFiles.length ? <p>Please remove all unsupported files.</p> : ''} */}
                <div className="modal" ref={modalRef}>
                    <div className="overlay"></div>
                    <span className="close" onClick={(() => closeModal())}>X</span>
                    <div className="modal-image" ref={modalImageRef}></div>
                </div>
                <div className="upload-modal" ref={uploadModalRef}>
                    <div className="overlay"></div>
                    <div className="close" onClick={(() => closeUploadModal())}>X</div>
                    <div className="progress-container">
                        <span ref={uploadRef}></span>
                        <div className="progress">
                            <div className="progress-bar" ref={progressRef}></div>
                        </div>
                    </div>
                </div>
               
                <div className="drop-container"
                    onClick={fileInputClicked}
                    onDragOver={dragOver}
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={fileDrop}>

                    <div className="drop-message">
                        <input
                            accept=".png, .pdf"
                            ref={fileInputRef}
                            className="file-input"
                            type="file"
                            multiple
                            onChange={filesSelected}
                        />
                        <div className="upload-icon"></div>
                        Dosyalarınızı Yüklemek için bu alana sürükleyiniz.<br />Dosya Seçimi yapmadan yükleme yapamazsınız.
                    </div>

                </div>
                <div className="file-display-container">
                    <div className="progress-container-File">
                        <div className="progress-bar-File" style={{ width: `${uploadProgress}%` }}></div>
                    </div>
                    {

                        validFiles.map((data, i) =>
                            <div className="file-status-bar" key={i}>

                                <div onClick={!data.invalid ? () => openImageModal(data) : () => removeFile(data.name)}>

                                    {data.type === 'application/pdf' ? (
                                        <div
                                            className="file-type-logo"
                                            style={{
                                                backgroundImage: `url(${'./pdfFile.png'})`,
                                            }}
                                        ></div>
                                    ) : (
                                        <div
                                            className="file-type-logo"
                                            style={{
                                                backgroundImage: `url(${filePreviews[i] || './file.png'})`,
                                            }}
                                        ></div>
                                    )}

                                    <div className="file-type">{fileType(data.name)}</div>
                                    <span className={`file-name ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                                    <span className="file-size">({fileSize(data.size)})</span> {data.invalid && <span className='file-error-message'>({errorMessage})</span>}
                                </div>
                                <div className="file-remove" onClick={() => removeFile(data.name)}>X</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
export default Dropzone;
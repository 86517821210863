const exceptionHandler = (err) => {
    try {
        let errors = [];
        if(err.response !== undefined && err.response.data !== undefined) {
            errors = err.response.data.Error.split(';');
        } else {
            errors.push(err.message);
        }
    
        return errors;
        
    } catch (error) {
        return []
    }

};

export default exceptionHandler;
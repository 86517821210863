import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SideBar from "../../components/SideBar";
import { Components } from "../../assets/Dimensions";
import SwitchComponent from "../../components/SwitchComponent";
import { ToastContainer, toast } from "react-toastify";
import "../questionVideoSolution/style.css";
import {
  getCourses,
  nestedTitles,
} from "../../api/QuestionSubCourseSelection/courses";
import Toggle from "../questionVideoSolution/components/toggle/toggle";
import { encryption, base64Encode } from "../../helpers/encryptionHelper";
import CourseBG from "../../assets/images/bg-lesson.jpeg";
import ExamCategoryType from "../../enums/ExamCategoryType";
import ExamType from "../../enums/ExamType";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { setCookie } from "../../helpers/cookie";
import QuestionPage from "../questionSubTopicSelection/QuestionPage";
import { useHistory } from "react-router-dom";
import {
  getAchievementCourses,
  getAchievementSubCourseTopics,
} from "../../api/QuestionAchivments/coursesAndQuestions";
import { getKpssCourses,getKpssSubCourses } from "../../api/kpssCompatibility/kpssQuestions";
import Modal from "../../components/cutomModal";
import Loader from "@material-ui/core/CircularProgress"
import { RatioBasedColor } from "../../util/ratioBasedColor";

const CoursePage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const user = JSON.parse(localStorage.getItem("user"));
  const userAdminToken = sessionStorage.getItem("jwtP");

  const [nav, setNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedExamCategory, setSelectedExamCategory] = useState(JSON.parse(localStorage.getItem("SelectedExamCategory"))||null);
  const [coursesData, setCoursesData] = useState([]);
  const [allCourseData, setAllCourseData] = useState(null);
  const [examTypeId, setExamTypeId] = useState(JSON.parse(localStorage.getItem("selectedExamTypeId"))||1);
  const [subCoursesData, setSubCoursesData] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState(JSON.parse(localStorage.getItem("selectedCourseIdKPSSComp"))||null);
  const [selectedCourseName, setSelectedCourseName] = useState(localStorage.getItem("selectedCourseNameKPSSComp")||null);
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [ratioAlert,setRatioAlert] = useState(false)
  const [loader,setLoader] = useState(false)

  const history = useHistory();

  const style = {
    container: {
      //state e bool değer ekle menü butonuna basıldığında css
      //içinde container a margin eklesin hemde navı açsın

      //marginLeft: isTabletOrMobile ? 0 : Components.SideBarWidth + 15,
      paddingLeft: isTabletOrMobile ? 15 : Components.SideBarWidth + 15,
      paddingTop: 20,
      paddingRight: 15,
      marginBottom: 20,
    },
    editIcon: {
      width: Components.Image.Big.Width,
      height: Components.Image.Big.Height,
    },
    iconButton: {
      marginLeft: 10,
      padding: 0,
    },
  };

  const RatioCalculate = (completedCount, totalCount) => {
    if (totalCount === 0) {
      return 0; // or return null, undefined, or any other value you prefer
    }
    return Math.round((completedCount / totalCount) * 100);
  };

  const goToQuestionPage = (subTopic) => {


    history.push({
      pathname: "/KpssCompatibilitySelection",
      state: {
          selectedCourseName: selectedCourseName,
      selectedSubCourseTopicName: subTopic.NewSubCourseTopicName,
        selectedSubCourseData: subTopic.SubCourseTopicUniqueId,
      },
    });
  };

  const getCategoryName = (value) => {
    for (const key in ExamCategoryType) {
      if (ExamCategoryType[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };
  const getExamTypeName = (value) => {
    for (const key in ExamType) {
      if (ExamType[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };

  const filterCoursesData = (data, categoryId, ExamTypeId) => {
    const category = data.find((category) => category.ExamType === ExamTypeId);
    if (!category) {
      console.log("Category not found");
      return;
    }

    // const examType = category.ExamCategoryCourses.find(
    //   (examType) => examType.ExamTypeId === ExamTypeId
    // );
    // if (!examType) {
    //   console.log("Exam type not found in the category");
    //   return;
    // }

    return setCoursesData(category.Courses);
  };

  const getCoursesData = async (value) => {
    setLoader(true);
    try {
        const res = await getKpssCourses(user.Token);
  
        if (res.data.ResponseStatus === 1) {
          localStorage.setItem("AchievementCourses", JSON.stringify(res.data.ResponseData));
          
          setCoursesData(res.data.ResponseData)
        } else {
          toast.error("Bir hata oluştu lütfen yetkililerle iletişime geçiniz.");
        }
    } catch (error) {
      toast.error("Bir hata oluştu lütfen yetkililerle iletişime geçiniz.");
      console.error("Error fetching achievement courses:", error);
    } finally {
      setLoader(false);
    }
  };
  

  const getSubCourseTopics = async (courseId) => {
    try {
      setLoader(true); // Set loader to true when starting the request
      const res = await getKpssSubCourses(user.Token, courseId);
      
      console.log("<<<<res>>>>", res);
  
      if (res.data.ResponseStatus === 1) {
        setSubCoursesData(res.data.ResponseData);
        if(res.data.ResponseData.length==0){
          console.log("res.data.ResponseData", res.data.ResponseData);
          toast.error("Bu Ders ile ilgili Kazanımı seçilecek konu yoktur.")
        }
      } else {
        toast.error("bir sorun oluştu lütfen yetkililere ulaşınız.");
      }
    } catch (error) {
      toast.error("Bir hata oluştu lütfen yetkililerle iletişime geçiniz.");
      console.error("Error fetching sub course topics:", error);
    } finally {
      setLoader(false); // Ensure loader is set to false regardless of success or failure
    }
  };

  

  useEffect(() => {
    getCoursesData();
  }, [selectedExamCategory]);
  useEffect(()=>{
    if (selectedCourseId) {
      getSubCourseTopics(
        selectedCourseId,
        selectedExamCategory == 2 ? ExamType.LGS : examTypeId
      );
    }
  },[selectedCourseId])

  return (
    <>
      {isTabletOrMobile ? (
        <div>
          <span
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={() => setNav(!nav)}
          >
            menu
          </span>
          {nav && <SideBar />}
        </div>
      ) : (
        <SideBar />
      )}
 {loader&&<div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Loader size={100} />
          </div>}
          <ToastContainer />
{/* {ratioAlert && (
      <Modal
        isOpen={ratioAlert}
        onClose={() => {
          setRatioAlert(false);
           // Reset selected subtopic
           history.push({
            pathname: "/KpssCompatibilitySelection",
            state: {
                selectedCourseName: selectedCourseName,
            selectedSubCourseTopicName: subTopic.NewSubCourseTopicName,
              selectedSubCourseData: subTopic.SubCourseTopicUniqueId,
            },
          });

        }}
        onButtonClick={() => {
            setRatioAlert(false)
            setSelectedSubTopic(null)
        }}
        title={"KPSS Soru Eşleştirme Tamamlanmıştır"}
        buttonText={"Başka Konuya Geç"}
        content={"Bu konunun KPSS Soru Eşleştirmesi %100 oraninda tamamlanmıstır. Diğer konuya gecin."}
        closeButtonText={"Yine de devam et "}
      />
    )} */}

      <div style={style.container}>
        <div style={{ fontWeight: "bold", fontSize: "20px" }}>
          KPSS Soru Eşleştirme
        </div>

        {coursesData.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              marginRight: "auto",
              marginLeft: "auto",
              justifyContent: "center",
            }}
          >
            {coursesData.map((data) => {
              return (
                <>
                  <div
                    onClick={() => {
                      localStorage.setItem("selectedCourseIdKPSSComp",data.CourseId)
                      localStorage.setItem("selectedCourseNameKPSSComp",data.Name)
                      setSelectedCourseId(data.CourseId);
                      setSelectedCourseName(data.Name)
                      // history.push({
                      //   pathname: "/questionSubTopicSelection",
                      //   state: {
                      //     courseId: data.CourseId,
                      //     courseName: data.CourseName,
                      //     examType:
                      //       selectedExamCategory == 2 ? ExamType.LGS : examTypeId,
                      //   },
                      // });
                      // getSubCourseTopics(
                      //   data.CourseId,
                      //   selectedExamCategory == 2 ? ExamType.LGS : examTypeId
                      // );
                    }}
                    style={{
                      //background: 'linear-gradient(to right, #FFFFFF, #053EDD)',
                      color: "#FFFFFF",
                      backgroundImage: `url(${CourseBG})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      padding: 30,
                      display: "flex",
                      justifyContent: "space-between",
                      borderRadius: 8,
                      flexWrap: "wrap",
                    }}
                  >
                    <div>{data.Name}</div>
                    <div>{`Tamamlanma Yüzdesi: ${RatioCalculate(
                                  data.CompletedCount,
                                  data.TotalCount
                                )}%`}</div>
                  </div>
                  {selectedCourseId == data.CourseId &&
                    subCoursesData.length > 0 && (
                      <div
                        style={{
                          padding: 10,
                          borderWidth: 1,
                          borderRadius: 8,
                          borderStyle: "solid",
                        }}
                      >
                        {subCoursesData.map((subTopic) => {
                          return (
                            <div
                            key={subTopic.Id}
                              onClick={() => {
                                setSelectedSubTopic(subTopic);
                                goToQuestionPage(subTopic);
                                // history.push({
                                //   pathname: "/AchievementSelection",
                                //   state: {
                                //     selectedSubCourseData: subTopic,
                                //   },
                                // });
                              }}
                              style={{
                                margin: 10,
                                borderWidth: 3,
                                borderRadius: 8,
                                borderStyle: "solid",
                                borderColor:RatioBasedColor(
                                    subTopic.CompletedCount
                                ),
                                padding: 10,
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>{subTopic.NewSubCourseTopicName}</div>
                              <div
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                Tamamlanma yüzdesi:{" "}
                                {subTopic.CompletedCount}
                                %
                                <span
                                  style={{
                                    marginLeft: 10,
                                    height: "15px",
                                    width: "15px",
                                    backgroundColor: RatioBasedColor(
                                        subTopic.CompletedCount
                                    ),
                                    borderRadius: "50%",
                                    display: "inline-block",
                                  }}
                                ></span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                </>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default CoursePage;

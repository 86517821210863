import { ApiUrl , ApiUrlAdmin} from './common/Config';
import { post, postFormData,get,getAdmin } from './common/Helper';

const baseUrl = ApiUrl + 'booksectioncrop/';
const baseUrlAdmin = ApiUrlAdmin + 'booksectioncrop/'

export const create = async(data, token) => {
    let url = baseUrl + 'create';
    return await postFormData(url, data, token);
};

export const getBookSectionCrop = async(data, token) => {
    let url = baseUrl + 'get';
    return await post(url, data, token);
};

export const isExistBookSectionQuestion = async(data, token) => {
    let url = baseUrl + 'isexistquestion';
    return await post(url, data, token);
};

export const update = async(data, token) => {
    let url = baseUrl + 'update';
    return await postFormData(url, data, token);
};
export const updateProd = async(data, token) => {
    let url = baseUrlAdmin + 'update';
    return await postFormData(url, data, token);
};

export const getCroppedImage = async(data, token) => {
    let url = baseUrl + 'getcroppedimage';
    return await post(url, data, token);
};
export const getCroppedImageProd = async(data, token) => {
    let url = baseUrlAdmin + 'getcroppedimage';
    return await post(url, data, token);
};

export const createSectionPart = async(data, token) => {
    let url = baseUrl + 'createsectionpart';
    return await postFormData(url, data, token);
};
export const createSectionPartProd = async(data, token) => {
    let url = baseUrlAdmin + 'createsectionpart';
    return await postFormData(url, data, token);
};

export const deleteSectionParts = async(data, token) => {
    let url = baseUrl + 'deletesectionparts';
    return await post(url, data, token);
};
export const deleteSectionPartsProd = async(data, token) => {
    let url = baseUrlAdmin + 'deletesectionparts';
    return await post(url, data, token);
};

export const getPage = async(data, token) => {
    let url = baseUrl + 'getpage';
    return await post(url, data, token);
};
export const deleteQuestion = async (data, token) =>{
    let url =baseUrl + 'delete?bookSectionCropId='
    return await get(url+data,token)
}
export const deleteQuestionAdmin = async (data, token) =>{
    let url =baseUrlAdmin + 'delete?bookSectionCropId='
    return await get(url+data,token)
}
export const deleteVideo = async (data, token) =>{
    let url =baseUrl + 'deletevideo?bookSectionCropId='
    return await get(url+data,token)
}
export const deleteVideoAdmin = async (data, token) =>{
    let url =baseUrlAdmin + 'deletevideo?bookSectionCropId='
    return await get(url+data,token)
}
export const getReportIssueType = async (token) => {
    let url = baseUrl + 'getreportissuetype'
    return await get(url,token)
}
export const sendReport = async(data, token) => {
    let url = baseUrl + 'createreportquestionrequest';
    return await post(url, data, token);
};
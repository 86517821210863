import { CropActions } from './../ActionTypes';

export const setCrop = (cropDimensions, answerCropDimensions, croppedImage, answerCroppedImage, cropSrc, answerCropSrc) => ({
    type: CropActions.SetAllOptions,
    cropDimensions,
    answerCropDimensions,
    croppedImage,
    answerCroppedImage,
    cropSrc,
    answerCropSrc
});

export const addCroppedList  = (cropped) => ({
    type: CropActions.AddCroppedList,
    cropped
});

export const removeCroppedList  = (cropped) => ({
    type: CropActions.RemoveCroppedList,
    cropped
});

export const clearCroppedList = () => ({
    type: CropActions.ClearCroppedList
});

export const setCroppedList = (croppedList) => ({
    type: CropActions.SetCroppedList,
    croppedList
});

export const setMultiCropChecked = (multiCropChecked) => ({
    type: CropActions.SetMultiCropChecked,
    multiCropChecked
});
export const setIsFirst = (data) => ({
    type: CropActions.SetFirstLoad,
    data
});
import { LoadingActions } from './../ActionTypes';

const initialState = {
    Activity: false
};

export const loadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case LoadingActions.SetActivity:
            return Object.assign({}, state, { 
                Activity: action.state
            });
        default:
            return state;
    }
};
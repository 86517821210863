import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

//#region Internal
import SideBar from './../components/SideBar'
import PageHeader from './../components/Base/PageHeader';
import Grid from './../components/Grid/index';
import GridSwitchButton from './../components/Grid/GridSwitchButton';
import DialogPanel from './../components/Base/DialogPanel';
import { Notification, success, error, warning } from './../components/Notification/index';
import excelImport from './../util/excelImport';
import getRows from './../util/getRows';

import { Components } from './../assets/Dimensions';
import { Text } from './../assets/Strings';

import { getAllBookSections, createBookSection, deleteBookSection, updateBookSection, bulkInsertBookSections } from './../api/BookSection';
import { getAllBookSectionTypes } from './../api/BookSectionType';
import { getAllCourseTopics } from './../api/CourseTopic';
import { getAllSubCourseTopics } from './../api/SubCourseTopic';
import { getAllBookSectionSourceTypes } from '../api/BookSectionSourceType';

import { setAllBookSections, setSelectedBookSectionId } from '../redux/actions/bookSectionAction';
import { setAllBookSectionTypes } from '../redux/actions/bookSectionTypeAction';
import { setAllCourseTopics } from '../redux/actions/courseTopicAction';
import { setAllSubCourseTopics } from '../redux/actions/subCourseTopicAction';
import { setSelectedItem } from './../redux/actions/sidebarAction';
import { setLinks, addLink } from './../redux/actions/pageHeaderLinkAction';
import { logoutUser } from './../redux/actions/userAction';
import { setBookSectionSourceTypes } from './../redux/actions/bookSectionSourceTypeAction';
import { setPageNumber } from './../redux/actions/gridAction';
//#endregion

//#region Variables
const _pageSize = 6;
//#endregion

//#region Inline Style
const styles = {
    container: {
        marginLeft: Components.SideBarWidth + 15,
        paddingTop: 20,
        marginRight: 30
    }
}
//#endregion

class BookSectionsPage extends Component {
    constructor(props){
        super(props);

        this._createFieldNames = ['newBookSectionName', 'newBookSectionSourceTypeName','newBookSectionTypeName','newCourseTopicName','newSubCourseTopicName','newStartPage','newCropPerPage','newAnswerPage','newTotalCropCount'];
        this._editFieldNames = ['editRowStatus', 'editBookSectionSourceTypeName','editName','editBookSectionTypeName','editCourseTopicName','editSubCourseTopicName','editStartPage','editCropPerPage','editAnswerPage','editTotalCropCount'];
        this._csvHeader = ['TOPIC','SUB-TOPIC','TYPE','NAME','STARTING PAGE','# of ITEMS','Answer page','# OF CROP PER PAGE','SourceType'];
        this._rows=[];
        this._columns=[];
        this._editRowData=[];
        this._user={};
        this._orderColumn='';
        this._orderByAsc=true;

        this.state={
            createDialog: false,
            deleteUserId: false,
            editDialog: false,
            deleteBookSectionId: 0,
            searchValue: ''
        };
    }

    async componentDidMount(){
        if(this.props.SelectedId === 0){
            localStorage.clear();
            this.props.logOutUser();
            this.props.history.push('/login');
            return;
        }

        this._user = JSON.parse(localStorage.getItem('user'));
        if(this._user === null || this._user.Role !== 'Administrators'){
            localStorage.clear();
            this.props.logOutUser();
            this.props.history.push('/login');
            return;
        }

        this.props.setPageNumber(0);
        this.props.setSelectedSideBar(Text.Books);

        let {BookSections, RowCount} = await this._getAllBookSections('', '', 1);
        this.props.setBookSections(BookSections, RowCount);

        let bookSectionTypeRes = await getAllBookSectionTypes(this._user.Token);
        if(bookSectionTypeRes.status === 200){
            this.props.setBookSectionTypes(bookSectionTypeRes.data);
        } else {
            error(bookSectionTypeRes, 2000);
        }

        let courseTopicRes = await getAllCourseTopics(this._user.Token);
        if(courseTopicRes.status === 200){
            this.props.setCourseTopics(courseTopicRes.data);
        } else {
            error(courseTopicRes, 2000);
        }

        let subCourseTopicRes = await getAllSubCourseTopics(this._user.Token);
        if(subCourseTopicRes.status === 200){
            this.props.setSubCourseTopics(subCourseTopicRes.data);
        } else {
            error(subCourseTopicRes, 2000);
        }

        let sourceTypeRes = await getAllBookSectionSourceTypes(this._user.Token);
        if(sourceTypeRes.status === 200){
            this.props.setBookSectionSourceTypes(sourceTypeRes.data);
        } else {
            error(sourceTypeRes, 2000);
        }
    };

    async shouldComponentUpdate(nextProps, nextState) {
        if(this.props.BookSections !== nextProps.BookSections){
            return true;
        }
        if(this.props.PageNumber !== nextProps.PageNumber){
            let {BookSections, RowCount} = await this._getAllBookSections('', '', 1, nextProps.PageNumber);
            this.props.setBookSections(BookSections,RowCount);
            return true;
        }
        if(this.state !== nextState) {
            return true;
        }

        return false;
    };

    _handleClose = (name) => {
        this.setState({
            [name]: false
        });
    };

    _createBookSection = () => {
        this.setState({
            createDialog: true
        });
    };

    _deleteRow = (e) => {
        this.setState({
            deleteDialog: true,
            deleteBookSectionId: e.currentTarget.id
        });
    };

    _editRow = (e) => {
        let selectedRow = this.props.BookSections.filter((row) => 
            row.Id.toString() === e.currentTarget.id
        );
        this._editRowData = selectedRow;

        this.setState({
            editDialog: true
        });
    };

    _validation = (fields) => {
        for(var key in fields){
            let val = fields[key];
            if(val.indexOf('RowStatus') === -1 && document.getElementById(val).value === ''){
                let msg = Text[val];
                return `Lütfen '${msg}' kısmını doldurunuz!`;
            }
        }
        return '';
    };

    _addBookSection = async () => {
        let validationResult = this._validation(this._createFieldNames);
        if(validationResult !== ''){
            warning(validationResult, 2000);
        } else {
            let bookSectionTypeId = this.props.BookSectionTypes.filter(item => item.Name ===  document.getElementById('newBookSectionTypeName').value)[0].Id;
            let courseTopicId = this.props.CourseTopics.filter(item => item.Name ===  document.getElementById('newCourseTopicName').value)[0].Id;
            let subCourseTopicId = this.props.SubCourseTopics.filter(item => item.Name ===  document.getElementById('newSubCourseTopicName').value)[0].Id;
            let bookSectionSourceTypeId = this.props.BookSectionSourceTypes.filter(item => item.Name === document.getElementById('newBookSectionSourceTypeName').value)[0].Id
            let newBookSection = {
                BookId: this.props.SelectedId,
                Name: document.getElementById('newBookSectionName').value,
                BookSectionTypeId: bookSectionTypeId,
                CourseTopicId: courseTopicId,
                SubCourseTopicId: subCourseTopicId,
                BookSectionSourceTypeId: bookSectionSourceTypeId,
                StartPage: parseInt(document.getElementById('newStartPage').value),
                CropPerPage: parseInt(document.getElementById('newCropPerPage').value),
                AnswerPage: parseInt(document.getElementById('newAnswerPage').value),
                TotalCropCount: parseInt(document.getElementById('newTotalCropCount').value)
            };
            let res = await createBookSection(newBookSection,this._user.Token);
            if(res.status === 200){
                success(Text.SuccessCreateBookSection, 2000);

                this.setState({
                    createDialog: false
                });

                let {BookSections, RowCount} = await this._getAllBookSections('', '', 1);
                this.props.setBookSections(BookSections,RowCount);
            } else {
                error(res, 2000);
            }
        }
    };

    _deleteBookSection = async () => {
        let bookSection = {
            Id: this.state.deleteBookSectionId
        };
        let res = await deleteBookSection(bookSection,this._user.Token);
        if(res.status === 200){
            success(Text.DeletedBookSection, 2000);

            let {BookSections, RowCount} = await this._getAllBookSections('', '', 1);

            this.setState({
                deleteDialog: false
            });
            
            this.props.setBookSections(BookSections,RowCount);
        } else {
            error(res, 2000);
        }
    };

    _editBookSection = async () => {
        let validationResult = this._validation(this._editFieldNames);
        if(validationResult !== ''){
            warning(validationResult, 2000);
        } else {
            var bookSectionType = this.props.BookSectionTypes.filter(item => {
                return item.Name === document.getElementById('editBookSectionTypeName').value
            });
            var courseTopic = this.props.CourseTopics.filter(item => {
                return item.Name === document.getElementById('editCourseTopicName').value
            });
            var subCourseTopic = this.props.SubCourseTopics.filter(item => {
                return item.Name === document.getElementById('editSubCourseTopicName').value
            });
            var bookSectionSourceType = this.props.BookSectionSourceTypes.filter(item => {
                return item.Name === document.getElementById('editBookSectionSourceTypeName').value
            });
            let bookSection = {
                Id: this._editRowData[0].Id,
                Name: document.getElementById('editName').value,
                BookSectionTypeId: bookSectionType[0].Id,
                CourseTopicId:  courseTopic[0].Id,
                SubCourseTopicId:  subCourseTopic[0].Id,
                BookSectionSourceTypeId: bookSectionSourceType[0].Id,
                StartPage: document.getElementById('editStartPage').value,
                CropPerPage: document.getElementById('editCropPerPage').value,
                AnswerPage: document.getElementById('editAnswerPage').value,
                TotalCropCount: document.getElementById('editTotalCropCount').value,
                RowStatus: document.getElementById('editRowStatus').checked
            };
            let res = await updateBookSection(bookSection,this._user.Token);
            if(res.status === 200){
                success(Text.EditedBookSection, 2000);
    
                let {BookSections, RowCount} = await this._getAllBookSections('', '', 1);
    
                this.setState({
                    editDialog: false
                });
                
                this.props.setBookSections(BookSections,RowCount);
            } else {
                error(res, 2000);
            }
        }
    };

    _getAllBookSections = async (searchValue, orderColumn, orderBy, pageNumber = this.props.PageNumber) => {
        let paging = {
            BookId: this.props.SelectedId,
            PageSize: _pageSize,
            PageNumber: pageNumber,
            SearchValue: searchValue,
            OrderColumn: orderColumn,
            OrderBy: orderBy
        };

        let res = await getAllBookSections(paging, this._user.Token);
        if(res.status === 200){
            this._columns = res.data.BookSections.length > 0 ?
                Object.keys(res.data.BookSections[0])
                : []; 
            this._rows = this._getRows(res.data.BookSections) || [];
            return {BookSections: res.data.BookSections, RowCount: res.data.RowCount};
        } else {
            error(res, 2000);
        }
    };

    _getRows = (data) => {
        let cellComponentArray = [{
            Name: 'RowStatus', 
            FalseComponent: (<GridSwitchButton checked={false} text={Text.DeActive}/>),
            TrueComponent: (<GridSwitchButton checked={true} text={Text.Active}/>)
        }];
        return getRows(data, null, null, cellComponentArray);
    };

    _excelImport = async(e) => {
        let rows = await excelImport(e.target.files[0], this._csvHeader);
        let bookSections = [];
        for(let i=1; i<rows.length; i++){
            var row = rows[i].split('\t');
            
            if(row[row.length - 1] === ""){
                row.pop();
            }

            let bookSection = {
                BookId: parseInt(this.props.SelectedId),
                Name: row[3],
                BookSectionTypeId: parseInt(row[2]),
                CourseTopicName: row[0],
                SubCourseTopicName: row[1],
                StartPage: parseInt(row[4]),
                CropPerPage: parseInt(row[7]),
                AnswerPage: parseInt(row[6]),
                TotalCropCount: parseInt(row[5]),
                BookSectionSourceTypeId: parseInt(row[8])
            };
            bookSections.push(bookSection);
        }
        var filteredBookSections = bookSections.filter(item => item.Name !== '');
        let res = await bulkInsertBookSections(filteredBookSections,this._user.Token);
        if(res.status === 200){
            success(Text.ExcelBookSectionDataUploadSuccess, 2000);

            let {BookSections, RowCount} = await this._getAllBookSections('', '', 1);
            this.props.setBookSections(BookSections,RowCount);
        } else {
            error(res, 2000);
        }
    };

    _onRowClick = (e) => {
        if(e.target.localName === 'td' && this._user.Role !== 'Administrators'){
            var bookSection = this.props.BookSections.filter(item => item.Id.toString() === e.currentTarget.id)[0];
            this.props.Links.push({
                Text: bookSection.Name,
                href: '/booksectioncrop'
            })
            this.props.addLink(this.props.Links);

            this.props.setSelectedBookSectionId(e.currentTarget.id);
            this.props.history.push('/booksectioncrop');   
        }
    };

    _handleSearchChange = async (e) => {
        this.setState({
            searchValue: e.target.value
        });

        if(e.target.value.length >= 3 || e.target.value.length === 0) {
            let {BookSections, RowCount} = await this._getAllBookSections(e.target.value, '', 1);
            this.props.setBookSections(BookSections,RowCount);
        }
    };

    _headerClick = async (e) => {
        if(e.target.innerHTML !== ''){
            let column = e.target.innerHTML;

            if(column === 'BookSectionTypeName' ||column === 'CourseTopicName' || column === 'SubCourseTopicName'
                || column === 'BookSectionSourceTypeName' || column === 'Editor' || column === 'Approver'){
                    warning(Text.DoesNotOrderColumn, 2000);
                    return;
                }

            let orderBy = this._orderColumn !== column ? this._orderByAsc : !this._orderByAsc;
            this._orderByAsc = orderBy;
            this._orderColumn = column;

            let {BookSections, RowCount} = await this._getAllBookSections(this.state.searchValue, column, this._orderByAsc === true ? 1 : 2);
            this.props.setBookSections(BookSections,RowCount);
        }
    };

    render(){
        return(
            <div>
                <SideBar/>

                <div style={{...styles.container}}>
                    <PageHeader
                        title={Text.BookSections}
                    />
                    <Grid 
                        stickyColumnCount={1} 
                        data={{
                            columns: this._columns,
                            rows: this._rows
                        }} 
                        toolbar={{
                            create: true,
                            createClick: this._createBookSection,
                            excelImport: true,
                            excelImportClick: this._excelImport
                        }}
                        paging={{
                            rowsPerPage: _pageSize,
                            count: this.props.RowCount
                        }}
                        editRow={this._editRow}
                        deleteRow={this._deleteRow}
                        rowClick={this._onRowClick}
                        searchOnChange={this._handleSearchChange}
                        searchValue={this.state.searchValue}
                        headerClick={this._headerClick}
                    />
                </div>

                <DialogPanel
                    id='createDialog'
                    isOpen={this.state.createDialog}
                    onClose={() => this._handleClose('createDialog')}
                    title={Text.CreateBookSection}
                    actions={[
                        { onClick: () => this._handleClose('createDialog'), text: Text.Cancel }, 
                        { onClick: this._addBookSection, text: Text.Add }
                    ]}
                    contentComponentNames={this._createFieldNames}
                />

                <DialogPanel
                    id='deleteDialog'
                    isOpen={this.state.deleteDialog}
                    onClose={() => this._handleClose('deleteDialog')}
                    title={Text.DeleteBookSectionTitle}
                    contentText={Text.DeleteBookSectionQuestion}
                    actions={[
                        { onClick: () => this._handleClose('deleteDialog'), text: Text.Cancel }, 
                        { onClick: this._deleteBookSection, text: Text.Delete }
                    ]}
                />

                <DialogPanel
                    id='editDialog'
                    isOpen={this.state.editDialog}
                    onClose={() => this._handleClose('editDialog')}
                    title={Text.EditBookSection}
                    actions={[
                        { onClick: () => this._handleClose('editDialog'), text: Text.Cancel }, 
                        { onClick: this._editBookSection, text: Text.Edit }
                    ]}
                    contentComponentNames={this._editFieldNames}
                    editRow={this._editRowData}
                />

                <Notification/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    User: state.userReducer.User,
    BookSections: state.bookSectionReducer.BookSections,
    RowCount: state.bookSectionReducer.RowCount,
    PageNumber: state.gridReducer.PageNumber,
    BookSectionTypes: state.bookSectionTypeReducer.BookSectionTypes,
    CourseTopics: state.courseTopicReducer.CourseTopics,
    SubCourseTopics: state.subCourseTopicReducer.SubCourseTopics,
    SelectedId: state.bookReducer.SelectedId,
    Links: state.pageHeaderLinkReducer.Links,
    BookSectionSourceTypes: state.bookSectionSourceTypeReducer.BookSectionSourceTypes
});

const mapDispatchToProps = (dispatch) => ({
    setBookSections: (bookSections, rowCount) => dispatch(setAllBookSections(bookSections, rowCount)),
    setBookSectionTypes: (bookSectionTypes) => dispatch(setAllBookSectionTypes(bookSectionTypes)),
    setCourseTopics: (courseTopics) => dispatch(setAllCourseTopics(courseTopics)),
    setSubCourseTopics: (subCourseTopics) => dispatch(setAllSubCourseTopics(subCourseTopics)),
    setSelectedSideBar: (item) => dispatch(setSelectedItem(item)),
    logOutUser: () => dispatch(logoutUser()),
    setSelectedBookSectionId: (selectedId) => dispatch(setSelectedBookSectionId(selectedId)),
    setLinks: (links) => dispatch(setLinks(links)),
    addLink: (links) => dispatch(addLink(links)),
    setBookSectionSourceTypes: (sourceTypes) => dispatch(setBookSectionSourceTypes(sourceTypes)),
    setPageNumber: (pageNumber) => dispatch(setPageNumber(pageNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BookSectionsPage));
export const Primary = {
    TopBar: '#003ce5',
    IconColor: 'rgb(0,0,0)',
    TextColor: 'rgb(0,98,129)',
    Background: 'rgb(238,238,238)',
    ContainerBackground: 'rgb(255,255,255)',
    SideBar: 'rgb(37,37,37)',
    ProfileTemplateBackground: 'rgb(29,29,29)',
    SideBarText: 'rgb(161,161,161)',
    LinkText: 'rgb(128,128,128)',

    PanelBoxShadow: '0 2px 5px 0 rgba(0,0,0,.26)'
}
import { combineReducers } from 'redux';
import { userReducer } from './userReducer';
import { gridReducer } from './gridReducer';
import { allUserReducer } from './allUserReducer';
import { roleReducer } from './roleReducer';
import { pageHeaderLinkReducer } from './pageHeaderLinkReducer';
import { bookReducer } from './bookReducer';
import { courseReducer } from './courseReducer';
import { publisherReducer } from './publisherReducer';
import { bookSectionReducer } from './bookSectionReducer';
import { bookSectionTypeReducer } from './bookSectionTypeReducer';
import { courseTopicReducer } from './courseTopicReducer';
import { subCourseTopicReducer } from './subCourseTopicReducer';
import { sidebarReducer } from './sidebarReducer';
import { taskReducer } from './taskReducer';
import { cropReducer } from './cropReducer';
import { answerReducer } from './answerReducer';
import { bookSectionSourceTypeReducer } from './bookSectionSourceTypeReducer';
import { loadingReducer } from './loadingReducer';

const allReducers = {
    userReducer,
    gridReducer,
    allUserReducer,
    roleReducer,
    pageHeaderLinkReducer,
    bookReducer,
    courseReducer,
    publisherReducer,
    bookSectionReducer,
    bookSectionTypeReducer,
    courseTopicReducer,
    subCourseTopicReducer,
    sidebarReducer,
    taskReducer,
    cropReducer,
    answerReducer,
    bookSectionSourceTypeReducer,
    loadingReducer
};

export const rootReducer = combineReducers(allReducers);
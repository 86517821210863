export const UserActions = {
    Login: 'USER_LOGIN',
    Logout: 'USER_LOGOUT',
    Update: 'USER_UPDATE',
    SetAll: 'SET_ALL_USERS'
};

export const GridActions = {
    SetPageNumber: 'SET_PAGE_NUMBER'
};

export const RoleActions = {
    SetAll: 'SET_ALL_ROLES'
};

export const PageHeaderActions = {
    SetLinks: 'SET_LINKS',
    Add: 'ADD_LINK'
};

export const BookActions = {
    SetAll: 'SET_ALL_BOOKS',
    SetSelectedId: 'SET_SELECTED_BOOK_ID'
};

export const PublisherActions = {
    SetAll: 'SET_ALL_PUBLISHERS'
};

export const CourseActions = {
    SetAll: 'SET_ALL_COURSES'
};

export const BookSectionActions = {
    SetAll: 'SET_ALL_BOOK_SECTIONS',
    SetSelectedBookSectionId: 'SET_SELECTED_BOOK_SECTION_ID'
};

export const BookSectionTypeActions = {
    SetAll: 'SET_ALL_BOOK_SECTION_TYPES'
};

export const CourseTopicActions = {
    SetAll: 'SET_ALL_COURSE_TOPICS'
};

export const SubCourseTopicActions = {
    SetAll: 'SET_ALL_SUB_COURSE_TOPICS'
};

export const SideBarActions = {
    SetSelectedItem: 'SET_SELECTED_ITEM'
};

export const TaskActions = {
    SetAll: 'SET_TASKS'
};

export const CropActions = {
    SetAllOptions: 'SET_CROP_IMAGE',
    AddCroppedList: 'ADD_CROPPED_IMAGE_LIST',
    RemoveCroppedList: 'REMOVE_CROPPED_IMAGE_FROM_LIST',
    ClearCroppedList: 'CLEAR_CROPPED_LIST',
    SetMultiCropChecked: 'SET_MULTI_CROP_CHECKED',
    SetCroppedList: 'SET_CROPPED_LIST',
    SetFirstLoad:'SET_FIRST_LOAD',
};

export const AnswerActions = {
    SetAnswer: 'SET_ANSWER'
};

export const BookSectionSourceTypeActions = {
    SetAll: 'SET_BOOK_SECTION_SOURCE_TYPES'
};

export const LoadingActions = {
    SetActivity: 'SET_ACTIVITY'
};
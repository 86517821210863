import { PageHeaderActions } from './../ActionTypes';

const initialState = {
    Links: []
};

export const pageHeaderLinkReducer = (state = initialState, action) => {
    switch (action.type) {
        case PageHeaderActions.SetLinks:
            return {
                ...state,
                Links: action.links
            }
        case PageHeaderActions.Add:
            return Object.assign({}, state, { 
                Links: action.links,
            });
        default:
            return state;
    }
};
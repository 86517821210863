import { BookSectionSourceTypeActions } from './../ActionTypes';

const initialState = {
    BookSectionSourceTypes: []
};

export const bookSectionSourceTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case BookSectionSourceTypeActions.SetAll:
            return Object.assign({}, state, { 
                BookSectionSourceTypes: action.bookSectionSourceTypes,
            });
        default:
            return state;
    }
};
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

//#region Internal
import SideBar from "../components/SideBar";
import PageHeader from "../components/Base/PageHeader";
import Grid from "../components/Grid/index";
import DialogPanel from "../components/Base/DialogPanel";
import {
  Notification,
  success,
  error,
  warning,
} from "../components/Notification/index";
import getRows from "./../util/getRows";

import { Components } from "../assets/Dimensions";
import { Text } from "../assets/Strings";

import {
  getAllByRole,
  setAssignee,
  sendBackAssignee,
} from "../api/BookSection";

import { setSelectedBookSectionId } from "../redux/actions/bookSectionAction";
import { setSelectedItem } from "../redux/actions/sidebarAction";
import { setTasks } from "../redux/actions/taskAction";
import { setLinks, addLink } from "./../redux/actions/pageHeaderLinkAction";
import { setPageNumber } from "./../redux/actions/gridAction";
import { logoutUser } from "./../redux/actions/userAction.js";
//#endregion

//#region Variables
const _pageSize = 8;
//#endregion

//#region Inline Style
const styles = {
  container: {
    marginLeft: Components.SideBarWidth + 15,
    paddingTop: 20,
    marginRight: 30,
  },
};
//#endregion

class WorkListPage extends Component {
  constructor(props) {
    super(props);
    this._editFieldNames = ["editAssigneeUser"];
    this._editRowData = [];
    this._sendBackRow = [];
    this._rows = [];
    this._columns = [];
    this._user = {};

    this.state = {
      editDialog: false,
      sendBackDialog: false,
      searchValue: "",
    };
  }

  componentWillMount() {
    this.props.setLinks([
      {
        Text: Text.WorkList,
        href: "/worklist",
      },
    ]);
  }

  async componentDidMount() {
    sessionStorage.removeItem("fromFeedBack")
    localStorage.removeItem("bookSectionId")
    this._user = JSON.parse(localStorage.getItem("user"));
    if (this._user === null) {
      localStorage.clear();
      this.props.logOutUser();
      this.props.history.push("/login");
      return;
    }

    if (
      this.props.location.state !== undefined &&
      this.props.location.state.isFinished === true
    ) {
      success(Text.FinishedSection, 2000);
    }

    this.props.setPageNumber(0);
    this.props.setSelectedSideBar(Text.WorkList);

    let { Tasks, RowCount } = await this._getAllTasks();
    this.props.setTasks(Tasks, RowCount);
  }

  async shouldComponentUpdate(nextProps, nextState) {
    if (this.props.Tasks !== nextProps.Tasks) {
      return true;
    }
    if (this.props.PageNumber !== nextProps.PageNumber) {
      let { Tasks, RowCount } = await this._getAllTasks(nextProps.PageNumber);
      this.props.setTasks(Tasks, RowCount);
      return true;
    }
    if (nextState.searchValue !== this.state.searchValue) {
      let { Tasks, RowCount } = await this._getAllTasks(
        this.props.pageNumber,
        nextState.searchValue
      );

      this.props.setTasks(Tasks, RowCount);
    }
    if (this.state !== nextState) {
      return true;
    }

    return false;
  }

  _handleClose = (name) => {
    this.setState({
      [name]: false,
    });
  };

  _getAllTasks = async (pageNumber = this.props.PageNumber, s) => {
    let paging = {
      UserId: this._user.Id,
      PageSize: _pageSize,
      PageNumber: pageNumber,
      SearchValue: s,
    };

    let res = await getAllByRole(paging, this._user.Token);
    if (res.status === 200) {
      this._columns =
        res.data.Tasks.length > 0 ? Object.keys(res.data.Tasks[0]) : [];
      this._rows = this._getRows(res.data.Tasks) || [];
      return { Tasks: res.data.Tasks, RowCount: res.data.RowCount };
    } else {
      error(res, 2000);
    }
  };

  _editRow = (e) => {
    if (this._user.Role !== "Administrators") {
      let selectedRow = this.props.Tasks.filter(
        (row) => row.Id.toString() === e.currentTarget.id
      );
      this._editRowData = selectedRow;

      this.setState({
        editDialog: true,
      });
    } else {
      warning(Text.WarningAdminRoleInWorkList, 2000);
    }
  };

  _editTask = async () => {
    if (this._editRowData[0].AssigneeUser === null) {
      let task = {
        BookSectionId: this._editRowData[0].Id,
        UserId: this._user.Id,
        AssigneeId:
          document.getElementById("editAssigneeUser").value === ""
            ? 0
            : this._user.Id,
      };
      let res = await setAssignee(task, this._user.Token);
      if (res.status === 200) {
        success(Text.EditedWorkAssignee, 2000);

        let { Tasks, RowCount } = await this._getAllTasks();

        this.setState({
          editDialog: false,
        });

        this.props.setTasks(Tasks, RowCount);
      } else {
        error(res, 2000);
      }
    } else {
      warning(Text.TaskAssigneed, 2000);
    }
  };

  _getRows = (data) => {
    return getRows(data, null, null, null);
  };

  _onRowClick = (e) => {
    if (
      e.target.localName === "td" &&
      (e.currentTarget.cells[8].innerText === this._user.Name ||
        this._user.Role === "Administrators")
    ) {
      var task = this.props.Tasks.filter(
        (item) => item.Id.toString() === e.currentTarget.id
      )[0];
      this.props.Links.push({
        Text: task.BookSectionName,
        href: "/booksectioncrop",
      });
      this.props.addLink(this.props.Links);
      localStorage.setItem("bookSectionId",e.currentTarget.id)
      this.props.setSelectedBookSectionId(e.currentTarget.id);
      this.props.history.push("/booksectioncrop");
    } else if (
      e.target.localName === "td" &&
      e.currentTarget.cells[8].innerText !== this._user.Name
    ) {
      warning(Text.WarningRoleInWorkList, 2000);
    }
  };

  _sendBack = async (e) => {
    if (this._sendBackRow[0].TaskStatus === 5) {
      let req = {
        BookSectionId: this._sendBackRow[0].Id,
      };
      let res = await sendBackAssignee(req, this._user.Token);
      if (res.status === 200) {
        success(Text.SendBackAssignee);

        let { Tasks, RowCount } = await this._getAllTasks();
        this.props.setTasks(Tasks, RowCount);

        this.setState({
          sendBackDialog: false,
        });
      } else {
        error(res, 2000);
      }
    } else {
      warning(Text.SendBackWarning, 2000);
    }
  };

  _sendBackOpen = (e) => {
    let selectedRow = this.props.Tasks.filter(
      (row) => row.Id.toString() === e.currentTarget.id
    );
    this._sendBackRow = selectedRow;
    this.setState({
      sendBackDialog: true,
    });
  };

  render() {
    return (
      <div>
        r
        <SideBar />
        <div style={{ ...styles.container }}>
          <PageHeader title={Text.WorkList} />
          <Grid
            stickyColumnCount={0}
            editRow={this._editRow}
            data={{
              columns: this._columns,
              rows: this._rows,
            }}
            paging={{
              rowsPerPage: _pageSize,
              count: this.props.RowCount,
            }}
            searchOnChange={(s) => {
              this.setState({ searchValue: s.target.value });
            }}
            rowClick={this._onRowClick}
            sendBack={
              this._user.Role === "Administrators" && this._sendBackOpen
            }
          />
        </div>
        <DialogPanel
          id="editDialog"
          isOpen={this.state.editDialog}
          onClose={() => this._handleClose("editDialog")}
          title={Text.EditWorkList}
          actions={[
            {
              onClick: () => this._handleClose("editDialog"),
              text: Text.Cancel,
            },
            { onClick: this._editTask, text: Text.Edit },
          ]}
          contentComponentNames={this._editFieldNames}
          editRow={this._editRowData}
        />
        <DialogPanel
          id="sendBackDialog"
          isOpen={this.state.sendBackDialog}
          onClose={() => this._handleClose("sendBackDialog")}
          title={Text.SendBackTitle}
          contentText={Text.SendBackQuestion}
          actions={[
            {
              onClick: () => this._handleClose("sendBackDialog"),
              text: Text.Cancel,
            },
            { onClick: this._sendBack, text: Text.Send },
          ]}
        />
        <Notification />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.userReducer.User,
  Tasks: state.taskReducer.Tasks,
  RowCount: state.taskReducer.RowCount,
  PageNumber: state.gridReducer.PageNumber,
  BookSections: state.bookSectionReducer.BookSections,
  Links: state.pageHeaderLinkReducer.Links,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedSideBar: (item) => dispatch(setSelectedItem(item)),
  setTasks: (tasks, rowCount) => dispatch(setTasks(tasks, rowCount)),
  setLinks: (links) => dispatch(setLinks(links)),
  setSelectedBookSectionId: (selectedId) =>
    dispatch(setSelectedBookSectionId(selectedId)),
  addLink: (links) => dispatch(addLink(links)),
  setPageNumber: (pageNumber) => dispatch(setPageNumber(pageNumber)),
  logOutUser: () => dispatch(logoutUser()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(WorkListPage));

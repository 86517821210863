const getRows = (data, rowComponent, rowComment, cellComponentArray) => {
    let rows = [];
    for (let i = 0; i < data.length; i++) { 
        let row ={};
        row.component = rowComponent;
        row.comment = rowComment;
        row.data = {
            id: data[i].Id,
            content: [] 
        };
        
        let {Id, ...arr} = data[i];
        let arrVal = Object.values(data[i]);

        for(let k = 0; k < arrVal.length; k++ ){
            let cell = {
                content: arrVal[k],
                component: null
            };

            if(cellComponentArray !== null){
                for(let i=0; i < cellComponentArray.length; i++){
                    if(Object.keys(arr)[k] === cellComponentArray[i].Name){
                        if(arrVal[k] === false){
                            cell.component = cellComponentArray[i].FalseComponent;
                        } else {
                            cell.component = cellComponentArray[i].TrueComponent;
                        }
                    }   
                }
            }

            row.data.content.push(cell);
        }
        
        rows.push(row);
    }
    return rows;
};

export default getRows;
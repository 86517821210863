import { PublisherActions } from './../ActionTypes';

const initialState = {
    Publishers: []
};

export const publisherReducer = (state = initialState, action) => {
    switch (action.type) {
        case PublisherActions.SetAll:
            return Object.assign({}, state, { 
                Publishers: action.publishers,
            });
        default:
            return state;
    }
};
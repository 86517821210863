export const Text = {
    Login: 'Giriş Yap',
    SignUp: 'Kayıt Ol',
    NoAccount: 'Hesabın yok mu?',
    HaveAccount: 'Hesabı zaten var mı?',
    UserProfile: 'Kullanıcı Profili',
    Logout: 'Çıkış Yap',
    Books: 'Kitaplar',
    Members: 'Üyeler',
    BookSections: 'Kitap Bölümleri',
    PersonalInformation: 'Kişi Bilgileri',
    NameAndSurname: 'Adı Soyadı',
    Email: 'Email',
    EmailOrUserName: 'Kullanıcı Adı veya Email',
    PhoneNumber: 'Telefon Numarası',
    Password: 'Şifre',
    QuestionInformation: 'Soru Bilgisi',
    TotalEnteredQuestion: 'Toplam Girilen Soru',
    TotalApprovedQuestion: 'Toplam Onaylanan Soru',
    MoneyInformation: 'Alacak Bilgileri',
    TotalServiceFee: 'Toplam Hizmet Bedeli',
    PaidServiceFee: 'Ödenen Hizmet Bedeli',
    ServiceFee: 'Ödenecek Hizmet Bedeli',
    PaymentInformation: 'Ödeme Bilgileri',
    NameAndSurnameWithCorporateName: 'Adı Soyadı (Şirket Ünvanı)',
    Iban: 'IBAN',
    Save: 'Kaydet',
    UserName: 'Kullanıcı Adı',
    Name: 'Ad',
    Surname: 'Soyad',
    RegisterSuccess: 'Kullanıcı kaydı gerçekleştirilmiştir.',
    SuccessUpdateProfile: 'Bilgileriniz güncellenmiştir.',
    ToolbarCreate: 'Yeni',
    ToolbarSave: 'Kaydet',
    ToolbarExcelImport: 'Excel Yükle',
    Waiting: 'Beklemede',
    Confirmed: 'Doğrulandı',
    Active: 'Aktif',
    DeActive: 'Deaktif',
    CreateMember: 'Yeni Kullanıcı Oluştur',
    Cancel: 'Vazgeç',
    Add: 'Ekle',
    SuccessCreateUser: 'Kullanıcı başarıyla oluşturuldu',
    DeleteMemberQuestion: 'Kullanıcıyı silmek istediğinize emin misiniz?',
    DeleteMemberTitle: 'Kullanıcıyı Sil',
    Delete: 'Sil',
    DeletedMember: 'Kullanıcı kaydı pasife çekildi.',
    EditMemberTitle: 'Kullanıcı Bilgilerini Güncelle',
    Edit: 'Güncelle',
    EditedMember: 'Kullanıcının bilgileri güncellendi.',
    CreateBook: 'Kitap Oluştur',
    DeleteBookQuestion: 'Kitabı silmek istediğinize emin misiniz?',
    DeleteBookTitle: 'Kitabı Sil',
    EditBookTitle: 'Kitap Bilgilerini Güncelle',
    SuccessCreateBook: 'Kitap başarıyla oluşturuldu',
    DeletedBook: 'Kitap kaydı pasife çekildi',
    EditedBook: 'Kitap bilgileri güncellendi',
    ExcelBookDataUploadSuccess: 'Kitaplar başarıyla eklendi',
    CreateBookSection: 'Kitap Bölümü Oluştur',
    DeleteBookSectionQuestion: 'Kitap bölümünü silmek istediğinize emin misiniz?',
    DeleteBookSectionTitle: 'Kitap Bölümünü Sil',
    EditBookSection: 'Kitap Bölümü Bilgilerini Güncelle',
    SuccessCreateBookSection: 'Kitap bölümü başarıyla oluşturuldu',
    DeletedBookSection: 'Kitap bölümü pasife çekildi',
    EditedBookSection: 'Kitap bölümü güncellendi',
    ExcelBookSectionDataUploadSuccess: 'Kitap bölümleri başarıyla eklendi',
    PreviousQuestion: 'Önceki Soru',
    NextQuestion: 'Sonraki Soru',
    WorkList: 'İş Listesi',
    EditWorkList: 'İşi güncelle',
    EditedWorkAssignee: 'İş sahibi güncellendi',
    UpdatedReport: 'İş sahibi güncellendi',
    MarkedAsDone: 'Rapor Status Güncellendi',
    UpdatedAnswer: 'Cevap Günellendi',
    EmptyAnswer: 'Lütfen bir cevap şıkkı seçiniz.',
    EmptyCroppedImage: 'Lütfen crop işlemini yapınız.',
    SuccessCropped: 'Crop işlemi başarıyla gerçekleştirildi',
    FinishedSection: 'Kitap bölümü crop işlemi tamamlandı',
    CroppedImageButton: 'Croplu resmi göster',
    CroppedDialog: 'Croplanan Görüntü',
    InFirstPage: 'Zaten ilk sayfadasınız',
    InLastPage: 'Zaten son sayfadasınız',
    CropUpdateQuestion: 'Croplanan görüntüyü güncellemek ister misiniz?',
    CropUpdateQuestionTitle: 'Crop Güncelleme',
    Update: 'Güncelle',
    InFirstQuestion: 'Zaten ilk sorudasınız.',
    UpdateCropped: 'Crop işlemi güncellendi',
    SoEasy: 'Çok Kolay',
    Easy: 'Kolay',
    MidLevel: 'Orta Seviye',
    Hard: 'Zor',
    SoHard: 'Çok Zor',
    FinishSection: 'Bitir',
    EmptyAnswerCroppedImage: 'Lütfen cevap kısmından da crop işlemi yapınız',
    FromS3Image: 'Kayıtlı Görüntüyü Göster',
    WarningRoleInWorkList: 'Önce taskı kendi üzerinize alın',
    WarningAdminRoleInWorkList: 'Admin kullanıcısı olarak taskı üzerinize alamazsınız',
    MultiCrop: 'Multi Crop',
    EmptyCroppedList: 'Multi Crop listesi boş',
    TaskAssigneed: 'Atanmış taskın kullanıcısını değiştiremezsiniz',
    SendBackAssignee: 'Task geri gönderildi',
    Send: 'Gönder',
    SendBackTitle: 'Task Geri Gönderme',
    SendBackQuestion: 'İlgili task onaycıya geri gönderilsin mi?',
    SendBackWarning: 'Onaylanmamış taskı yollayamazsınız.',
    DoesNotOrderColumn: 'Bu kolondan sıralama yapılamaz',
    DoneTaskNotAssigneed: 'Done statüsündeki taska giremezsiniz.',
    FinishTaskTitle: 'Section Sonu',
    FinishTaskQuestion: 'Sonlandırmak istediğiniz emin misiniz?',
    FinishTask: 'Sonlandır',
    Tckn: 'TCKN',

    editName: 'Ad',
    editSurname: 'Soyad',
    editPhoneNumber:'Telefon Numarası',
    editEmail:'Mail Adresi',
    editIban:'IBAN',
    editRole:'Kullanıcı Rolü',
    editAccountStatus: 'Kullanıcı Aktiflik',
    newRole: 'Kullanıcı Rolü',
    newUserName: 'Kullanıcı Adı',
    newName: 'Ad',
    newSurname: 'Soyad',
    newPhoneNumber: 'Telefon Numarası',
    newEmail: 'Mail Adresi',
    newIban: 'IBAN',
    newPassword: 'Şifre',
    newBookName: 'Kitap Adı',
    newBookImageExtension: 'Dosya Uzantısı',
    newPrintPublishYear: 'Yayın Tarihi',
    newBookPublisher: 'Yayıncı',
    newBookCourse: 'Ders',
    editFileExtension: 'Dosya uzantısı',
    editPrintPublishYear: 'Yayın tarihi',
    editPublisherName: 'Yayıncı',
    editCourseName: 'Ders',
    newBookSectionName: 'Kitap Bölümü Adı',
    newBookSectionTypeName: 'Kitap Bölümü Türü',
    newCourseTopicName: 'Konu Türü',
    newSubCourseTopicName: 'Alt Konu Türü',
    newStartPage: 'Başlangıç Sayfası',
    newCropPerPage: 'Sayfa Bazında Crop Sayısı',
    newAnswerPage: 'Cevap Anahtarı Sayfası',
    newTotalCropCount: 'Toplam Crop Sayısı',
    newRowStatus: 'Aktiflik',
    editBookSectionName: 'Kitap Bölümü Adı',
    editBookSectionTypeName: 'Kitap Bölümü Türü',
    editCourseTopicName: 'Konu Türü',
    editSubCourseTopicName: 'Alt Konu Türü',
    editStartPage: 'Başlangıç Sayfası',
    editCropPerPage: 'Sayfa BAzında Crop Sayısı',
    editAnswerPage: 'Cevap Anahtarı Sayfası',
    editTotalCropCount: 'Toplam Crop Sayısı',
    editRowStatus: 'Aktiflik',
    editAssigneeUser: 'Kullanıcı Adı',
    newBookSectionSourceTypeName: 'Kaynak Tipi',
    editBookSectionSourceName: 'Kaynak Tipi',
    newPageCount: 'Sayfa Sayısı',
    editPageCount: 'Sayfa Sayısı',
    newPublisherBookId: 'Kitap Index',
    editPublisherBookId: 'Kitap Index',
    newOriginalFileName: 'Dosya Adı',
    editOriginalFileName: 'Dosya Adı',
    editStatus: 'Aktiflik'
}
import DifficultyLevelColors from "../enums/DifficultyLevelColors";

export const RatioBasedColor = (ratio) => {
    if (ratio <= 20) {
      return DifficultyLevelColors.VeryHard;
    } else if (ratio <= 40) {
      return DifficultyLevelColors.Hard;
    } else if (ratio <= 60) {
      return DifficultyLevelColors.Medium;
    } else if (ratio <= 80) {
      return DifficultyLevelColors.Easy;
    } else {
      return DifficultyLevelColors.VeryEasy;
    }
  };

import { AnswerActions } from './../ActionTypes';

const initialState = {
    Answer: null
};

export const answerReducer = (state = initialState, action) => {
    switch (action.type) {
        case AnswerActions.SetAnswer:
            return Object.assign({}, state, { 
                Answer: action.answer,
            });
        default:
            return state;
    }
};
const excelImport = (file, headers) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            let csvData = reader.result;
            let data = csvData.split(/\r?\n|\r/);
            var columns = data[0].split('\t');
            
            if(columns[columns.length - 1] === ""){
                columns.pop();    
            }

            for (const [index, item] of columns.entries()) {
                if(item !== headers[index]){
                    return null;
                }
            }

            data.shift();
            resolve(data);
        };

        reader.readAsText(file);
      })
};

export default excelImport;
import React, { Component } from 'react';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/styles';
import MuiTypography from '@material-ui/core/Typography';
//#endregion

//#region Internal
import { Components, FontSize } from '../../assets/Dimensions';
//#endregion

//#region Override Style
const Typography = withStyles({
    root: {
        marginLeft: 20
    },
    body1: {
        fontSize: FontSize.Small
    }
})(MuiTypography);
//#endregion

//#region Inline Style
const styles = {
    img: {
        width: Components.Image.Mid.Width,
        height: Components.Image.Mid.Height,
        marginTop: 3,
        float: 'left'
    }
}
//#endregion

class GridStatusBar extends Component {
    constructor(props){
        super(props);

        this.state = {};
    }

    render(){
        return(
            <div>
                <img
                    src={this.props.img}
                    alt='statusIcon'
                    style={{...styles.img}}
                />
                <Typography>{this.props.text}</Typography>
            </div>
        );
    }
}

GridStatusBar.propTypes = {
    img: PropTypes.node.isRequired
};

GridStatusBar.defaultProps = {
};

export default GridStatusBar;
import { BookActions } from './../ActionTypes';

export const setAllBooks = (books, rowCount) => ({
    type: BookActions.SetAll,
    books,
    rowCount
});

export const setSelectedBookId = (selectedId) => ({
    type: BookActions.SetSelectedId,
    selectedId
});
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/core';
import MuiIconButton from '@material-ui/core/IconButton';
import MuiTypography from '@material-ui/core/Typography';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid';

import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
//#endregion

//#region Internal
import { Components, FontSize } from './../../assets/Dimensions';
import { Primary } from './../../assets/Colors';
import { Text } from './../../assets/Strings' ;
//#endregion

//#region Override Style
const Checkbox = withStyles({
    root: {
        padding: 4
    }
})(MuiCheckbox);

const FormControlLabel = withStyles({
    label: {
        fontSize: FontSize.Mid
    }
})(MuiFormControlLabel);

const IconButton = withStyles({
    root: {
        width: Components.FabButton.Small.Width,
        height: Components.FabButton.Small.Height,
        color: Primary.ContainerBackground,
        marginLeft: 10,
        borderRadius: 15,
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },
    label: {
        marginTop: -5
    }
})(MuiIconButton);

const Typography = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        borderRadius: 15,
        color: 'rgb(255,255,255)',
        marginLeft: 10,
        padding: '8px 10px 8px 10px',
        fontSize: FontSize.Mid
    }
})(MuiTypography);
//#endregion

//#region Inline Style
const styles = {
    icon: {
        width: Components.Image.Mid.Width,
        height: Components.Image.Mid.Height
    }
}
//#endregion

class Footer extends Component{
    constructor(props){
        super(props);

        this.state={
        };
    }

    render(){
        return(
            <div style={{
                marginLeft: this.props.isMultiCrop ? 100 : 190
            }}>
                <Grid container>
                    {this.props.isMultiCrop && 
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={'multiCrop'}
                                        checked={this.props.MultiCropChecked}
                                        onChange={this.props.onChange}
                                    />
                                }
                                label={Text.MultiCrop}
                            />
                        </Grid>
                    }
                    <Grid item>
                        <IconButton 
                            aria-label='FirstPage'
                            onClick={this.props.firstPage}
                        >
                            <FirstPageIcon style={{...styles.icon}}/>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton 
                            aria-label='PreviousPage'
                            onClick={this.props.previousPage}
                        >
                            <KeyboardArrowLeft style={{...styles.icon}}/>
                        </IconButton>
                    </Grid>
                    <Grid item style={{marginTop: 5}}>
                        <Typography component='span'>
                            {this.props.pageNumber}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton 
                            aria-label='NextPage'
                            onClick={this.props.nextPage}
                        >
                            <KeyboardArrowRight style={{...styles.icon}}/>
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton 
                            aria-label='LastPage'
                            onClick={this.props.lastPage}
                        >
                            <LastPageIcon style={{...styles.icon}}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

Footer.propTypes = {
    firstPage: PropTypes.func.isRequired,
    lastPage: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    pageNumber: PropTypes.number.isRequired,
    isMultiCrop: PropTypes.bool,
    onChange: PropTypes.func
};

Footer.defaultProps = {
    isMultiCrop: false
};

const mapStateToProps = (state) => ({
    MultiCropChecked: state.cropReducer.MultiCropChecked
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
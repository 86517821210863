import Axios from "axios";

const header = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
};

export const post = async (url, parameters, token, isAdmin) => {
  let res;

  if (token !== null) {
    header.Authorization = "Bearer " + token;
  }
  if (isAdmin) header.Authorization ="Bearer " + token;
  await Axios.post(url, parameters, {
    headers: header,
  })
    .then((response) => {
      res = response;
    })
    .catch((err) => {
      res = err;
    });
  return res;
};
export const postAdmin = async (url, parameters, token, ) => {
  let res;

  if (token !== null) {
    header.Authorization = "Administration L=vc2H2hgAm_mdEZ";
  }
  
  await Axios.post(url, parameters, {
    headers: header,
  })
    .then((response) => {
      res = response;
    })
    .catch((err) => {
      res = err;
    });
  return res;
};


export const put = async (url, parameters, token) => {
  let res;

  if (token !== null) {
    header.Authorization = "Bearer " + token;
  }

  await Axios.put(url, parameters, {
    headers: header,
  })
    .then((response) => {
      res = response;
    })
    .catch((err) => {
      res = err;
    });
  return res;
};

export const get = async (url, token, isAdmin=null) => {
  let res;

  if (token !== null) {
    header.Authorization = "Bearer " + token;
  }

  let headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + token,
  };
  if (isAdmin) header.Authorization = "Administration L=vc2H2hgAm_mdEZ";
   //if (isAdmin) header.Authorization ="Bearer " + token;
  await Axios.get(url, {
    headers: headers,
  })
    .then((response) => {
      res = response;
    })
    .catch((err) => {
      res = err;
    });
  return res;
};

export const getAdmin = async (url, token, isAdmin=null) => {
  let res;

  if (token !== null) {
    header.Authorization = "Bearer " + token;
  }

  let headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + token,
  };
  //if (isAdmin) header.Authorization = "Administration L=vc2H2hgAm_mdEZ";
   if (isAdmin) header.Authorization ="Bearer " + token;
  await Axios.get(url, {
    headers: headers,
  })
    .then((response) => {
      res = response;
    })
    .catch((err) => {
      res = err;
    });
  return res;
};

export const postFormData = async (url, parameters, token) => {
  let res;

  let headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: "Bearer " + token,
    "Content-Type": "multipart/form-data",
  };
  await Axios.post(url, parameters, {
    headers: headers,
  })
    .then((response) => {
      res = response;
    })
    .catch((err) => {
      res = err;
    });
  return res;
};

import React, { useState, useEffect } from "react";
import "./style.css";

const Toggle = ({
  labels = [],
  values = [],
  initialValue,
  onChange,
  isFromTopicSelection,
  questionDataExamType
}) => {
  const [value, setValue] = useState(initialValue);
  const [initialValueChanged, setInitialValueChanged] = useState(false);

  // Effect to update initial value change flag when initialValue prop changes
  useEffect(() => {
    if (isFromTopicSelection && value !== initialValue) {
      console.log("setInitialValueChanged")
      setInitialValueChanged(true);
    } else {
      setInitialValueChanged(false);
    }
  }, [initialValue, isFromTopicSelection, value]);

  const handleToggle = () => {
    const currentIndex = values.indexOf(value);
    const nextIndex = (currentIndex + 1) % values.length;
    const newValue = values[nextIndex];
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <div
      style={{
        display: "flex",
      }}
      className="toggle-switch"
    >
      <div
        //className={`option ${value === values[0] ? "selected" : ""}`}
        //className={`option ${isFromTopicSelection ? initialValueChanged ? "selectedV2" : "": value === values[0] ? "selected" : ""}`}
        onClick={() => handleToggle()}
        className={`option ${
          isFromTopicSelection
            ? ((value !== questionDataExamType) && (value == values[0]))
              ? "selectedV2"
              : value === values[0]
              ? "selected"
              : ""
            : value === values[0]
            ? "selected"
            : ""
        }`}
      >
        {labels[0]}
      </div>
      <div
        //className={`option ${value === values[1] ? "selected" : ""}`}
        //className={`option ${isFromTopicSelection ? initialValueChanged ? "selectedV2" : "": value === values[1] ? "selected" : ""}`}
        onClick={() => handleToggle()}
        className={`option ${
          isFromTopicSelection
            ? (value !== questionDataExamType) && (value == values[1])
              ? "selectedV2"
              : value === values[1]
              ? "selected"
              : ""
            : value === values[1]
            ? "selected"
            : ""
        }`}
      >
        {labels[1]}
      </div>
    </div>
  );
};

export default Toggle;

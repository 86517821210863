import { BookActions } from './../ActionTypes';

const initialState = {
    Books: [],
    RowCount: 0,
    SelectedId: 0
};

export const bookReducer = (state = initialState, action) => {
    switch (action.type) {
        case BookActions.SetAll:
            return Object.assign({}, state, { 
                Books: action.books,
                RowCount: action.rowCount
            });
        case BookActions.SetSelectedId:
            return Object.assign({}, state, { 
                SelectedId: action.selectedId
            });
        default:
            return state;
    }
};
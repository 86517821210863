import React, { useCallback, useState, useEffect } from 'react';
// import ShowImage from './ShowImage';
//import DropBox from './DropBox';
import Dropzone from './DropZone';
import { getFolderNames } from '../../api/ContentManagment';
import './Dropzone.css'
import './index.css'



function Previews() {
  const [folderNames, setFolderNames] = useState([]);
  // const [images, setImages] = useState([]);
  // const onDrop = useCallback((acceptedFiles) => {
  //   acceptedFiles.map((file, index) => {
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       setImages((prevState) => [
  //         ...prevState,
  //         { id: index, src: e.target.result, path: file.path },
  //       ]);
  //     };
  //     reader.readAsDataURL(file);
  //     return file;
  //   });
  // }, []);

  const getFolderName = async (token) => {
    const res = await getFolderNames(token)
    if (res.status == 200) {

      if (res.data.responseStatus == 1) {
        console.log("resData", res.data.responseData)
        setFolderNames(res.data.responseData)
      }
    }
  }
  useEffect(() => {
    getFolderName(sessionStorage.getItem("jwtP"))
  }, [])
  return (
    // <div className="App">
    //      <DropBox 
    //      onDrop={onDrop} 
    //      />
    //   {/* <ShowImage images={images} /> */}
    // </div>
    <div style={{ textAlign: "center" }}>
      <p style={{ textAlign: "center" }}>Dosya Seçmeden Yükleme işlemi yapılmaz</p>
      <div className="content">
        <Dropzone folderNames={folderNames} />
      </div>
    </div>
  );
}
export default Previews;
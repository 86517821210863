import React, { Component } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/styles';
import MuiTable from '@material-ui/core/Table';
import MuiTableCell from '@material-ui/core/TableCell';
import MuiTableRow from '@material-ui/core/TableRow';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
//#endregion

//#region Internal
import { Components, FontSize } from './../../assets/Dimensions';
//#endregion

//#region Override Style
const TableCell = withStyles({
    root: {
        padding: '12px 20px 12px 20px'
    },
    body: {
        fontSize: FontSize.Small
    },
    head: {
        fontWeight: 'bold'
    }
})(MuiTableCell);

const TableRow = withStyles({
    root: {
        '&:hover':{
            backgroundColor: '#f1ececd1'
        }
    }
})(MuiTableRow);

const InnerTable = withStyles({
    root: {
        boxShadow: 'none',
        borderStyle: 'none'
    }
})(MuiTable);

const InnerTableHead = withStyles({
    root: {
        borderStyle: 'none',
        backgroundColor: 'lavenderblush'
    }
})(TableHead);

const InnerTableCell = withStyles({
    root: {
        padding: '5px 0px 10px 45px',
        borderStyle: 'none'
    }
})(MuiTableCell);

const InnerTableRow = withStyles({
    root: {
        borderTopStyle: 'hidden',
        borderStyle: 'none'
    }
})(MuiTableRow);

const ExpansionPanel = withStyles({
    root: {
        display: 'contents'
    }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        display: 'contents'
    },
    content: {
        display: 'contents'
    }
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
    root: {
        display: 'contents'
    }
})(MuiExpansionPanelDetails);
//#endregion

class GridExpansion extends Component {
    constructor(props){
        super(props);

        this.state={};
    }

    render(){
        return(
            <TableBody>
                {this.props.data.rows.map((rowData, rowIndex) => (
                    <ExpansionPanel expanded={this.state.expandedRow === rowIndex+'_expansion'} onChange={() => this._expansionRowChange(rowIndex+'_expansion')}>
                        <ExpansionPanelSummary>
                            <TableRow key={rowIndex}>
                                {rowData.data.map((cellData, cellIndex) => {
                                    return(
                                        <TableCell key={cellIndex}>
                                            {cellData.content}
                                            {cellData.component}
                                        </TableCell>
                                    );
                                })}
                                {rowData.component !== null &&
                                    <TableCell style={{
                                        width: Components.GridButton.Width
                                    }}>
                                        {rowData.component}
                                    </TableCell>
                                }
                            </TableRow>
                            {rowData.comment !== null && rowData.comment}
                        </ExpansionPanelSummary>

                        <ExpansionPanelDetails>
                            <InnerTableRow>
                                <InnerTableCell colSpan={12}>

                                    <InnerTable>
                                        <InnerTableHead>
                                            <InnerTableRow>
                                                {this.props.expansionTableData.columns.map((innerHeadCellData, innerHeadCellIndex) => {
                                                    return(
                                                        <InnerTableCell key={innerHeadCellIndex}>{innerHeadCellData}</InnerTableCell>
                                                    )
                                                })}
                                            </InnerTableRow>
                                        </InnerTableHead>
                                        
                                        <TableBody>
                                                {this.props.expansionTableData.rows.map((innerRowData, innerRowIndex) => (
                                                    <InnerTableRow key={innerRowIndex}>
                                                        {innerRowData.data.map((innerCellData, innerCellIndex) => {
                                                            return(
                                                                <InnerTableCell key={innerCellIndex}>
                                                                    {innerCellData}
                                                                </InnerTableCell>
                                                            )
                                                        })}          
                                                    </InnerTableRow>
                                                ))}
                                        </TableBody>
                                    </InnerTable>

                                </InnerTableCell>
                            </InnerTableRow>
                        </ExpansionPanelDetails>

                    </ExpansionPanel>
                ))}
            </TableBody>
        )
    }
}

GridExpansion.propTypes = {
    data: PropTypes.shape({
        columns: PropTypes.array.isRequired,
        rows: PropTypes.array.isRequired
    }),
    expansionTableData: PropTypes.shape({
        columns: PropTypes.array,
        rows: PropTypes.array
    }).isRequired,
};

GridExpansion.defaultProps = {
    
};

export default withRouter(GridExpansion);
import React, { useEffect, useState, useRef } from "react";
import SideBar from "../components/SideBar";
import { Components } from "../assets/Dimensions";
import { getTeachers, 
    getTeachersDev, 
    onlineLecture, 
    updateOnlineLecture, 
    deleteOnlineLecture, 
    deleteOnlineLectureHomeWork, 
    addOnlineLectureHomeWork, 
    nestedTitles, 
    addLecture, 
    updateOnlineLectureHomeWork, 
    addGeneralLecture, 
    updateGeneralLecture, 
    onlineLectureClearCache,
    onlineLectureUpdateUrlType } from "../api/onlineLecture";
import './onlineLecture.css'
import EditIcon from '@material-ui/icons/Edit';
import MuiIconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/styles';
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify'
import Axios from "axios";
import Loader from "../components/Loader";
import 'react-toastify/dist/ReactToastify.css';
import ResponseStatus from "../util/enums";
import { useHistory } from 'react-router-dom';

const Enums = {
    UrlType: {
      None: 0,
      Youtube: 1,
      WebView: 2,
      VideoPlayer: 3,
    },
  };

const OnlineLecturePanel = () => {
    const [teacherList, setTeacherList] = useState([])
    const [lectureList, setLectureList] = useState([])
    const [displayDateChange, setDisplayDateChange] = useState(false)
    const [dateChangeConfirmation, setDateChangeConfirmation] = useState(false)
    const [deleteLectureModal, setDeleteLectureModal] = useState(false)
    const [startDate, setStartDate] = useState(new Date())
    const [data, setData] = useState([])
    const [selectedLecture, setSelectedLecture] = useState(null)
    const [selectedHomework, setSelectedHomework] = useState(null)
    const [addHomeWorkModal, setAddHomeWorkModal] = useState(false)
    const [selectedHomeworkType, setSelectedHomeworkType] = useState("");
    const [inputValues, setInputValues] = useState(null)
    const [deleteHomeWorkModal, setDeleteHomeWorkModal] = useState(false)
    const [updateHomeWorkModal, setUpdateHomeWorkModal] = useState(false)
    const [addLectureModal, setAddLectureModal] = useState(false)
    const [nestedTitleLecture, setNestedTitleLecture] = useState()
    const [selectedExamCategory, setSelectedExamCategory] = useState("")
    const [selectedCourse, setSelectedCourse] = useState("")
    const [selectedSubCourse, setSelectedSubCourse] = useState("")
    const [selectedSubCourseTopics, setSelectedSubCourseTopics] = useState("")
    const [selectedExamTypeId, setSelectedExamTypeId] = useState("")
    const [selectedThumbnail, setSelectedThumbnail] = useState()
    const [thumbnailPreview, setThumbnailPreview] = useState(null)
    const [selectedTeacher, setSelectedTeacher] = useState("")
    const [addLectureButtonGroup, setAddLectureButtonGroup] = useState(false)
    const [addMutualLectures, setAddMutualLectures] = useState(false)
    const [thumbnailFile, setThumbnailFile] = useState(null)
    const [lecturePdfFile, setLecturePdfFile] = useState(null)
    const [thumbnailFileName, setThumbnailFileName] = useState('');
    const [loading, setLoading] = useState(false)
    const [isUpdateHomework, setIsUpdateHomework] = useState(false)
    const [cacheReqModal, setCacheReqModal] = useState(false)
    const [updateLectureModal, setUpdateLectureModal] = useState(false)
    const [pdfUploadURL, setPdfUploadURL] = useState(null)
    const [pdfUploadLoader, setPdfUploadLoader] = useState(false)
    const [islecturePublic,setIslecturePublic] = useState(false)
    const [selectedUrlTypeValue, setSelectedUrlTypeValue] = useState('');
    // const [title, setTitle] = useState('');
    // const [topicName, setTopicName] = useState('');
    // const [subTopicName, setSubTopicName] = useState('');
    // const [url, setUrl] = useState('');
    const titleRef = useRef(null);
    const updateTitleRef = useRef(null)
    const updateTopicRef = useRef(null)
    const topicNameRef = useRef(null);
    const NormalSubTopicNameRef = useRef(null);
    const UpdateSubTopicNameRef = useRef(null);
    const MutualSubTopicNameRef = useRef(null);
    const messageColorRef = useRef()
    const urlRef = useRef(null);
    const lectureNoteUrlRef = useRef(null);
    const messageRef = useRef(null);
    const buttonRef = useRef();
    const checkLectureRef = useRef();
    const publicLectureRef = useRef();


    // api request for url type change in the table start

    const handleSelectChange =async (event ,item) => {
        const newValue = event.target.value;
       
       
        const requestData = 
            {
                "OnlineLectureId": item.Id,
                "OfflineUrlType": newValue
            } 
        
       await onlineLectureUpdateUrlType(requestData,userAdminToken)
       getLectures()
    }
   
    // api request for url type change in the table end


    const handleTitleChange = (e) => {
        if (sessionStorage.getItem("pdfUrl")) {

            if (checkLectureRef.current) {
                checkLectureRef.current.checked = false;
            }
            if (lectureNoteUrlRef.current) {

                lectureNoteUrlRef.current.value = null;
            }
            if (messageRef.current) {
                messageRef.current.textContent = "Lütfen Pdf Yükleyiniz!"
                messageRef.current.style.color = 'red';
            }
        }
        // if (sessionStorage.getItem("pdfUrl")&&lectureNoteUrlRef.current&&messageRef.current) {

        // }


    };

    const handleTopicNameChange = (e) => {
        //setTopicName(e.target.value);
        if (sessionStorage.getItem("pdfUrl")) {

            if (checkLectureRef.current) {
                checkLectureRef.current.checked = false;
            }
            if (lectureNoteUrlRef.current) {

                lectureNoteUrlRef.current.value = null;
            }
            if (messageRef.current) {
                messageRef.current.textContent = "Lütfen Pdf Yükleyiniz!"
                messageRef.current.style.color = 'red';
            }
        }
        // if (sessionStorage.getItem("pdfUrl")) {
        //     renameFile("mutualLectures")
        // }
        // if (sessionStorage.getItem("pdfUrl")&&lectureNoteUrlRef.current&&messageRef.current) {
        //     lectureNoteUrlRef.current.value = null;
        //     messageRef.current.textContent="Lütfen Pdf Yükleyiniz!"
        //     messageRef.current.style.color = 'red';
        // }

    };

    const handleSubTopicNameChange = (e) => {
        //setSubTopicName(e.target.value);
        if (sessionStorage.getItem("pdfUrl")) {

            if (checkLectureRef.current) {
                checkLectureRef.current.checked = false;
            }
            if (lectureNoteUrlRef.current) {

                lectureNoteUrlRef.current.value = null;
            }
            if (messageRef.current) {
                messageRef.current.textContent = "Lütfen Pdf Yükleyiniz!"
                messageRef.current.style.color = 'red';
            }
        }
        //const inputValue = MutualSubTopicNameRef.current.value;
        // if(lectureNoteUrlRef.current){
        //     lectureNoteUrlRef.current.value = null;
        //     messageRef.current.textContent="Lütfen Pdf Yükleyiniz!"
        //     messageRef.current.style.color = 'red';


        //     sessionStorage.removeItem("pdfUrl")
        // }
    };
    let timeoutId = null;
    const handleUrlChange = (e) => {
        //setUrl(e.target.value);
        const inputValue = urlRef.current.value;

    };
    const handleUpdateTopic = (e) => {
        if (sessionStorage.getItem("pdfUrl")) {

            if (checkLectureRef.current) {
                checkLectureRef.current.checked = false;
            }
            if (lectureNoteUrlRef.current) {

                lectureNoteUrlRef.current.value = null;
            }
            // if(messageRef.current){
            //     messageRef.current.textContent="Lütfen Pdf Yükleyiniz!"
            //     messageRef.current.style.color = 'red';
            // }
        }
        // if(sessionStorage.getItem("pdfUrl")){
        //     if (timeoutId !== null) {
        //         clearTimeout(timeoutId);
        //     }

        //     // Schedule the renameFile function to run after the timeout
        //     timeoutId = setTimeout(() => {
        //         renameFile( "Update");
        //         // You can add any other code you want to execute after the delay here

        //         // Reset the timeoutId to null after the function has run
        //         timeoutId = null;
        //     }, 1000);
        //     //sessionStorage.setItem("isLectureUpdated",true)
        // }
    }
    const handleUpdateTitle = (e) => {
        if (sessionStorage.getItem("pdfUrl")) {

            if (checkLectureRef.current) {
                checkLectureRef.current.checked = false;
            }
            if (lectureNoteUrlRef.current) {

                lectureNoteUrlRef.current.value = null;
            }
            // if(messageRef.current){
            //     messageRef.current.textContent="Lütfen Pdf Yükleyiniz!"
            //     messageRef.current.style.color = 'red';
            // }
        }
        // if(sessionStorage.getItem("pdfUrl")){
        //     if (timeoutId !== null) {
        //         clearTimeout(timeoutId);
        //     }

        //     // Schedule the renameFile function to run after the timeout
        //     timeoutId = setTimeout(() => {
        //         renameFile( "Update");

        //         // You can add any other code you want to execute after the delay here

        //         // Reset the timeoutId to null after the function has run
        //         timeoutId = null;
        //     }, 1000);
        //     //sessionStorage.setItem("isLectureUpdated",true)
        // }
    }
    const handleUpdateSubTopic = (e) => {
        if (sessionStorage.getItem("pdfUrl")) {

            if (checkLectureRef.current) {
                checkLectureRef.current.checked = false;
            }
            if (lectureNoteUrlRef.current) {

                lectureNoteUrlRef.current.value = null;
            }
            // if(messageRef.current){
            //     messageRef.current.textContent="Lütfen Pdf Yükleyiniz!"
            //     messageRef.current.style.color = 'red';
            // }
        }
        // if(sessionStorage.getItem("pdfUrl")){
        //     if (timeoutId !== null) {
        //         clearTimeout(timeoutId);
        //     }

        //     // Schedule the renameFile function to run after the timeout
        //     timeoutId = setTimeout(() => {
        //         renameFile( "Update");
        //         // You can add any other code you want to execute after the delay here

        //         // Reset the timeoutId to null after the function has run
        //         timeoutId = null;
        //     }, 1000);
        //     //sessionStorage.setItem("isLectureUpdated",true)
        // }
    }
    let pdfUploadMessage = '';
    const Input2 = ({ value, setValue, name, required }) => {
        return <input required={required} id={name} name={name} type="text" value={value} onChange={(e) => setValue(e.target.value)} />;
    };
    const user = JSON.parse(localStorage.getItem("user"))
    const userAdminToken = sessionStorage.getItem("jwtP")
    const styles = {
        container: {
            marginLeft: Components.SideBarWidth + 15,
            paddingTop: 20,
            marginRight: 30,
        },
        rowActionIcon: {
            width: Components.Image.Big.Width,
            height: Components.Image.Big.Height
        },
        ChangeDateModalStyle: {
            padding: 20,
            margin: "auto"
        },
        iconButton: {
            right: 0,
            top: 0,
        }
    };
    const history = useHistory();
    const Dialog = withStyles({
        paperWidthSm: {
            maxWidth: 720,
            minWidth: 300,
            minHeight: 220,
        },
    })(MuiDialog);
    const DialogContent = withStyles({
        root: {
            paddingTop: 0,
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
        },
    })(MuiDialogContent);

    const DialogTitle = withStyles({
        root: {
            marginLeft: 20,
        },
    })(MuiDialogTitle);

    const IconButton = withStyles({
        label: {
            width: Components.Image.Huge.Width,
            height: Components.Image.Huge.Height
        }
    })(MuiIconButton);
    const importFile = async (e) => {
        const formData = new FormData();
        let headers = {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + userAdminToken,
            "Content-Type": "multipart/form-data",
        };
        formData.append("ThumbnailUrl", thumbnailFile);
        try {
            const res = await Axios.post("https://api.pakodemy.com/api/OnlineLecture/addLecture", formData, {
                headers: headers,
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    const getUploadedFile = (file) => {
        setThumbnailFile(file)
        console.log("getFileFromComp", file)
    }
    const getLectures = async () => {
        setLoading(true)
        const res = await onlineLecture(userAdminToken)

        if (res.status == 200) {
            setLectureList(res.data.ResponseData)
            console.log(res.data.ResponseData)
            setLoading(false)
        } else if (res == "Error: Request failed with status code 401") {
            setLoading(false)
            toast.error("Lütfen Tekrar Giriş Yapınız")
            history.push('/login')
        } else {
            toast.error("Bir hata oluştu lütfen yetkililere ulaşınız")
            console.log("error", res)
        }
    }
    const teachers = async () => {
        setLoading(true)
        const res = await getTeachers(userAdminToken)
        if (res.status == 200) {
            setTeacherList(res.data.ResponseData)
            setLoading(false)

        } else if (res == "Error: Request failed with status code 401") {
            setLoading(false)
            toast.error("Lütfen Tekrar Giriş Yapınız")
            history.push('/login')
        } else {
            toast.error("Bir hata oluştu lütfen yetkililere ulaşınız")
            console.log("error", res)
        }
    }
    const NestedLectures = async () => {
        setLoading(true)
        const res = await nestedTitles(userAdminToken)
        if (res.status == 200) {
            setNestedTitleLecture(res.data)
            localStorage.setItem("nestedTitles", JSON.stringify(res.data))
            setLoading(false)
        }
    }
    const updateLecture = async (lectureInfo, updatedInfo) => {
        setLoading(true)
        console.log("updatedDate", updatedInfo)
        const updatedPayload = {
            ...lectureInfo,
            Date: updatedInfo
        };
        console.log("updatedLectureInfo", JSON.stringify(updatedPayload))
        console.log("updatedLectureInfo", updatedPayload)
        // const formData = new FormData()
        // for ( var key in updatedPayload ) {
        //     formData.append(key, updatedPayload[key]);
        // }
        const formData = new FormData();
        formData.append("OnlineLectureTeacherId", updatedPayload.TeacherInfos.TeacherId);
        for (const key in updatedPayload) {
            if (key !== "Homeworks" && key !== "TeacherInfos" && updatedPayload[key] != null) {
                formData.append(key, updatedPayload[key]);
            }
        }
        console.log("updatedFormData", formData)
        // formData.delete("Date")
        // formData.append("Date",updatedInfo)

        //console.log("updatedPayload", updatedPayload)

        const res = await (selectedLecture.CourseId == 0 ? updateGeneralLecture(formData, userAdminToken) : updateOnlineLecture(formData, userAdminToken))
        if (res.data.ResponseStatus == ResponseStatus.Success) {
            //setLectureList(res.data.ResponseData)
            toast.success("Ders Güncelleme başarılı")
            getLectures()
        } else toast.error(res.data.ResponseMessage)
        setSelectedLecture(null)
        setLoading(false)
    }
    const clearCache = async () => {
        const res = await onlineLectureClearCache(userAdminToken)
        if (res.status == 200) {
            toast.success("Sistem Güncellenmiştir")
            getLectures()
        }
    }
    const dateChange = (lectureInfo) => {

        console.log("lectureInfo", lectureInfo)


        // Convert the string to a Date object, using the local time zone
        const dateObj = new Date(lectureInfo);

        // Adjust the date and time values to match the GMT time zone
        const gmtTime = dateObj.getTime() - dateObj.getTimezoneOffset() * 60000;
        const gmtDateObj = new Date(gmtTime);

        // Get the date string in ISO format, with the time zone set to GMT
        const isoDateString = gmtDateObj.toISOString();

        // Format the date string to remove the milliseconds and append the "Z" timezone designator
        const formattedDateString = isoDateString.slice(0, -5);

        console.log("formatedDate", formattedDateString);

        return formattedDateString
    }
    useEffect(() => {
        getLectures()
        teachers()
        NestedLectures()
        sessionStorage.removeItem("pdfUrl")
    }, [])
    const teacherConfimationModal = (name) => {
        return (
            <div>
                <p>{name}</p>
                <p>Seçilen öğretmeni onaylıyormusunuz?</p>
                <div className=" flex">
                    <button>
                        Onayla
                    </button>
                    <button>
                        İptal Et
                    </button>
                </div>
            </div>
        )
    }
    const handleCellChange = (rowIndex, colIndex, value) => {
        // Update the data array with the new cell value
        const updatedData = [...data];
        updatedData[rowIndex][colIndex] = value;
        setData(updatedData);
    }
    const deleteLecture = async (id, RowStatus) => {
        setLoading(true)
        const lectureData = {
            "Id": id,

        }
        console.log("deleted Lecture", lectureData)
        const res = await deleteOnlineLecture(lectureData, userAdminToken)
        if (res.status == 200) {
            toast.success("ders kaldırılmıştır")
            setLoading(false)
            getLectures()
        }
    }
    const addOnlineLecture = async (lectureData) => {
        setLoading(true)
        const res = await addLecture(lectureData, userAdminToken)
        if (res.status == 200) {
            toast.success("ders eklenmiştir")
            setLoading(false)
        } else {
            toast.error("Bir hata oluştu")
            setLoading(false)
        }
    }
    const deleteHomework = async (id) => {
        setLoading(true)
        console.log("deleted HomeWork", id)
        const res = await deleteOnlineLectureHomeWork(id, userAdminToken)
        if (res.status == 200) {
            toast.success("Ödev kaldırılmıştır")
            getLectures()
        }
        setLoading(false)
    }
    const dateFormatDisplay = (lectureDate) => {
        const date = new Date(lectureDate);
        const localDateString = date.toLocaleDateString('tr-TR', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        return localDateString
    }
    const teacherNameById = (id) => {
        const teacher = teacherList.find(item => item.Id == id)
        return teacher ? teacher.TeacherName : null
    }
    const handleOptionChange = (event) => {
        setSelectedHomeworkType(event.target.value);
    };
    const handleExamCategoryOptionChange = (event) => {
        if (sessionStorage.getItem("pdfUrl")) {

            if (checkLectureRef.current) {
                checkLectureRef.current.checked = false;
            }
            if (lectureNoteUrlRef.current) {

                lectureNoteUrlRef.current.value = null;
            }
            if (messageRef.current) {
                messageRef.current.textContent = "Lütfen Pdf Yükleyiniz!"
                messageRef.current.style.color = 'red';
            }
        }
        setSelectedExamCategory(event.target.value)
        setSelectedCourse("")
        setSelectedSubCourse("")
        setSelectedSubCourseTopics("")
        // if(lectureNoteUrlRef.current){
        //     lectureNoteUrlRef.current.value = null;
        //     messageRef.current.textContent="Lütfen Pdf Yükleyiniz!"
        //     messageRef.current.style.color = 'red';
        //     if (messageColorRef.current) {
        //         messageColorRef.current.textContent = 'red';
        //       }

        //     sessionStorage.removeItem("pdfUrl")
        // }
        // if (sessionStorage.getItem("pdfUrl")) {
        //     renameFile("normalLectures")
        // }
    }
    const handleCourseOptionChange = (event) => {
        if (sessionStorage.getItem("pdfUrl")) {

            if (checkLectureRef.current) {
                checkLectureRef.current.checked = false;
            }
            if (lectureNoteUrlRef.current) {

                lectureNoteUrlRef.current.value = null;
            }
            if (messageRef.current) {
                messageRef.current.textContent = "Lütfen Pdf Yükleyiniz!"
                messageRef.current.style.color = 'red';
            }
        }
        setSelectedCourse(event.target.value)
        setSelectedSubCourse("")
        setSelectedSubCourseTopics("")
        // if(lectureNoteUrlRef.current){
        //     lectureNoteUrlRef.current.value = null;
        //     messageRef.current.textContent="Lütfen Pdf Yükleyiniz!"
        //     messageRef.current.style.color = 'red';
        //     if (messageColorRef.current) {
        //         messageColorRef.current.textContent = 'red';
        //       }

        //     sessionStorage.removeItem("pdfUrl")
        // }
        // if (sessionStorage.getItem("pdfUrl")) {
        //     renameFile("normalLectures")
        // }
    }
    const handleSubCourseChange = (event) => {
        if (sessionStorage.getItem("pdfUrl")) {

            if (checkLectureRef.current) {
                checkLectureRef.current.checked = false;
            }
            if (lectureNoteUrlRef.current) {

                lectureNoteUrlRef.current.value = null;
            }
            if (messageRef.current) {
                messageRef.current.textContent = "Lütfen Pdf Yükleyiniz!"
                messageRef.current.style.color = 'red';
            }
        }
        setSelectedSubCourse(event.target.value)
        setSelectedSubCourseTopics("")
        // if(lectureNoteUrlRef.current){
        //     lectureNoteUrlRef.current.value = null;
        //     messageRef.current.textContent="Lütfen Pdf Yükleyiniz!"
        //     messageRef.current.style.color = 'red';
        //     if (messageColorRef.current) {
        //         messageColorRef.current.textContent = 'red';
        //       }

        //     sessionStorage.removeItem("pdfUrl")
        // }
        // if (sessionStorage.getItem("pdfUrl")) {
        //     renameFile("normalLectures")
        // }
    }
    const handleSubCourseTopicsChange = (event) => {
        if (sessionStorage.getItem("pdfUrl")) {

            if (checkLectureRef.current) {
                checkLectureRef.current.checked = false;
            }
            if (lectureNoteUrlRef.current) {

                lectureNoteUrlRef.current.value = null;
            }
            if (messageRef.current) {
                messageRef.current.textContent = "Lütfen Pdf Yükleyiniz!"
                messageRef.current.style.color = 'red';
            }
        }
        setSelectedSubCourseTopics(event.target.value)
        // if(lectureNoteUrlRef.current){
        //     lectureNoteUrlRef.current.value = null;
        //     messageRef.current.textContent="Lütfen Pdf Yükleyiniz!"
        //     messageRef.current.style.color = 'red';
        //     if (messageColorRef.current) {
        //         messageColorRef.current.textContent = 'red';
        //       }

        //     sessionStorage.removeItem("pdfUrl")
        // }
        // if (sessionStorage.getItem("pdfUrl")) {
        //     renameFile("normalLectures")
        // }
    }
    const handleExamTypeCheckbox = (event) => {
        setSelectedExamTypeId(event.target.value)
        setSelectedCourse("")
        setSelectedExamCategory("")
        setSelectedSubCourse("")
        setSelectedSubCourseTopics("")
        // if(lectureNoteUrlRef.current){
        //     lectureNoteUrlRef.current.value = null;
        //     messageRef.current.textContent="Lütfen Pdf Yükleyiniz!"
        //     messageRef.current.style.color = 'red';
        //     if (messageColorRef.current) {
        //         messageColorRef.current.textContent = 'red';
        //       }

        //     sessionStorage.removeItem("pdfUrl")
        // }
        // if (sessionStorage.getItem("pdfUrl")) {
        //     renameFile("normalLectures")
        // }
    }
    const handleTeacherChange = (event) => {
        setSelectedTeacher(event.target.value)
    }
    const handleSoruSayısıInputChange = (event) => {
        const value = parseInt(event.target.value);
        if (value < 1 || isNaN(value)) {
            event.target.value = "";
        } else if (value > 120) {
            event.target.value = 120;
        }
    };
    const addOrUpdateHomework = async (isUpdate, HomeWorkData) => {
        const res = await (isUpdate ? updateOnlineLectureHomeWork(HomeWorkData, userAdminToken) : addOnlineLectureHomeWork(HomeWorkData, userAdminToken))
        if (res.status == 200) {
            isUpdate ? toast.success("ders başarıyla güncellenmiştir") : toast.success("ders başarıyla eklenmiştir")
            getLectures()
        } else {
            toast.error("bir sorun oluştu lütfen tekrar deneyiniz")
        }
    }
    const thumbnailUpload = async (event) => {
        // //     const file = event.target.files[0];

        // // setThumbnailFile(file);
        console.log("evet.target", event.target)
        // setThumbnailPreview(URL.createObjectURL(event.target.files[0]));
        const selectedFile = event.target.files[0];
        console.log("fileInput", selectedFile)
        setThumbnailFile(selectedFile);

        if (!selectedFile) {
            return;
        }

        setSelectedThumbnail(selectedFile);

        const objectUrl = URL.createObjectURL(selectedFile); // Create temporary URL
        setThumbnailPreview(objectUrl);
        setThumbnailFileName(selectedFile.name);

        //addOnlineLecture(selectedFile)
    }
    const handleLecturePdf = async (event) => {
        event.preventDefault();

        console.log("evet.target", event.target)
        const selectedFile = event.target.files[0];
        console.log("fileInputPDF", selectedFile)
        setLecturePdfFile(selectedFile);

        if (!selectedFile) {
            return;
        }

    }
    const handleMutualLecturePdfRef = (e) => {
        e.preventDefault(); // Prevent form reset

        // Handle PDF upload here
        messageRef.current.textContent = "PDF dosyanız yükleniyor Lütfen Bekleyiniz"
        messageRef.current.style.color = 'red';
        // Access the file input value if needed
        const selectedFile = lectureNoteUrlRef.current.files[0];
        console.log(selectedFile);
        uploadPDFFiles(selectedFile, "mutualLectures")
        // Continue with handling the PDF file
    };

    const handleLecturePdfRef = (e) => {
        e.preventDefault(); // Prevent form reset

        // Handle PDF upload here
        messageRef.current.textContent = "PDF dosyanız yükleniyor Lütfen Bekleyiniz"
        messageRef.current.style.color = 'red';
        // Access the file input value if needed
        const selectedFile = lectureNoteUrlRef.current.files[0];
        console.log(selectedFile);
        uploadPDFFiles(selectedFile, "normalLectures")
        // Continue with handling the PDF file
    };
    const handleUpdateLecturePdfRef = (e) => {
        e.preventDefault(); // Prevent form reset
        messageRef.current.textContent = "PDF dosyanız yükleniyor Lütfen Bekleyiniz"
        messageRef.current.style.color = 'red';
        // Handle PDF upload here

        // Access the file input value if needed
        const selectedFile = lectureNoteUrlRef.current.files[0];
        console.log(selectedFile);
        uploadPDFFiles(selectedFile, "updateLecture")
        // Continue with handling the PDF file
    };



    // useEffect(() => {
    //     let fileReader, isCancel = false;
    //     if (thumbnailFile) {
    //       fileReader = new FileReader();
    //       fileReader.onload = (e) => {
    //         const { result } = e.target;
    //         if (result && !isCancel) {
    //           setThumbnailPreview(result)
    //         }
    //       }
    //       fileReader.readAsDataURL(thumbnailFile);
    //     }
    //     return () => {
    //       isCancel = true;
    //       if (fileReader && fileReader.readyState === 1) {
    //         fileReader.abort();
    //       }
    //     }

    //   }, [thumbnailFile]);
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setInputValues((prevInputValues) => ({
            ...prevInputValues,
            [id]: value
        }));
    };
    const handleCheckLectureNote = () => {

        // if (sessionStorage.getItem("pdfUrl")) {
        //     if (addMutualLectures) {

        //         renameFile("mutualLectures")
        //     } else if (addLectureModal) {
        //         renameFile("normalLectures")

        //     } else {

        //         renameFile("Update")
        //     }
        // }
    }
    const handlePublicLectureCheck = () => {
        console.log("islecturePublic",islecturePublic)
        setIslecturePublic(!islecturePublic)
    }
    const handleHomeworkSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        // Use the inputValues object here for API request
        const formJson = Object.fromEntries(formData.entries());
        formJson.OrderId = parseInt(formJson.OrderId)
        if (formJson.QuestionCount) {
            formJson.QuestionCount = parseInt(formJson.QuestionCount);
        }
        formJson["OnlineLectureId"] = selectedLecture.Id
        formJson["SubCourseTopicUniqueId"] = selectedLecture.SubCourseTopicUniqueId
        formJson["ChannelCode"] = "Web"
        if (isUpdateHomework) {
            formJson["Id"] = selectedHomework.Id
            formJson["SubCourseTopicVideoId"] = selectedHomework.SubCourseTopicVideoId

        }
        formJson["SubCourseTopicVideoUrl"] = ""
        //console.log("selectedHomeWork",selectedHomework)
        addOrUpdateHomework(isUpdateHomework, formJson)
        console.log(formJson);
        console.log(inputValues);
        setUpdateHomeWorkModal(false)
        setAddHomeWorkModal(false);
        setSelectedLecture(null);
        setSelectedHomework(null)
        setThumbnailFile(null)
        setLecturePdfFile(null)
        setPdfUploadLoader(false)
        setThumbnailPreview(null)
    };
    const handleLectureUpdate = async (event) => {
        event.preventDefault();

        //-------------------------------------------------------------------------------------------------------------------

        if (sessionStorage.getItem("pdfUrl")) {
            messageRef.current.textContent = "PDF Dosyası Güncelleniyor lütfen bekleyiniz"
            messageRef.current.style.color = 'red';
            buttonRef.current.disabled = true;

        }
        let customFileName;

        // let selectedSubCourseName = selectedLecture.SubTopicName.includes("/") ?selectedLecture.SubTopicName.split('/')[1].trim():selectedLecture.SubTopicName
        // let selectedSubTopicName =  selectedLecture.SubTopicName.includes("/") ?selectedLecture.SubTopicName.split('/')[0].trim():selectedLecture.TopicName
        // let selectedTopicName = selectedLecture.Title
        // let SourceTypeName;
        // let Title;
        // let subTitleName;
        // let SubCourseName;
        // SourceTypeName = nestedTitleLecture.find((sourceType)=>sourceType.SourceTypeId == selectedLecture.ExamCategoryId).SourceTypeName
        // Title = selectedLecture.SubCourseTopicUniqueId == 0 && updateTitleRef.current !==  selectedTopicName? updateTitleRef.current.value: selectedLecture.Title
        // subTitleName = selectedLecture.SubCourseTopicUniqueId == 0 && updateTopicRef.current !== selectedSubTopicName ? updateTopicRef.current.value:selectedSubTopicName
        // //SubCourseName =  nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).Courses.find((course) => course.Id == selectedCourse).CourseTopics.find((subCourse) => subCourse.CourseTopicUniqueId == selectedSubCourse).SubCourseTopics.find((newSubCourse)=>newSubCourse.SubCourseTopicUniqueId==selectedLecture.SubCourseTopicUniqueId).NewSubCourseTopicName
        // SubCourseName=UpdateSubTopicNameRef.current !== selectedSubCourseName?UpdateSubTopicNameRef.current.value: selectedLecture.SubTopicName
        // customFileName = SourceTypeName + `${Title?"_"+Title:""}`+ `${subTitleName?"_"+subTitleName:""}`  + `${SubCourseName?"_":""}` + SubCourseName
        // // if (UpdateSubTopicNameRef.current.value !== selectedLecture.SubTopicName) {
        // //     customFileName += '_' + UpdateSubTopicNameRef.current.value;

        // // }
        let selectedSubCourseName =selectedLecture.SubTopicName&& selectedLecture.SubTopicName.includes("/") ? selectedLecture.SubTopicName.split('/')[1].trim() : ""
        let selectedSubTopicName =selectedLecture.SubTopicName&& selectedLecture.SubTopicName.includes("/") ? selectedLecture.SubTopicName.split('/')[0].trim() : selectedLecture.SubTopicName
        let SourceTypeName;
        let Title;
        let SubCourseName;
        let subTitleName;
        SourceTypeName = nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedLecture.ExamCategoryId).SourceTypeName
        SubCourseName = UpdateSubTopicNameRef.current !== selectedSubCourseName ? UpdateSubTopicNameRef.current.value : selectedLecture.SubTopicName
        if (selectedLecture.SubCourseTopicUniqueId == 0) {
            Title = updateTitleRef.current !== selectedLecture.Title ? updateTitleRef.current.value : selectedLecture.Title
            subTitleName = updateTopicRef.current !== selectedSubTopicName ? updateTopicRef.current.value : selectedSubTopicName

            customFileName = SourceTypeName + `${Title ? "_" + Title : ""}` + `${subTitleName ? "_" + subTitleName : ""}` + `${SubCourseName ? "_" + SubCourseName : ""}`
        } else {
            customFileName = SourceTypeName + `${selectedLecture.TopicName ? "_" + selectedLecture.TopicName : ""}` + `${selectedSubTopicName ? "_" + selectedSubTopicName : ""}` + `${SubCourseName ? "_" + SubCourseName : ""}`

        }

        const requestData = {
            "oldFile": sessionStorage.getItem("pdfUrl"),
            "newFile": customFileName,
            ...(selectedLecture ? { "OnlineLectureId": selectedLecture.Id } : {})
            // "folderName":"test"
        }

        const headers = {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + userAdminToken,
            "Content-Type": "application/json",
        };
        Axios.post('https://contentprocess.tosanalytics.com/api/mediafile/renamefile', requestData, {
            headers: headers,

        }).then((response) => {
            console.log("response status", response)
            if (response.data.responseStatus === 0) {
                // Handle error here (response.data.responseMessage contains the error message)
                console.error('Error uploading files:', response.data.responseMessage);
                toast.error(response.data.responseMessage)
                if (updateLectureModal && messageRef.current) {


                    messageRef.current.textContent = "Pdf dosyası yüklenemedi"
                    messageRef.current.style.color = 'red';
                    buttonRef.current.disabled = true;
                }

                //uploadRef.current.innerHTML = `<span class="error">Error Uploading File(s): ${response.data.responseMessage}</span>`;
                //progressRef.current.style.backgroundColor = 'red';
            } else {
                // Handle successful upload here (if needed)
                console.log('Upload successful', response);
                toast.success("PDF yükleme başarılı")
                getLectures()
                // if(context == "mutualLectures" && addMutualLectures ){
                //     messageRef.current.textContent = "PDF Güncelleme başarılı"
                // messageRef.current.style.color = 'green';
                // buttonRef.current.disabled = false;
                // }else if(context == "normalLectures" && addLectureModal){
                //     messageRef.current.textContent = "PDF Güncelleme başarılı"
                //     messageRef.current.style.color = 'green';
                //     buttonRef.current.disabled = false;
                // }else if(context == "Update" && updateLectureModal ){
                //     messageRef.current.textContent = "PDF Güncelleme başarılı"
                //     messageRef.current.style.color = 'green';
                //     buttonRef.current.disabled = false;
                // }
                if (updateLectureModal && messageRef.current) {

                    messageRef.current.textContent = "PDF Güncelleme başarılı"
                    messageRef.current.style.color = 'green';
                    buttonRef.current.disabled = false;
                }
                // setPdfUploadLoader(true)
                // uploadRef.current.innerHTML = 'File(s) Uploaded Successfully';
                //progressRef.current.style.backgroundColor = 'green';
                //sessionStorage.setItem("pdfUrl", response.data.responseData.imageUrl)

            }
        })
            .catch((error) => {

                // Handle any other errors (e.g., network errors)
                console.error('Error uploading files:', error);
                toast.error("Pdf dosyası yüklenemedi ");
                if (updateLectureModal && messageRef.current) {

                    messageRef.current.textContent = "Pdf dosyası yüklenemedi"
                    messageRef.current.style.color = 'red';
                    buttonRef.current.disabled = true;
                }


            });


        //-------------------------------------------------------------------------------------------------------------------

        const title = selectedLecture.SubCourseTopicUniqueId == 0 ? event.target.elements.Title.value : null;
        const topicName = selectedLecture.SubCourseTopicUniqueId == 0 ? event.target.elements.TopicName.value : null;
        const subTopicName = event.target.elements.SubTopicName.value;
        const url = event.target.elements.StreamUrl.value;
        const lectureNoteUrl = sessionStorage.getItem("pdfUrl") !== selectedLecture.LectureNoteUrl ? sessionStorage.getItem("pdfUrl") : selectedLecture.LectureNoteUrl
        const IsPublic = islecturePublic

        //formData.append("ThumbnailUrl", thumbnailFile)

        // update the information about the selected lecture with the new values
        const updatedLecture = {
            ...selectedLecture,
            SubTopicName: subTopicName,
            StreamUrl: url,
            ThumbnailUrl: thumbnailFile,
            LectureNoteUrl: lectureNoteUrl,
            IsPublic:IsPublic
        };
        if (selectedLecture.SubCourseTopicUniqueId == 0) {
            updatedLecture.Title = title;
            updatedLecture.TopicName = topicName;
        }
        const formData = new FormData();
        formData.append("OnlineLectureTeacherId", updatedLecture.TeacherInfos.TeacherId);
        for (const key in updatedLecture) {
            if (key !== "Homeworks" && key !== "TeacherInfos" && updatedLecture[key] != null) {
                formData.append(key, updatedLecture[key]);
            }
        }
        console.log("updatedFormData", formData)
        // call a function to update the lecture in your data source
        const res = await (selectedLecture.SubCourseTopicUniqueId == 0 ?
            updateGeneralLecture(formData, userAdminToken) :
            updateOnlineLecture(formData, userAdminToken))
        if (res.status == 200) {
            //setLectureList(res.data.ResponseData)
            if (res.data.ResponseStatus == 1) {

                toast.success("Ders Güncelleme başarılı")
                setUpdateLectureModal(false)
                setSelectedLecture(null)
                setThumbnailFile(null)
                setThumbnailPreview(null)
                sessionStorage.removeItem("pdfUrl")
                getLectures()
                setIslecturePublic(false)
            } else {
                toast.error(res.data.ResponseMessage)
            }

        }
        setLoading(false)

    }


    //offline url types start
    
    useEffect(() => {
        // Fetch value from local storage on component mount
        const storedValue = localStorage.getItem('selectedUrlTypeValue');
        if (storedValue) {
          setSelectedUrlTypeValue(parseInt(storedValue, 10)); // Convert to number
        } else {
          // If no value stored, set default value to Enums.UrlType.None
          setSelectedUrlTypeValue(Enums.UrlType.None);
        }
      }, []);
    
      const handleChange = (event) => {
        const value = parseInt(event.target.value, 10); // Convert to number
        setSelectedUrlTypeValue(value);
        // Store value in local storage
        localStorage.setItem('selectedUrlTypeValue', value);
      };
    
      // Generate options based on Enums.UrlType
      const options = Object.keys(Enums.UrlType).map((key) => {
        const value = Enums.UrlType[key];
        return <option key={value} value={value}>{key}</option>;
      });
    
    //offline url types end 


    //-------------------------------pdf file rename Start

    const renameFile = async (context) => {
        if (sessionStorage.getItem("pdfUrl") && messageRef.current) {
            messageRef.current.textContent = "PDF Dosyası Güncelleniyor lütfen bekleyiniz"
            messageRef.current.style.color = 'red';
            buttonRef.current.disabled = true;

        }
        let customFileName;
        if (context == "mutualLectures") {
            customFileName = titleRef.current.value;

            if (topicNameRef.current.value) {
                customFileName += '_' + topicNameRef.current.value;

                if (MutualSubTopicNameRef.current.value) {
                    customFileName += '_' + MutualSubTopicNameRef.current.value;

                }

            }
        } else if (context == "normalLectures") {
            console.log("selectedExamCategory", selectedExamCategory)
            let SourceTypeName;
            let CourseName;
            let SubCourseName;
            SourceTypeName = nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).SourceTypeName
            CourseName = nestedTitleLecture
                .find((sourceType) => sourceType.SourceTypeId == selectedExamCategory)
                .Courses.find((course) => course.Id == selectedCourse)
                .CourseName;
            SubCourseName = nestedTitleLecture
                .find((sourceType) => sourceType
                    .SourceTypeId == selectedExamCategory)
                .Courses.find((course) => course.Id == selectedCourse)
                .CourseTopics.find((subCourse) => subCourse.CourseTopicUniqueId == selectedSubCourse)
                .SubCourseTopics.find((newSubCourse) => newSubCourse.SubCourseTopicUniqueId == selectedSubCourseTopics)
                .NewSubCourseTopicName
            //nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).Courses.find((course) => course.Id == selectedCourse).CourseTopics.find((subCourse) => subCourse.CourseTopicUniqueId == selectedSubCourse).SubCourseTopics.find((newSubCourse)=>newSubCourse.SubCourseTopicUniqueId==selectedSubCourseTopics).NewSubCourseTopicName
            customFileName = SourceTypeName + "_" + CourseName + "_" + SubCourseName
            if (NormalSubTopicNameRef.current.value) {
                customFileName += '_' + NormalSubTopicNameRef.current.value;

            }
        } else {
            // let selectedSubCourseName = selectedLecture.SubTopicName.includes("/") ?selectedLecture.SubTopicName.split('/')[1].trim():selectedLecture.SubTopicName
            // let selectedSubTopicName =  selectedLecture.SubTopicName.includes("/") ?selectedLecture.SubTopicName.split('/')[0].trim():selectedLecture.TopicName
            // let selectedTopicName = selectedLecture.Title
            // let SourceTypeName;
            // let Title;
            // let subTitleName;
            // let SubCourseName;
            // SourceTypeName = nestedTitleLecture.find((sourceType)=>sourceType.SourceTypeId == selectedLecture.ExamCategoryId).SourceTypeName
            // Title = selectedLecture.SubCourseTopicUniqueId == 0 && updateTitleRef.current !==  selectedTopicName? updateTitleRef.current.value: selectedLecture.Title
            // subTitleName = selectedLecture.SubCourseTopicUniqueId == 0 && updateTopicRef.current !== selectedSubTopicName ? updateTopicRef.current.value:selectedSubTopicName
            // //SubCourseName =  nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).Courses.find((course) => course.Id == selectedCourse).CourseTopics.find((subCourse) => subCourse.CourseTopicUniqueId == selectedSubCourse).SubCourseTopics.find((newSubCourse)=>newSubCourse.SubCourseTopicUniqueId==selectedLecture.SubCourseTopicUniqueId).NewSubCourseTopicName
            // SubCourseName=UpdateSubTopicNameRef.current !== selectedSubCourseName?UpdateSubTopicNameRef.current.value: selectedLecture.SubTopicName
            // customFileName = SourceTypeName + `${Title?"_"+Title:""}`+ `${subTitleName?"_"+subTitleName:""}`  + `${SubCourseName?"_":""}` + SubCourseName
            // // if (UpdateSubTopicNameRef.current.value !== selectedLecture.SubTopicName) {
            // //     customFileName += '_' + UpdateSubTopicNameRef.current.value;

            // // }
            let selectedSubCourseName =selectedLecture.SubTopicName&& selectedLecture.SubTopicName.includes("/") ? selectedLecture.SubTopicName.split('/')[1].trim() : ""
            let selectedSubTopicName =selectedLecture.SubTopicName&& selectedLecture.SubTopicName.includes("/") ? selectedLecture.SubTopicName.split('/')[0].trim() : selectedLecture.SubTopicName
            let SourceTypeName;
            let Title;
            let SubCourseName;
            let subTitleName;
            SourceTypeName = nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedLecture.ExamCategoryId).SourceTypeName
            SubCourseName = UpdateSubTopicNameRef.current !== selectedSubCourseName ? UpdateSubTopicNameRef.current.value : selectedLecture.SubTopicName
            if (selectedLecture.SubCourseTopicUniqueId == 0) {
                Title = updateTitleRef.current !== selectedLecture.Title ? updateTitleRef.current.value : selectedLecture.Title
                subTitleName = updateTopicRef.current !== selectedSubTopicName ? updateTopicRef.current.value : selectedSubTopicName

                customFileName = SourceTypeName + `${Title ? "_" + Title : ""}` + `${subTitleName ? "_" + subTitleName : ""}` + `${SubCourseName ? "_" + SubCourseName : ""}`
            } else {
                customFileName = SourceTypeName + `${selectedLecture.TopicName ? "_" + selectedLecture.TopicName : ""}` + `${selectedSubTopicName ? "_" + selectedSubTopicName : ""}` + `${SubCourseName ? "_" + SubCourseName : ""}`

            }
        }
        const requestData = {
            "oldFile": sessionStorage.getItem("pdfUrl"),
            "newFile": customFileName,
            ...(selectedLecture ? { "OnlineLectureId": selectedLecture.Id } : {})
            // "folderName":"test"
        }

        const headers = {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + userAdminToken,
            "Content-Type": "application/json",
        };
        Axios.post('https://contentprocess.tosanalytics.com/api/mediafile/renamefile', requestData, {
            headers: headers,

        }).then((response) => {
            console.log("response status", response)
            if (response.data.responseStatus === 0) {
                // Handle error here (response.data.responseMessage contains the error message)
                console.error('Error uploading files:', response.data.responseMessage);
                toast.error(response.data.responseMessage)

                if (context == "mutualLectures" && addMutualLectures) {
                    messageRef.current.textContent = "Pdf dosyası yüklenemedi"
                    messageRef.current.style.color = 'red';
                    buttonRef.current.disabled = true;
                } else if (context == "normalLectures" && addLectureModal) {
                    messageRef.current.textContent = "Pdf dosyası yüklenemedi"
                    messageRef.current.style.color = 'red';
                    buttonRef.current.disabled = true;
                } else if (context == "Update" && updateLectureModal) {
                    messageRef.current.textContent = "Pdf dosyası yüklenemedi"
                    messageRef.current.style.color = 'red';
                    buttonRef.current.disabled = true;
                }
                //uploadRef.current.innerHTML = `<span class="error">Error Uploading File(s): ${response.data.responseMessage}</span>`;
                //progressRef.current.style.backgroundColor = 'red';
            } else {
                // Handle successful upload here (if needed)
                console.log('Upload successful', response);
                toast.success("PDF yükleme başarılı")
                // if(context == "mutualLectures" && addMutualLectures ){
                //     messageRef.current.textContent = "PDF Güncelleme başarılı"
                // messageRef.current.style.color = 'green';
                // buttonRef.current.disabled = false;
                // }else if(context == "normalLectures" && addLectureModal){
                //     messageRef.current.textContent = "PDF Güncelleme başarılı"
                //     messageRef.current.style.color = 'green';
                //     buttonRef.current.disabled = false;
                // }else if(context == "Update" && updateLectureModal ){
                //     messageRef.current.textContent = "PDF Güncelleme başarılı"
                //     messageRef.current.style.color = 'green';
                //     buttonRef.current.disabled = false;
                // }
                messageRef.current.textContent = "PDF Güncelleme başarılı"
                messageRef.current.style.color = 'green';
                buttonRef.current.disabled = false;
                // setPdfUploadLoader(true)
                // uploadRef.current.innerHTML = 'File(s) Uploaded Successfully';
                //progressRef.current.style.backgroundColor = 'green';
                sessionStorage.setItem("pdfUrl", response.data.responseData.imageUrl)

            }
        })
            .catch((error) => {

                // Handle any other errors (e.g., network errors)
                console.error('Error uploading files:', error);
                toast.error("Pdf dosyası yüklenemedi ");
                if (context == "mutualLectures" && addMutualLectures) {
                    messageRef.current.textContent = "Pdf dosyası yüklenemedi"
                    messageRef.current.style.color = 'red';
                    buttonRef.current.disabled = true;
                } else if (context == "normalLectures" && addLectureModal) {
                    messageRef.current.textContent = "Pdf dosyası yüklenemedi"
                    messageRef.current.style.color = 'red';
                    buttonRef.current.disabled = true;
                } else if (context == "Update" && updateLectureModal) {
                    messageRef.current.textContent = "Pdf dosyası yüklenemedi"
                    messageRef.current.style.color = 'red';
                    buttonRef.current.disabled = true;
                }

            });
    }

    //-------------------------------pdf file rename End


    //------------------------------pdf upload to content managment start


    const uploadPDFFiles = async (selectedFile, context) => {
        buttonRef.current.disabled = true;
        //const customFileName = titleRef.current.value+`${topicNameRef.current.value?"_"+topicNameRef.current.value:""}`+`${subTopicNameRef.current.value?"_"+subTopicNameRef.current.value:""}`
        let customFileName;
        if (context == "mutualLectures") {
            customFileName = titleRef.current.value;

            if (topicNameRef.current.value) {
                customFileName += '_' + topicNameRef.current.value;

                if (MutualSubTopicNameRef.current.value) {
                    customFileName += '_' + MutualSubTopicNameRef.current.value;

                }

            }
        } else if (context == "normalLectures") {
            console.log("selectedExamCategory", selectedExamCategory)
            let SourceTypeName;
            let CourseName;
            let SubCourseName;
            SourceTypeName = nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).SourceTypeName
            CourseName = nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).Courses.find((course) => course.Id == selectedCourse).CourseName;
            SubCourseName = nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).Courses.find((course) => course.Id == selectedCourse).CourseTopics.find((subCourse) => subCourse.CourseTopicUniqueId == selectedSubCourse).SubCourseTopics.find((newSubCourse) => newSubCourse.SubCourseTopicUniqueId == selectedSubCourseTopics).NewSubCourseTopicName
            //nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).Courses.find((course) => course.Id == selectedCourse).CourseTopics.find((subCourse) => subCourse.CourseTopicUniqueId == selectedSubCourse).SubCourseTopics.find((newSubCourse)=>newSubCourse.SubCourseTopicUniqueId==selectedSubCourseTopics).NewSubCourseTopicName
            customFileName = SourceTypeName + "_" + CourseName + "_" + SubCourseName
            if (NormalSubTopicNameRef.current.value) {
                customFileName += '_' + NormalSubTopicNameRef.current.value;

            }
        } else {
            let selectedSubCourseName =selectedLecture.SubTopicName&& selectedLecture.SubTopicName.includes("/") ? selectedLecture.SubTopicName.split('/')[1].trim() : ""
            let selectedSubTopicName = selectedLecture.SubTopicName&&selectedLecture.SubTopicName.includes("/") ? selectedLecture.SubTopicName.split('/')[0].trim() : selectedLecture.SubTopicName
            let SourceTypeName;
            let Title;
            let SubCourseName;
            let subTitleName;
            SourceTypeName = nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedLecture.ExamCategoryId).SourceTypeName
            SubCourseName = UpdateSubTopicNameRef.current !== selectedSubCourseName ? UpdateSubTopicNameRef.current.value : selectedLecture.SubTopicName
            if (selectedLecture.SubCourseTopicUniqueId == 0) {
                Title = updateTitleRef.current !== selectedLecture.Title ? updateTitleRef.current.value : selectedLecture.Title
                subTitleName = updateTopicRef.current !== selectedSubTopicName ? updateTopicRef.current.value : selectedSubTopicName

                customFileName = SourceTypeName + `${Title ? "_" + Title : ""}` + `${subTitleName ? "_" + subTitleName : ""}` + `${SubCourseName ? "_" + SubCourseName : ""}`
            } else {
                customFileName = SourceTypeName + `${selectedLecture.TopicName ? "_" + selectedLecture.TopicName : ""}` + `${selectedSubTopicName ? "_" + selectedSubTopicName : ""}` + `${SubCourseName ? "_" + SubCourseName : ""}`

            }
            //SubCourseName =  nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).Courses.find((course) => course.Id == selectedCourse).CourseTopics.find((subCourse) => subCourse.CourseTopicUniqueId == selectedSubCourse).SubCourseTopics.find((newSubCourse)=>newSubCourse.SubCourseTopicUniqueId==selectedLecture.SubCourseTopicUniqueId).NewSubCourseTopicName
            // customFileName = SourceTypeName + `${Title?"_":""}` + Title + `${subTitleName?"_":""}` + subTitleName + `${SubCourseName?"_":""}` + SubCourseName
            // if (UpdateSubTopicNameRef.current.value !== selectedLecture.SubTopicName) {
            //     customFileName += '_' + UpdateSubTopicNameRef.current.value;

            // }
        }

        const formDataPDf = new FormData();
        formDataPDf.append('Folder', "onlinelectures");
        formDataPDf.append("FormFile", selectedFile);
        formDataPDf.append("FileName", customFileName);




        const headers = {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + userAdminToken,
            "Content-Type": "multipart/form-data",
        };
        Axios.post('https://contentprocess.tosanalytics.com/api/Mediafile/uploads', formDataPDf, {
            headers: headers,

        }).then((response) => {
            console.log("response status", response)
            if (response.data.responseStatus === 0) {
                // Handle error here (response.data.responseMessage contains the error message)
                console.error('Error uploading files:', response.data.responseMessage);
                toast.error(response.data.responseMessage)
                messageRef.current.textContent = response.data.responseMessage
                messageRef.current.style.color = 'red';
                buttonRef.current.disabled = true;
                //uploadRef.current.innerHTML = `<span class="error">Error Uploading File(s): ${response.data.responseMessage}</span>`;
                //progressRef.current.style.backgroundColor = 'red';
            } else {
                // Handle successful upload here (if needed)
                console.log('Upload successful', response);
                toast.success("PDF yükleme başarılı")
                messageRef.current.textContent = "PDF yükleme başarılı"
                messageRef.current.style.color = 'green';
                buttonRef.current.disabled = false;
                // setPdfUploadLoader(true)
                // uploadRef.current.innerHTML = 'File(s) Uploaded Successfully';
                //progressRef.current.style.backgroundColor = 'green';
                sessionStorage.setItem("pdfUrl", response.data.responseData[0].imageUrl)
            }
        })
            .catch((error) => {

                // Handle any other errors (e.g., network errors)
                console.error('Error uploading files:', error);
                toast.error("Pdf dosyası yüklenemedi ");
                messageRef.current.textContent = "Pdf dosyası yüklenemedi"
                messageRef.current.style.color = 'red';
                buttonRef.current.disabled = true;
            });


    }




    useEffect(() => {
        if (lecturePdfFile) {

            uploadPDFFiles(lecturePdfFile)
        }
    }, [lecturePdfFile])
    //------------------------------pdf upload to content managment end
    const handleAddLectureSubmit = async (event) => {
        event.preventDefault();
        console.log("isFile", selectedSubCourseTopics)
        console.log("selectedCourse", selectedCourse)
        const form = event.target;
        console.log("formElements", form)
        const formData = new FormData(form);
        //formData.append("ThumbnailUrl",thumbnailFile)
        const formJson = Object.fromEntries(formData.entries());
        console.log("selectedFileThubnail", thumbnailFile)
        //--------------------------------------------
        formData.delete("time-input")
        formData.delete("ThumbnailUrl")
        formData.delete("LectureNoteUrl")
        formData.delete("LectureNoteUrlCheck")
        formData.append("SubCourseTopicUniqueId", Number(selectedSubCourseTopics))
        formData.append("ExamCategoryId", Number(selectedExamTypeId))
        formData.append("OnlineLectureTeacherId", Number(selectedTeacher))
        formData.append("CourseId", Number(selectedCourse))
        formData.append("ExamType", Number(selectedExamCategory))
        formData.append("ChannelCode", "Web")
        formData.append("Date", dateChange(startDate))
        formData.append("ThumbnailUrl", thumbnailFile)
        formData.append("LectureNoteUrl", sessionStorage.getItem("pdfUrl"))
        formData.append("RowStatus", true)
        formData.append("IsPublic", islecturePublic)
        formData.append("OfflineUrlType", selectedUrlTypeValue)
        //--------------------------------------------
        // formJson["SubCourseTopicUniqueId"] = Number(selectedSubCourseTopics)
        // delete formJson["time-input"];
        // //formJson["ThumbnailUrl"] = thumbnailFile
        // formJson["ExamCategoryId"] = Number(selectedExamTypeId)
        // formJson["OnlineLectureTeacherId"] = Number(selectedTeacher)
        // formJson["CourseId"] = Number(selectedCourse)
        // formJson["ExamType"] = Number(selectedExamCategory)
        // //formJson["ThumbnailUrl"] = thumbnailFile
        // formJson["ChannelCode"] = "Web"
        // formData.append("thumbnailFile", thumbnailFile);
        // // Add a new key "Date" with the value from startDate
        // formJson["Date"] = dateChange(startDate);
        console.log("added lecture", formJson)
        //addOnlineLecture(formJson)
        if (!sessionStorage.getItem("pdfUrl")) {
            toast.error("pdf eklenmeden Ders ekleyemezsiniz")
            return
        }
        if (messageRef.current.textContent !== "PDF yükleme başarılı") {
            toast.error("pdf eklenmeden Ders ekleyemezsiniz")
            return
        }
        if (!thumbnailPreview) {
            toast.error("Thumbnail eklenmeden Ders ekleyemezsiniz")
            return
        }
        const headers = {
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + userAdminToken,
            "Content-Type": "multipart/form-data",
        };
        //formData.append("ThumbnailUrl", thumbnailFile);
        try {
            const res = await Axios.post(`https://api.pakodemy.com/api/OnlineLecture/${addMutualLectures ? "addGeneralLecture" : "addLecture"}`, formData, {
                headers: headers,
            });
            if (res.status == 200) {
                console.log("res==200")
                if (res.data.ResponseStatus == ResponseStatus.Success) {
                    console.log("resStat==1")
                    toast.success("Ders başarıyla eklenmiştir")
                    getLectures()
                } else if (res.data.ResponseStatus == ResponseStatus.Error) {
                    console.log("res==0")
                    toast.error(res.data.ResponseMessage)
                    toast.error(`${res.data.ResponseMessage}`)
                }
            } else {
                console.log("res == error")

                toast.error("bir hata ile karşılaşıldı lütfen tekrar deneyiniz")
            }
        } catch (ex) {
            console.log(ex);
            toast.error(ex.response.data.Error)
        }
        setSelectedCourse(null)
        setSelectedExamCategory(null)
        setSelectedExamTypeId(null)
        setSelectedSubCourse(null)
        setSelectedSubCourseTopics(null)
        setThumbnailFile(null)
        setLecturePdfFile(null)
        setPdfUploadLoader(false)
        setThumbnailPreview(null)
        setAddLectureModal(false)
        setUpdateHomeWorkModal(false)
        setAddHomeWorkModal(false);
        setSelectedLecture(null);
        setSelectedHomework(null)
        setThumbnailPreview(null)
        setAddMutualLectures(false)
        sessionStorage.removeItem("pdfUrl")
        setIslecturePublic(false)
    }

    useEffect(() => {

        if (lectureNoteUrlRef.current && lectureNoteUrlRef.current.files[0]) {

            const selectedFile = lectureNoteUrlRef.current.files[0];
            console.log(selectedFile);
            uploadPDFFiles(selectedFile, "mutualLectures")
        }
    }, [titleRef, topicNameRef, MutualSubTopicNameRef.current])
    useEffect(() => {

        if (lectureNoteUrlRef.current && lectureNoteUrlRef.current.files[0]) {
            console.log("lectureNoteUrlRef.current.files[0]", NormalSubTopicNameRef.current)
            const selectedFile = lectureNoteUrlRef.current.files[0];
            console.log(selectedFile);
            uploadPDFFiles(selectedFile, "normalLectures")
        }
    }, [NormalSubTopicNameRef, selectedExamCategory, selectedCourse, selectedSubCourseTopics])


    return (
        <div>
            <SideBar />
            <ToastContainer />
            <div style={{ ...styles.container }}>
                <button onClick={() => setCacheReqModal(true)} style={{ "right": 0, bottom: 0, position: "fixed", margin: 10, width: 75, backgroundColor: "#25d366" }}>Sisteme Yükle</button>
                {updateLectureModal && (
                    <Dialog
                        open={updateLectureModal}
                        onClose={() => {
                            sessionStorage.removeItem("pdfUrl")
                            setUpdateLectureModal(false)
                            setIslecturePublic(false)
                        }

                        }
                    >
                        <div style={styles.ChangeDateModalStyle}>

                            <div style={{ "margin": "10px" }} >
                                <div>
                                    {selectedLecture.CourseName ? selectedLecture.CourseName : null}
                                </div>
                                <div>
                                    {selectedLecture.Date}
                                </div>
                                <div>
                                    {selectedLecture.TopicName ? selectedLecture.TopicName : null}
                                </div>
                                <div>
                                    {selectedLecture.SubTopicName ? selectedLecture.SubTopicName : null}
                                </div>
                                <form onSubmit={handleLectureUpdate}>
                                    {selectedLecture.SubCourseTopicUniqueId == 0 && (
                                        <div>
                                            <div>
                                                <label htmlFor="Title">Ana Başlık Giriniz: </label>
                                                <input defaultValue={selectedLecture.Title} ref={updateTitleRef} required id="Title" name="Title" type="text" onChange={handleUpdateTitle} />
                                            </div>
                                            <div>
                                                <label htmlFor="TopicName">Başlık Giriniz: </label>
                                                <input defaultValue={selectedLecture.TopicName} ref={updateTopicRef} required id="TopicName" name="TopicName" type="text" onChange={handleUpdateTopic} />
                                            </div>
                                        </div>
                                    )}
                                    <div>
                                        <label htmlFor="SubTopicName">Alt Başlık Giriniz(part1,part2): </label>
                                        <input defaultValue={selectedLecture.SubTopicName&&selectedLecture.SubTopicName.includes("/") ? selectedLecture.SubTopicName.split('/')[1].trim() : selectedLecture.SubCourseTopicUniqueId == 0 ? selectedLecture.SubTopicName : ""} ref={UpdateSubTopicNameRef} id="SubTopicName" name="SubTopicName" type="text" onChange={handleUpdateSubTopic} />
                                    </div>
                                    <div>
                                        <input type="checkbox" ref={publicLectureRef} checked={islecturePublic}  name="PublicStream" id="PublicStream" onChange={handlePublicLectureCheck}></input>
                                        <lable>Herkese açık yayın </lable>
                                    </div>
                                    <div>
                                        <label htmlFor="UrlInput">Castr Url Giriniz: </label>
                                        <input defaultValue={selectedLecture.StreamUrl}  name="StreamUrl" type="text" id="UrlInput" />
                                    </div>
                                    <div>
                                        <label htmlFor="OfflineUrlInput">YouTube Url Giriniz: </label>
                                        <input defaultValue={selectedLecture.OfflineUrl}  name="OfflineUrl" type="text" id="OfflineUrlInput" />
                                    </div>
                                    {<img style={{ "height": "120px", "width": "150px" }} src={selectedLecture.ThumbnailUrl}></img>}
                                    <div>
                                        <label htmlFor="ThumbnailUrl">Thumbnail dosyasını seçiniz: </label>
                                        <input
                                            //required 
                                            accept="image/png"
                                            type="file"
                                            name="ThumbnailUrl"
                                            id="ThumbnailUrl"
                                            onChange={thumbnailUpload}
                                        ></input>
                                        <div>
                                            {thumbnailPreview && <img style={{ "height": "120px", "width": "150px" }} src={thumbnailPreview} />}
                                            {/* {thumbnailPreview && console.log("thumbnail", selectedThumbnail.name)} */}
                                        </div>
                                    </div>
                                    <a href={selectedLecture.LectureNoteUrl ? selectedLecture.LectureNoteUrl : null} target="_blank">
                                        <div style={{ width: "150px", wordWrap: "break-word" }}>

                                            {selectedLecture.LectureNoteUrl ? selectedLecture.LectureNoteUrl : null}
                                        </div>
                                    </a>
                                    <div>
                                        <label>Ders notu dosyasını seçiniz(Slayt): </label>
                                        <input type="file" name="LectureNoteUrl" id="LectureNoteUrl" accept="application/pdf" ref={lectureNoteUrlRef} onChange={handleUpdateLecturePdfRef}></input>
                                    </div>
                                    {<div >
                                        <div ref={messageRef}></div>
                                    </div>}
                                    <div>
                                        <input type="checkbox" ref={checkLectureRef} required name="LectureNoteUrlCheck" id="LectureNoteUrlCheck" onChange={handleCheckLectureNote}></input>
                                        <lable>Ders notları kontrol edilmiştir. </lable>
                                    </div>
                                    <div style={{ "margin": "10px" }}>

                                        <button
                                            ref={buttonRef}
                                            type="submit"
                                            //style={{ backgroundColor: "green", color: "white", fontSize: "20px" }}
                                            onClick={async () => {
                                                // await renameFile(sessionStorage.getItem("pdfUrl"),"Update")

                                                //setUpdateLectureModal(false)
                                            }
                                            }
                                        // style={styles.deleteConfirmClose}
                                        >
                                            Güncelle
                                        </button>
                                        <button
                                            //style={{ backgroundColor: "red", color: "white", fontSize: "20px" }}
                                            onClick={() => {
                                                // dateChange(startDate)
                                                // setDisplayDateChange(false);
                                                // setDateChangeConfirmation(true)
                                                sessionStorage.removeItem("pdfUrl")
                                                setUpdateLectureModal(false)
                                                setThumbnailFile(null)
                                                setThumbnailPreview(null)
                                                setIslecturePublic(false)
                                            }}
                                        // style={styles.deleteConfirm}
                                        >
                                            Vazgeç
                                        </button>
                                    </div>

                                </form>


                            </div>


                        </div>
                    </Dialog>
                )}
                {cacheReqModal && (
                    <Dialog
                        open={cacheReqModal}
                        onClose={() => setCacheReqModal(false)}
                    >
                        <div style={styles.ChangeDateModalStyle}>

                            <h1 style={{ textAlign: 'center' }}>Değişiklikleriniz sisteme kaydedilecektir onaylıyormusunuz?</h1>
                            <div style={{ display: 'flex', justifyContent: 'center', gap: "10px" }}>
                                <button
                                    style={{ backgroundColor: "red", color: "white", fontSize: "20px" }}
                                    onClick={() => {
                                        // dateChange(startDate)
                                        // setDisplayDateChange(false);
                                        // setDateChangeConfirmation(true)
                                        setCacheReqModal(false)
                                    }}
                                // style={styles.deleteConfirm}
                                >
                                    HAYIR
                                </button>
                                <button
                                    style={{ backgroundColor: "green", color: "white", fontSize: "20px" }}
                                    onClick={() => {
                                        clearCache();
                                        setCacheReqModal(false)
                                    }
                                    }
                                // style={styles.deleteConfirmClose}
                                >
                                    EVET
                                </button>
                            </div>

                        </div>
                    </Dialog>)}
                {
                    addMutualLectures && (
                        <Dialog
                            open={addMutualLectures}
                            onClose={() => {

                                setAddMutualLectures(false);
                                setSelectedCourse("");
                                setSelectedExamCategory("");
                                setSelectedSubCourse("");
                                setSelectedExamTypeId("")
                                setSelectedSubCourseTopics("")
                                setThumbnailFile(null)
                                setLecturePdfFile(null)
                                setPdfUploadLoader(false)
                                setThumbnailPreview(null)
                                setAddLectureModal(false)
                                setUpdateHomeWorkModal(false)
                                setAddHomeWorkModal(false);
                                setSelectedLecture(null);
                                setSelectedHomework(null)
                                setThumbnailPreview(null)
                                setSelectedTeacher(null)
                                sessionStorage.removeItem("pdfUrl")
                            }}
                        >
                            <div style={styles.ChangeDateModalStyle}>

                                <div>
                                    <form method="post" onSubmit={handleAddLectureSubmit}>
                                        <div>

                                            <label>
                                                <input type="radio" name="ExamCategoryId" value="1" checked={selectedExamTypeId == "1"} onChange={handleExamTypeCheckbox} />
                                                YKS
                                            </label>
                                            <label>
                                                <input type="radio" name="ExamCategoryId" value="2" checked={selectedExamTypeId == "2"} onChange={handleExamTypeCheckbox} />
                                                LGS
                                            </label>
                                        </div>
                                        {selectedExamTypeId && <div>

                                            <label htmlFor="ExamCategoryoptions">Lütfen sınav türünü seçiniz: </label>
                                            <select id="ExamCategoryoptions" onChange={handleExamCategoryOptionChange} value={selectedExamCategory}>
                                                {/* <option value="">Ödev Türü Seçiniz</option>
                                            <option value="PDF">PDF</option>
                                            <option value="Serbest Soru Çözümü">Serbest Soru Çözümü</option>
                                            <option value="Öğretmen Videosu" disabled>Öğretmen Videosu</option>
                                            <option value="Ders Videosu" disabled>Ders Videosu</option>
                                        <option value="Ders Konu Kartı" disabled>Ders Konu Kartı</option> */}
                                                <option value="">Sınav türünü seçiniz</option>
                                                {/* {nestedTitleLecture.map((lecture) => {
                                                    if ((selectedExamTypeId == 1 && (lecture.SourceTypeId == 1 || lecture.SourceTypeId == 2)) || (selectedExamTypeId == 2 && lecture.SourceTypeId == 3)) {
                                                        return (
                                                            <option key={lecture.SourceTypeId} value={lecture.SourceTypeId}>{lecture.SourceTypeName}</option>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })} */}
                                                {selectedExamTypeId == 1 ?
                                                    <option value={99}>TYT-AYT</option> :
                                                    <option value={3}>LGS</option>}
                                                {console.log(nestedTitleLecture)}

                                            </select>
                                        </div>}

                                        {selectedExamCategory && (
                                            <div>
                                                <div>
                                                    <label htmlFor="LectureTeacher">Öğretmen Seçiniz: </label>
                                                    <select id="LectureTeacher" onChange={handleTeacherChange} value={selectedTeacher}>
                                                        <option value="">Öğretmen Seçiniz: </option>
                                                        {teacherList.map((teacher) => {
                                                            return (
                                                                <option value={teacher.Id}>

                                                                    {teacher.TeacherName}

                                                                </option>
                                                            )
                                                        })}
                                                        <option value={0}>Pakodemy Hoca(birden çok Hoca varsa)</option>
                                                    </select>
                                                </div>
                                                {selectedTeacher && <div style={styles.ChangeDateModalStyle}>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => setStartDate(date)}
                                                        timeInputLabel="Time:"
                                                        dateFormat="MM/dd/yyyy h:mm aa"
                                                        showTimeInput
                                                        inline
                                                    />
                                                </div>}
                                                {startDate && selectedTeacher && <div>
                                                    <label htmlFor="ThumbnailUrl">Thumbnail dosyasını seçiniz: </label>
                                                    <input type="file" name="ThumbnailUrl" id="ThumbnailUrl" accept="image/png" onChange={thumbnailUpload}></input>
                                                    <div>
                                                        {thumbnailPreview && <img style={{ "height": "120px", "width": "150px" }} src={thumbnailPreview} />}

                                                    </div>

                                                </div>}
                                                {thumbnailPreview && <div>
                                                    <div>
                                                        <input type="checkbox" ref={publicLectureRef} checked={islecturePublic}   name="PublicStream" id="PublicStream" onChange={handlePublicLectureCheck}></input>
                                                        <lable>Herkese açık yayın </lable>
                                                    </div>
                                                    <select value={selectedUrlTypeValue} onChange={handleChange}>
      
                                                        {options}
                                                    </select>
                                                    <div>
                                                        <label htmlFor="Title">Ana Başlık Giriniz: </label>
                                                        <input required id="Title" name="Title" type="text" ref={titleRef} onChange={handleTitleChange} />
                                                        {/* <Input2  required={true} name="Title"  value={title} setValue={setTitle} /> */}
                                                    </div>
                                                    <div>
                                                        <label htmlFor="TopicName">Başlık Giriniz: </label>
                                                        <input required id="TopicName" name="TopicName" type="text" ref={topicNameRef} onChange={handleTopicNameChange} />
                                                        {/* <Input2  required={true} name="TopicName"  value={topicName} setValue={setTopicName} /> */}
                                                    </div>
                                                    <div>
                                                        <label htmlFor="SubTopicName">Alt Başlık Giriniz(part1,part2): </label>
                                                        <input id="SubTopicName" name="SubTopicName" type="text" ref={MutualSubTopicNameRef} onChange={handleTopicNameChange} />
                                                        {/* <Input2   name="Title"  value={subTopicName} setValue={setSubTopicName} /> */}
                                                    </div>
                                                    <div>
                                                        <label htmlFor="UrlInput">Castr Url Giriniz: </label>
                                                        <input  name="StreamUrl" type="text" id="UrlInput" ref={urlRef} onChange={handleUrlChange} />
                                                        {/* <Input2  required={true} name="Url"  value={url} setValue={setUrl} /> */}
                                                    </div>
                                                    <div>
                                                        <label htmlFor="OfflineUrlInput">YouTube Url Giriniz: </label>
                                                        <input required name="OfflineUrl" type="text" id="OfflineUrlInput" ref={urlRef} onChange={handleUrlChange} />
                                                        {/* <Input2  required={true} name="Url"  value={url} setValue={setUrl} /> */}
                                                    </div>
                                                    <div>
                                                        <label>Ders notu dosyasını seçiniz(Slayt): </label>
                                                        <input type="file" name="LectureNoteUrl" id="LectureNoteUrl" accept="application/pdf" ref={lectureNoteUrlRef} onChange={handleMutualLecturePdfRef}></input>
                                                    </div>
                                                    {<div >
                                                        <div ref={messageRef}></div>
                                                    </div>}
                                                    <div>
                                                        <input type="checkbox" ref={checkLectureRef} required name="LectureNoteUrlCheck" id="LectureNoteUrlCheck" onChange={handleCheckLectureNote}></input>
                                                        <lable>Ders notları kontrol edilmiştir. </lable>
                                                    </div>
                                                    {/* <Input2  required={true} name="SubTopicName"  value={email} setValue={setEmail} /> */}
                                                </div>}

                                            </div>
                                        )}
                                        <div style={{ "marginTop": 5 }}>
                                            <button
                                                ref={buttonRef}
                                                type="submit"
                                                onClick={() => {

                                                    // setAddHomeWorkModal(false);
                                                    // setSelectedLecture(null);
                                                }}
                                                style={{ marginRight: 10 }}
                                            // style={styles.deleteConfirm}
                                            >
                                                Ders Ekle
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setAddMutualLectures(false);
                                                    setSelectedCourse("");
                                                    setSelectedExamCategory("");
                                                    setSelectedSubCourse("");
                                                    setSelectedExamTypeId("")
                                                    setSelectedSubCourseTopics("")
                                                    setSelectedTeacher(null)
                                                    setSelectedThumbnail(null)
                                                    setThumbnailFile(null)
                                                    setLecturePdfFile(null)
                                                    setPdfUploadLoader(false)
                                                    setThumbnailPreview(null)
                                                    setAddLectureModal(false)
                                                    setUpdateHomeWorkModal(false)
                                                    setAddHomeWorkModal(false);
                                                    setSelectedLecture(null);
                                                    setSelectedHomework(null)
                                                    setThumbnailPreview(null)
                                                    sessionStorage.removeItem("pdfUrl")
                                                }}
                                            // style={styles.deleteConfirmClose}
                                            >
                                                Vazgeç
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </Dialog>
                    )
                }
                {
                    addLectureModal && (
                        <Dialog
                            open={addLectureModal}
                            onClose={() => {

                                setAddLectureModal(false);
                                setSelectedCourse("");
                                setSelectedExamCategory("");
                                setSelectedSubCourse("");
                                setSelectedExamTypeId("")
                                setSelectedSubCourseTopics("")
                                setThumbnailFile(null)
                                setLecturePdfFile(null)
                                setPdfUploadLoader(false)
                                setThumbnailPreview(null)
                                setAddLectureModal(false)
                                setUpdateHomeWorkModal(false)
                                setAddHomeWorkModal(false);
                                setSelectedLecture(null);
                                setSelectedHomework(null)
                                setThumbnailPreview(null)
                                setSelectedTeacher(null)
                                setIslecturePublic(false)
                                sessionStorage.removeItem("pdfUrl")
                            }}
                        >
                            <div style={styles.ChangeDateModalStyle}>
                                {console.log("selectedExamTypeId", selectedExamTypeId)}
                                <div>
                                    <form method="post" onSubmit={handleAddLectureSubmit}>
                                        <div>

                                            <label>
                                                <input type="radio" name="ExamCategoryId" value="1" checked={selectedExamTypeId == "1"} onChange={handleExamTypeCheckbox} />
                                                YKS
                                            </label>
                                            <label>
                                                <input type="radio" name="ExamCategoryId" value="2" checked={selectedExamTypeId == "2"} onChange={handleExamTypeCheckbox} />
                                                LGS
                                            </label>
                                        </div>
                                        {selectedExamTypeId && <div>

                                            <label htmlFor="ExamCategoryoptions">Lütfen sınav türünü seçiniz: </label>
                                            <select id="ExamCategoryoptions" onChange={handleExamCategoryOptionChange} value={selectedExamCategory}>
                                                {/* <option value="">Ödev Türü Seçiniz</option>
                                            <option value="PDF">PDF</option>
                                            <option value="Serbest Soru Çözümü">Serbest Soru Çözümü</option>
                                            <option value="Öğretmen Videosu" disabled>Öğretmen Videosu</option>
                                            <option value="Ders Videosu" disabled>Ders Videosu</option>
                                        <option value="Ders Konu Kartı" disabled>Ders Konu Kartı</option> */}
                                                <option value="">Sınav türünü seçiniz</option>
                                                {nestedTitleLecture.map((lecture) => {
                                                    if ((selectedExamTypeId == 1 && (lecture.SourceTypeId == 1 || lecture.SourceTypeId == 2)) || (selectedExamTypeId == 2 && lecture.SourceTypeId == 3)) {
                                                        return (
                                                            <option key={lecture.SourceTypeId} value={lecture.SourceTypeId}>{lecture.SourceTypeName}</option>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })}
                                                {console.log(nestedTitleLecture)}
                                            </select>
                                        </div>}
                                        {selectedExamCategory && (
                                            <div>
                                                <label htmlFor="Courseoptions">Lütfen ders seçiniz: </label>
                                                <select id="Courseoptions" onChange={handleCourseOptionChange} value={selectedCourse}>
                                                    <option value=""> Ders Seçiniz:</option>
                                                    {/* {nestedTitleLecture[Number(selectedExamCategory)].Courses.map((courses)=>{
                                                        return(
                                                            <option value={courses.Id}>{courses.CourseName}</option>
                                                        )
                                                    })} */}
                                                    {nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).Courses.map((courses) => {
                                                        return (
                                                            <option value={courses.Id}>{courses.CourseName}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        )}
                                        {selectedExamCategory && selectedCourse && (
                                            <div>
                                                <label htmlFor="SubCourseOptions">Lütfen Konu seçiniz: </label>
                                                <select id="SubCourseOptions" onChange={handleSubCourseChange} value={selectedSubCourse}>
                                                    <option value="">Konu Seçiniz:</option>
                                                    {nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).Courses.find((course) => course.Id == selectedCourse).CourseTopics.map((courseTopics) => {
                                                        return (
                                                            <option value={courseTopics.CourseTopicUniqueId}>{courseTopics.NewCourseTopicName}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        )}
                                        {selectedExamCategory && selectedCourse && selectedSubCourse && (
                                            <div>
                                                <label htmlFor="SubCourseTopicsOptions">Lütfen konu Alt Başlığı Seçiniz: </label>
                                                <select id="SubCourseTopicsOptions" onChange={handleSubCourseTopicsChange} value={selectedSubCourseTopics}>
                                                    <option value="">Konu Alt başlığı Seçiniz: </option>
                                                    {nestedTitleLecture.find((sourceType) => sourceType.SourceTypeId == selectedExamCategory).Courses.find((course) => course.Id == selectedCourse).CourseTopics.find((subCourse) => subCourse.CourseTopicUniqueId == selectedSubCourse).SubCourseTopics.map((subCourseTopics) => {
                                                        return (
                                                            <option value={subCourseTopics.SubCourseTopicUniqueId}>{subCourseTopics.NewSubCourseTopicName}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        )}
                                        {selectedCourse && selectedExamCategory && selectedSubCourse && selectedSubCourseTopics && (
                                            <div>
                                                <div style={{ margin: 10 }}>
                                                    <label htmlFor="LectureTeacher">Öğretmen Seçiniz: </label>
                                                    <select id="LectureTeacher" onChange={handleTeacherChange} value={selectedTeacher}>
                                                        <option value="">Öğretmen Seçiniz: </option>
                                                        {teacherList.map((teacher) => {
                                                            return (
                                                                <option value={teacher.Id}>

                                                                    {teacher.TeacherName}

                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                {selectedTeacher && <div style={styles.ChangeDateModalStyle}>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => setStartDate(date)}
                                                        timeInputLabel="Time:"
                                                        dateFormat="MM/dd/yyyy h:mm aa"
                                                        showTimeInput
                                                        inline
                                                    />
                                                </div>}
                                                <div>
                                                    <label htmlFor="ThumbnailUrl">Thumbnail dosyasını seçiniz: </label>
                                                    <input
                                                        //required 
                                                        accept="image/png"
                                                        type="file"
                                                        name="ThumbnailUrl"
                                                        id="ThumbnailUrl"
                                                        onChange={thumbnailUpload}
                                                    ></input>
                                                    <div>
                                                        {thumbnailPreview && <img style={{ "height": "120px", "width": "150px" }} src={thumbnailPreview} />}
                                                        {/* {thumbnailPreview && console.log("thumbnail", selectedThumbnail.name)} */}
                                                    </div>
                                                </div>
                                                {thumbnailPreview && <div>
                                                    <div>
                                                        <input type="checkbox" ref={publicLectureRef} checked={islecturePublic}  name="PublicStream" id="PublicStream" onChange={handlePublicLectureCheck}></input>
                                                        <lable>Herkese açık yayın </lable>
                                                    </div>
                                                    <select value={selectedUrlTypeValue} onChange={handleChange}>
      
                                                        {options}
                                                    </select>
                                                    <div>
                                                        <label htmlFor="SubTopicName">Alt Başlık Giriniz(part1,part2): </label>
                                                        <input id="SubTopicName" name="SubTopicName" type="text" ref={NormalSubTopicNameRef} onChange={handleSubTopicNameChange} />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="UrlInput">Castr Url Giriniz: </label>
                                                        <input  name="StreamUrl" type="text" id="UrlInput"  />
                                                        {/* <Input2  required={true} name="Url"  value={url} setValue={setUrl} /> */}
                                                    </div>
                                                    <div>
                                                        <label htmlFor="OfflineUrlInput">YouTube Url Giriniz: </label>
                                                        <input required name="OfflineUrl" type="text" id="OfflineUrlInput"></input>
                                                    </div>
                                                    <div>
                                                        <label>Ders notu dosyasını seçiniz(Slayt): </label>
                                                        <input type="file" name="LectureNoteUrl" id="LectureNoteUrl" accept="application/pdf" ref={lectureNoteUrlRef} onChange={handleLecturePdfRef}></input>
                                                    </div>
                                                    {<div >

                                                        <div ref={messageRef}></div>
                                                    </div>}
                                                   
                                                    <div>
                                                        <input type="checkbox" ref={checkLectureRef} required name="LectureNoteUrlCheck" id="LectureNoteUrlCheck" onChange={handleCheckLectureNote}></input>
                                                        <lable>Ders notları kontrol edilmiştir. </lable>
                                                    </div>


                                                </div>}
                                            </div>
                                        )}
                                        {/* {selectedExamCategory == "Pdf" && (
                                            <div>
                                                <label htmlFor="pdfInput">PDF Kodu Giriniz:</label>
                                                <input name="PrintableTestCode" type="text" id="pdfInput" />
                                            </div>
                                        )}
                                        {selectedHomeworkType === "Serbest Soru Çözümü" && (
                                            <div>
                                                <label htmlFor="SerbestSoruSayısı">Serbest Soru Sayısını Giriniz:</label>
                                                <input type="number" max="120" min="1" id="SerbestSoruSayısı" onChange={handleSoruSayısıInputChange} name="QuestionCount" />
                                            </div>
                                        )}
                                        {selectedHomeworkType && (
                                            <div>
                                                <label htmlFor="title">Başlık:</label>
                                                <input type="text" id="title" name="Title" />

                                                <label htmlFor="description">Açıklama:</label>
                                                <input type="text" id="description" name="Description" />
                                                <label htmlFor="order">Ödev sıralaması:</label>
                                                <input type="number" id="order" name="OrderId" />
                                            </div>)} */}
                                        <div style={{ "marginTop": 5 }}>
                                            <button
                                                ref={buttonRef}
                                                type="submit"
                                                onClick={() => {

                                                    // setAddHomeWorkModal(false);
                                                    // setSelectedLecture(null);
                                                }}
                                                style={{ marginRight: 10 }}
                                            // style={styles.deleteConfirm}
                                            >
                                                Ders Ekle
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setAddLectureModal(false);
                                                    setSelectedCourse("");
                                                    setSelectedExamCategory("");
                                                    setSelectedSubCourse("");
                                                    setSelectedExamTypeId("")
                                                    setSelectedSubCourseTopics("")
                                                    setThumbnailFile(null)
                                                    setLecturePdfFile(null)
                                                    setPdfUploadLoader(false)
                                                    setThumbnailPreview(null)
                                                    setAddLectureModal(false)
                                                    setUpdateHomeWorkModal(false)
                                                    setAddHomeWorkModal(false);
                                                    setSelectedLecture(null);
                                                    setSelectedHomework(null)
                                                    setThumbnailPreview(null)
                                                    setSelectedTeacher(null)
                                                    sessionStorage.removeItem("pdfUrl")
                                                }}
                                            // style={styles.deleteConfirmClose}
                                            >
                                                Vazgeç
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </Dialog>
                    )
                }
                {
                    addHomeWorkModal && (
                        <Dialog
                            open={addHomeWorkModal}
                            onClose={() => setAddHomeWorkModal(false)}
                        >
                            <div style={styles.ChangeDateModalStyle}>
                                {<img style={{ "height": "120px", "width": "150px" }} src={selectedLecture.ThumbnailUrl}></img>}
                                <p>{selectedLecture.SubTopicName}</p>
                                <div>
                                    <form method="post" onSubmit={handleHomeworkSubmit}>

                                        <label htmlFor="options">Lütfen Ödev Türü Seçiniz:</label>
                                        <select id="options" onChange={handleOptionChange} value={selectedHomeworkType}>
                                            <option value="">Ödev Türü Seçiniz</option>
                                            <option value="PDF">PDF</option>
                                            <option value="Serbest Soru Çözümü">Serbest Soru Çözümü</option>
                                            <option value="Öğretmen Videosu" disabled>Öğretmen Videosu</option>
                                            <option value="Ders Videosu" disabled>Ders Videosu</option>
                                            <option value="Ders Konu Kartı" disabled>Ders Konu Kartı</option>
                                        </select>

                                        {selectedHomeworkType === "PDF" && (
                                            <div>
                                                <label htmlFor="pdfInput">PDF Kodu Giriniz:</label>
                                                <input name="PrintableTestCode" type="text" id="pdfInput" />
                                            </div>
                                        )}
                                        {selectedHomeworkType === "Serbest Soru Çözümü" && (
                                            <div>
                                                <label htmlFor="SerbestSoruSayısı">Serbest Soru Sayısını Giriniz:</label>
                                                <input type="number" max="120" min="1" id="SerbestSoruSayısı" onChange={handleSoruSayısıInputChange} name="QuestionCount" />
                                            </div>
                                        )}
                                        {selectedHomeworkType && (
                                            <div>
                                                <label htmlFor="title">Başlık:</label>
                                                <input type="text" id="title" name="Title" />

                                                <label htmlFor="description">Açıklama:</label>
                                                <input type="text" id="description" name="Description" />
                                                <label htmlFor="order">Ödev sıralaması:</label>
                                                <input type="number" id="order" name="OrderId" />
                                            </div>)}
                                        <div style={{ "marginTop": 5 }}>
                                            <button
                                                type="submit"
                                                onClick={() => {

                                                    // setAddHomeWorkModal(false);
                                                    // setSelectedLecture(null);
                                                }}
                                                style={{ marginRight: 10 }}
                                            // style={styles.deleteConfirm}
                                            >
                                                Ödev Ekle
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setAddHomeWorkModal(false);
                                                    setSelectedLecture(null);
                                                }}
                                            // style={styles.deleteConfirmClose}
                                            >
                                                Vazgeç
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </Dialog>
                    )
                }
                {
                    updateHomeWorkModal && (
                        <Dialog
                            open={updateHomeWorkModal}
                            onClose={() => setUpdateHomeWorkModal(false)}
                        >
                            <div style={styles.ChangeDateModalStyle}>
                                {<img style={{ "height": "120px", "width": "150px" }} src={selectedLecture.ThumbnailUrl}></img>}
                                <p>{selectedLecture.SubTopicName}</p>
                                <div>
                                    <form method="post" onSubmit={handleHomeworkSubmit}>

                                        <label htmlFor="options">Lütfen Ödev Türü Seçiniz:</label>
                                        <select id="options" onChange={handleOptionChange} value={selectedHomework.QuestionCount > 0 ? "Serbest Soru Çözümü" : "PDF"}>
                                            <option value="">Ödev Türü Seçiniz</option>
                                            <option value="PDF" >PDF</option>
                                            <option value="Serbest Soru Çözümü">Serbest Soru Çözümü</option>
                                            <option value="Öğretmen Videosu" disabled>Öğretmen Videosu</option>
                                            <option value="Ders Videosu" disabled>Ders Videosu</option>
                                            <option value="Ders Konu Kartı" disabled>Ders Konu Kartı</option>
                                        </select>

                                        {selectedHomework.QuestionCount == 0 ? (
                                            <div>
                                                <label htmlFor="pdfInput">PDF Kodu Giriniz:</label>
                                                <input name="PrintableTestCode" type="text" id="pdfInput" defaultValue={selectedHomework.PrintableTestCode} />
                                            </div>
                                        ) :
                                            (
                                                <div>
                                                    <label htmlFor="SerbestSoruSayısı">Serbest Soru Sayısını Giriniz:</label>
                                                    <input 
                                                    type="number" 
                                                    max="120" 
                                                    min="1" 
                                                    id="SerbestSoruSayısı" 
                                                    onChange={handleSoruSayısıInputChange} 
                                                    name="QuestionCount" 
                                                    defaultValue={selectedHomework.QuestionCount} 
                                                    />
                                                </div>
                                            )}

                                        <div>
                                            <label htmlFor="title">Başlık:</label>
                                            <input type="text" id="title" name="Title" defaultValue={selectedHomework.Title} />

                                            <label htmlFor="description">Açıklama:</label>
                                            <input type="text" id="description" name="Description" defaultValue={selectedHomework.Description} />
                                            <label htmlFor="order">Ödev sıralaması:</label>
                                            <input type="number" id="order" name="OrderId" defaultValue={selectedHomework.OrderId} />
                                        </div>
                                        <div style={{ "marginTop": 5 }}>
                                            <button
                                                type="submit"
                                                onClick={() => {

                                                    // setAddHomeWorkModal(false);
                                                    // setSelectedLecture(null);
                                                }}
                                                style={{ marginRight: 10 }}
                                            // style={styles.deleteConfirm}
                                            >
                                                Ödev Güncelle
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setUpdateHomeWorkModal(false);
                                                    setSelectedHomework(null);
                                                    setSelectedLecture(null)
                                                }}
                                            // style={styles.deleteConfirmClose}
                                            >
                                                Vazgeç
                                            </button>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </Dialog>
                    )
                }
                {deleteHomeWorkModal && (
                    <Dialog
                        open={deleteHomeWorkModal}
                        onClose={() => setDeleteHomeWorkModal(false)}
                    >
                        <div style={styles.ChangeDateModalStyle}>
                            <p >{selectedHomework.Title}</p>
                            <p>{selectedHomework.Description}</p>
                            bu ödevi silmeyi onaylıyormusunuz?
                            <div style={{ "marginTop": 5 }}>
                                <button
                                    onClick={() => {
                                        deleteHomework(selectedHomework.Id)
                                        //setDisplayDateChange(false);
                                        setDeleteHomeWorkModal(false);
                                        setSelectedHomework(null);
                                    }}
                                    style={{ marginRight: 10 }}
                                // style={styles.deleteConfirm}
                                >
                                    Sil
                                </button>
                                <button
                                    onClick={() => {
                                        setDeleteHomeWorkModal(false);
                                        setSelectedHomework(null);
                                    }}
                                // style={styles.deleteConfirmClose}
                                >
                                    Vazgeç
                                </button>
                            </div>
                        </div>
                    </Dialog>
                )}
                {deleteLectureModal && (
                    <Dialog
                        open={deleteLectureModal}
                        onClose={() => setDeleteLectureModal(false)}
                    >
                        <div style={styles.ChangeDateModalStyle}>
                            {<img style={{ "height": "120px", "width": "150px" }} src={selectedLecture.ThumbnailUrl}></img>}
                            <p>{selectedLecture.SubTopicName}</p>
                            Bu canlı dersi silmeyi onaylıyormusunuz
                            <div style={{ "marginTop": 5 }}>
                                <button
                                    onClick={() => {
                                        deleteLecture(selectedLecture.Id, selectedLecture.RowStatus)
                                        //setDisplayDateChange(false);
                                        setDeleteLectureModal(false);
                                        setSelectedLecture(null);
                                    }}
                                    style={{ marginRight: 10 }}
                                // style={styles.deleteConfirm}
                                >
                                    Sil
                                </button>
                                <button
                                    onClick={() => {
                                        setDeleteLectureModal(false);
                                        setSelectedLecture(null);
                                    }}
                                // style={styles.deleteConfirmClose}
                                >
                                    Vazgeç
                                </button>
                            </div>
                        </div>
                    </Dialog>
                )}
                {dateChangeConfirmation && (
                    <Dialog
                        open={dateChangeConfirmation}
                        onClose={() => setDateChangeConfirmation(false)}
                    >
                        <div style={styles.ChangeDateModalStyle}>
                            {<img style={{ "height": "120px", "width": "150px" }} src={selectedLecture.ThumbnailUrl}></img>}
                            Bu canlı dersin tarihini değiştirmeyi onaylıyormusunuz
                            <div >
                                <button
                                    onClick={() => {
                                        updateLecture(selectedLecture, dateChange(startDate))
                                        //setDisplayDateChange(false);
                                        setSelectedLecture(null)
                                        setDateChangeConfirmation(false)
                                    }}
                                // style={styles.deleteConfirm}
                                >
                                    Onayla
                                </button>
                                <button
                                    onClick={() => {
                                        setDateChangeConfirmation(false);
                                        setSelectedLecture(null)
                                    }}
                                // style={styles.deleteConfirmClose}
                                >
                                    Vazgeç
                                </button>
                            </div>
                        </div>
                    </Dialog>)}
                {displayDateChange && (
                    <Dialog
                        open={displayDateChange}
                        onClose={() => setDisplayDateChange(false)}
                    >
                        <div style={styles.ChangeDateModalStyle}>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                timeInputLabel="Time:"
                                dateFormat="MM/dd/yyyy h:mm aa"
                                showTimeInput
                                inline
                            />
                            <div >
                                <button
                                    onClick={() => {
                                        dateChange(startDate)
                                        setDisplayDateChange(false);
                                        setDateChangeConfirmation(true)
                                    }}
                                // style={styles.deleteConfirm}
                                >
                                    Değiştir
                                </button>
                                <button
                                    onClick={() => setDisplayDateChange(false)}
                                // style={styles.deleteConfirmClose}
                                >
                                    Vazgeç
                                </button>
                            </div>
                        </div>
                    </Dialog>)}
                <button onClick={() => window.open('/addTeachers')}>Öğretmen ekle</button>
                <button onClick={() => { setAddLectureButtonGroup(true) }}>Ders ekle</button>
                {addLectureButtonGroup && (
                    <div>
                        <button onClick={() => { setAddLectureModal(true) }}>Normal Ders Ekle</button>
                        <button onClick={() => { setAddMutualLectures(true) }}>Rehberlik ve ortak dersler ekle</button>
                    </div>
                )}
                <div style={{ "marginBottom": "10px", overflowX: "auto",overflow:"x" }} >


                    <table>
                        <thead>
                            <tr>
                                <th>CourseId</th>
                                <th>Date</th>
                                <th>ExamCategoryId</th>
                                <th>ExamType</th>
                                <th>Id</th>
                                <th>OnlineLectureHomeworks</th>
                                <th>OnlineLectureTeacherId</th>
                                <th>RowStatus</th>
                                <th>SubCourseTopicUniqueId</th>
                                <th>SubTopicName</th>
                                <th>ThumbnailUrl</th>
                                <th>Title</th>
                                <th>TopicName</th>
                                <th>PDF URL</th>
                                <th>Url</th>
                                <th>Url Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lectureList ? lectureList.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.CourseId}</td>
                                    <td> <IconButton onClick={() => {
                                        setDisplayDateChange(true);
                                        setSelectedLecture(item);
                                        console.log("item", item)
                                    }} style={{ ...styles.iconButton, float: 'right' }} >
                                        <EditIcon style={{ ...styles.rowActionIcon }} />
                                    </IconButton>
                                        {dateFormatDisplay(item.Date)}</td>
                                    <td>{item.ExamCategoryId}</td>
                                    <td>{item.ExamType}</td>
                                    <td>{item.Id}</td>
                                    <td>
                                        <button onClick={() => {
                                            setAddHomeWorkModal(true);
                                            setSelectedLecture(item)
                                            setIsUpdateHomework(false)
                                        }}>Ödev ekle</button>
                                        {item.Homeworks.map((homeWork) => {
                                            return (
                                                <div style={{ "display": "flex" }}>
                                                    <IconButton onClick={() => {
                                                        setSelectedHomework(homeWork)
                                                        setSelectedLecture(item)
                                                        setUpdateHomeWorkModal(true)
                                                        setIsUpdateHomework(true)
                                                    }} style={{ ...styles.iconButton, float: 'right', "alignItems": "center" }} >
                                                        <EditIcon style={{ ...styles.rowActionIcon }} />
                                                    </IconButton>
                                                    <li style={{ "margin": "10px" }}>
                                                        {homeWork.Title}/{homeWork.Description}
                                                    </li>
                                                    <button onClick={() => {
                                                        setDeleteHomeWorkModal(true)
                                                        setSelectedHomework(homeWork)
                                                    }} style={{ border: "1px solid red", color: "red" }}>Ödevi sil</button>
                                                </div>
                                            )
                                        })}
                                    </td>
                                    <td>
                                        <img style={{ "height": "120px", "width": "150px" }} src={item.TeacherInfos.ImageUrl}></img>
                                        {item.TeacherInfos.TeacherName}
                                    </td>
                                    <td>{item.RowStatus}</td>
                                    <td>{item.SubCourseTopicUniqueId}</td>
                                    <td>
                                        <div style={{ "display": "flex" }}>
                                            <IconButton onClick={() => {

                                                setSelectedLecture(item)
                                                setIslecturePublic(item.IsPublic)
                                                //  setSelectedExamTypeId(item.ExamCategoryId)
                                                //  setSelectedExamCategory(item.ExamType)
                                                //  setSelectedCourse(item.CourseId)
                                                //   setSelectedSubCourse(item)

                                                sessionStorage.setItem("pdfUrl", item.LectureNoteUrl)
                                                setUpdateLectureModal(true)
                                                //item.ExamType==99?setAddMutualLectures(true):setAddLectureModal(true)
                                                // setUpdateLectureModal(true)
                                                // setIsUpdateHomework(true)
                                                console.log("SelectedLecture", item)
                                            }} style={{ ...styles.iconButton, float: 'right', "alignItems": "center" }} >
                                                <EditIcon style={{ ...styles.rowActionIcon }} />
                                            </IconButton>
                                            {item.SubTopicName}
                                        </div>

                                    </td>
                                    <td>
                                        <div style={{ display: "flex" }}>
                                            <IconButton onClick={() => {

                                                setSelectedLecture(item)
                                                setIslecturePublic(item.IsPublic)
                                                //  setSelectedExamTypeId(item.ExamCategoryId)
                                                //  setSelectedExamCategory(item.ExamType)
                                                //  setSelectedCourse(item.CourseId)
                                                //   setSelectedSubCourse(item)
                                                sessionStorage.setItem("pdfUrl", item.LectureNoteUrl)
                                                setUpdateLectureModal(true)
                                                //item.ExamType==99?setAddMutualLectures(true):setAddLectureModal(true)
                                                // setUpdateLectureModal(true)
                                                // setIsUpdateHomework(true)
                                                console.log("SelectedLecture", item)
                                            }} style={{ ...styles.iconButton, float: 'right', "alignItems": "center" }} >
                                                <EditIcon style={{ ...styles.rowActionIcon }} />
                                            </IconButton>
                                            <img style={{ "height": "120px", "width": "150px" }} src={item.ThumbnailUrl}></img>
                                        </div>
                                    </td>
                                    <td>{item.Title}</td>
                                    <td>
                                        <div style={{ "display": "flex" }}>
                                            <IconButton onClick={() => {

                                                setSelectedLecture(item)
                                                setIslecturePublic(item.IsPublic)
                                                //  setSelectedExamTypeId(item.ExamCategoryId)
                                                //  setSelectedExamCategory(item.ExamType)
                                                //  setSelectedCourse(item.CourseId)
                                                //   setSelectedSubCourse(item)
                                                sessionStorage.setItem("pdfUrl", item.LectureNoteUrl)
                                                setUpdateLectureModal(true)
                                                //item.ExamType==99?setAddMutualLectures(true):setAddLectureModal(true)
                                                // setUpdateLectureModal(true)
                                                // setIsUpdateHomework(true)
                                                console.log("SelectedLecture", item)
                                            }} style={{ ...styles.iconButton, float: 'right', "alignItems": "center" }} >
                                                <EditIcon style={{ ...styles.rowActionIcon }} />
                                            </IconButton>
                                            {item.TopicName}
                                        </div>

                                    </td>
                                    <td>
                                        <div style={{ display: "flex" }}>
                                            <IconButton onClick={() => {

                                                setSelectedLecture(item)
                                                setIslecturePublic(item.IsPublic)
                                                //  setSelectedExamTypeId(item.ExamCategoryId)
                                                //  setSelectedExamCategory(item.ExamType)
                                                //  setSelectedCourse(item.CourseId)
                                                //   setSelectedSubCourse(item)
                                                sessionStorage.setItem("pdfUrl", item.LectureNoteUrl)
                                                setUpdateLectureModal(true)
                                                //item.ExamType==99?setAddMutualLectures(true):setAddLectureModal(true)
                                                // setUpdateLectureModal(true)
                                                // setIsUpdateHomework(true)
                                                console.log("SelectedLecture", item)
                                            }} style={{ ...styles.iconButton, float: 'right', "alignItems": "center" }} >
                                                <EditIcon style={{ ...styles.rowActionIcon }} />
                                            </IconButton>
                                            <a href={item.LectureNoteUrl} target="_blank">
                                                <div style={{ width: "150px", wordWrap: "break-word" }}>

                                                    {item.LectureNoteUrl}
                                                </div>
                                            </a>
                                        </div>

                                    </td>
                                    <td>
                                        <div style={{ "display": "flex" }}>
                                            <IconButton onClick={() => {

                                                setSelectedLecture(item)
                                                setIslecturePublic(item.IsPublic)
                                                //  setSelectedExamTypeId(item.ExamCategoryId)
                                                //  setSelectedExamCategory(item.ExamType)
                                                //  setSelectedCourse(item.CourseId)
                                                //   setSelectedSubCourse(item)
                                                sessionStorage.setItem("pdfUrl", item.LectureNoteUrl)
                                                setUpdateLectureModal(true)
                                                //item.ExamType==99?setAddMutualLectures(true):setAddLectureModal(true)
                                                // setUpdateLectureModal(true)
                                                // setIsUpdateHomework(true)
                                                console.log("SelectedLecture", item)
                                            }} style={{ ...styles.iconButton, float: 'right', "alignItems": "center" }} >
                                                <EditIcon style={{ ...styles.rowActionIcon }} />
                                            </IconButton>

                                            <a href={item.StreamUrl} target="_blank">{item.StreamUrl}</a>
                                        </div>


                                    </td>
                                    <td>
                                    <select

                                        value={item.OfflineUrlType}
                                        onChange={(event) => handleSelectChange(event,item)}
                                    >
                                       {Object.keys(Enums.UrlType).map((key) => (
                                        <option key={key} value={Enums.UrlType[key]}>
                                            {key}
                                        </option>
                                        ))}
                                    </select>
                                    </td>
                                    <td><button onClick={() => {
                                        setSelectedLecture(item)
                                        setDeleteLectureModal(true);
                                    }} style={{ border: "1px solid red", color: "red" }}>Dersi sil</button></td>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                </div>
            </div>
            {loading ? <Loader></Loader> : <></>}
        </div>
    )
}
export default OnlineLecturePanel
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SideBar from "../../components/SideBar";
import { Components } from "../../assets/Dimensions";
import SwitchComponent from "../../components/SwitchComponent";
import { ToastContainer, toast } from "react-toastify";
import "./style.css";
import { getCoursesForVideoSolution } from "../../api/QuestionSubCourseSelection/courses";
import Toggle from "./components/toggle/toggle";
import { encryption, base64Encode } from "../../helpers/encryptionHelper";
import CourseBG from "../../assets/images/bg-lesson.jpeg";
import ExamCategoryType from "../../enums/ExamCategoryType";
import ExamType from "../../enums/ExamType";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { setCookie } from "../../helpers/cookie";
import { useHistory } from "react-router-dom";
import Loader from "@material-ui/core/CircularProgress"

const CoursePage = () => {
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const user = JSON.parse(localStorage.getItem("user"));
  const userAdminToken = sessionStorage.getItem("jwtP");

  const [nav, setNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedExamCategory, setSelectedExamCategory] = useState(null);
  const [coursesData, setCoursesData] = useState([]);
  const [allCourseData, setAllCourseData] = useState(null);
  const [examTypeId, setExamTypeId] = useState(1);
  const [loader, setLoader] = useState(false);
  const style = {
    container: {
      //state e bool değer ekle menü butonuna basıldığında css
      //içinde container a margin eklesin hemde navı açsın

      //marginLeft: isTabletOrMobile ? 0 : Components.SideBarWidth + 15,
      paddingLeft: isTabletOrMobile ? 15 : Components.SideBarWidth + 15,
      paddingTop: 20,
      paddingRight: 15,
      marginBottom: 20,
    },
    editIcon: {
      width: Components.Image.Big.Width,
      height: Components.Image.Big.Height,
    },
    iconButton: {
      marginLeft: 10,
      padding: 0,
    },
  };

  const getCategoryName = (value) => {
    for (const key in ExamCategoryType) {
      if (ExamCategoryType[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };

  const filterCoursesData = (data, categoryId, ExamTypeId) => {
    const category = data.find(
      (category) => category.ExamCategoryId === categoryId
    );
    if (!category) {
      toast.error("Bu Kategori ile ilgili Videosu Çekilecek ders Bulunamamıştır, Lütfen başka kategori seçiniz.")
      setTimeout(() => {
        history.push('./userprofile');
      }, 2000);
      console.log("Category not found");
      return;
    }

    const examType = category.ExamCategoryCourses.find(
      (examType) => examType.ExamTypeId === ExamTypeId
    );
    if (!examType) {
      console.log("Exam type not found in the category");
      return;
    }

    return setCoursesData(examType.ExamTypeCourses);
  };

  const getCoursesData = async () => {
    
      try {
        setLoader(true)
        const res = await getCoursesForVideoSolution(user.Token);
        if (res.data.ResponseStatus === 1) {
          localStorage.setItem(
            "QuestionSubTopicSelectionCourses",
            JSON.stringify(res.data.ResponseData)
          );
          setAllCourseData(res.data.ResponseData);
          if (selectedExamCategory) {
            if (selectedExamCategory === ExamCategoryType.YKS) {
              filterCoursesData(
                res.data.ResponseData,
                selectedExamCategory,
                ExamType.TYT
              );
            } else if(selectedExamCategory === ExamCategoryType.LGS) {
              filterCoursesData(
                res.data.ResponseData,
                selectedExamCategory,
                ExamType.LGS
              );
            }else{
              filterCoursesData(
                res.data.ResponseData,
                selectedExamCategory,
                ExamType.KPSS
              );
            }
          } else {
            setIsOpen(true);
          }
          setLoader(false)
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
        toast.error("Bir hata oluştu lütfen yetkililere ulaşınız");
        setTimeout(() => {
          history.push('./userprofile');
        }, 2000);
        
      }
    
  };

  useEffect(() => {
    getCoursesData();
  }, [selectedExamCategory]);
  const selectExamCategory = (id) => {
    setSelectedExamCategory(id);
    setIsOpen(false);
  };

  return (
    <>
      {isTabletOrMobile ? (
        <div>
          <span
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={() => setNav(!nav)}
          >
            menu
          </span>
          {nav && <SideBar />}
        </div>
      ) : (
        <SideBar />
      )}
      <ToastContainer />
      {loader && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader size={100} />
        </div>
      )}

      <div style={style.container}>
        {isOpen && (
          <div className="modal">
            <div className="modal-content">
              <div style={{ textAlign: "center" }}>
                <h2>Lütfen Sınav Seçimi Yapınız</h2>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <button
                    style={{ padding: 10, width: "30%" }}
                    onClick={() => {
                      selectExamCategory(ExamCategoryType.YKS);
                    }}
                  >
                    YKS
                  </button>
                  <button
                    style={{ width: "30%" }}
                    onClick={() => {
                      selectExamCategory(ExamCategoryType.LGS);
                    }}
                  >
                    LGS
                  </button>
                  <button
                    style={{ width: "30%" }}
                    onClick={() => {
                      selectExamCategory(ExamCategoryType.KPSS);
                    }}
                  >
                    KPSS
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedExamCategory && (
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 10,
              margin: 10,
            }}
          >
            {/* <Toggle
              labels={["YKS", "LGS"]}
              values={[ExamCategoryType.YKS, ExamCategoryType.LGS]}
              initialValue={selectedExamCategory}
              onChange={(value) => {
                setSelectedExamCategory(value);
                filterCoursesData(allCourseData, value, value == ExamCategoryType.LGS ? ExamType.LGS : ExamType.TYT);
                setExamTypeId(value == ExamCategoryType.LGS ? ExamType.LGS : ExamType.TYT)
                localStorage.setItem("SelectedExamCategory",value)
              }}
            /> */}
            <div
              style={{
                borderWidth: 2,
                borderColor: "black",
                borderRadius: 8,
                padding: 10,
                borderStyle: "solid", // Add border style explicitly
                cursor: "pointer",
                alignItems: "center", // Add cursor style to indicate it's clickable
              }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {getCategoryName(selectedExamCategory)}
              <IconButton style={{ ...style.iconButton }}>
                <EditIcon style={{ ...style.editIcon }} />
              </IconButton>
            </div>
            {selectedExamCategory == 1 && (
              <Toggle
                labels={["TYT", "AYT"]}
                values={[ExamType.TYT, ExamType.AYT]}
                initialValue={selectedExamCategory}
                onChange={(value) => {
                  filterCoursesData(allCourseData, ExamCategoryType.YKS, value);
                  setExamTypeId(value);
                }}
              />
            )}
          </div>
        )}

        {coursesData.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              marginRight: "auto",
              marginLeft: "auto",
              justifyContent: "center",
            }}
          >
            {coursesData.map((data) => {
              return (
                <div
                  style={{
                    textDecoration: "none",
                    cursor: data.TotalCount > 0 ? "pointer" : "default",
                    color: "#FFFFFF",
                    backgroundImage: `url(${CourseBG})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                    padding: 30,
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: 8,
                    flexWrap: "wrap",
                  }}
                  onClick={() => {
                    if (data.TotalCount > 0) {
                      const url = `https://canvasvideo.tosanalytics.com/?courseId=${base64Encode(
                        data.CourseId
                      )}&examTypeId=${base64Encode(
                        selectedExamCategory == ExamCategoryType.KPSS
                          ? ExamType.KPSS
                          : selectedExamCategory == ExamCategoryType.LGS
                          ? ExamType.LGS
                          : examTypeId
                      )}`;
                      window.open(url, "_blank");
                      setCookie(
                        "jwtToken",
                        user.Token,
                        86400000,
                        ".tosanalytics.com",
                        "/"
                      );
                    } else {
                      toast.error(
                        "Bu ders ile ilgili cevaplanacak soru yoktur."
                      );
                    }
                  }}
                >
                  <div>{data.CourseName}</div>
                  <div>
                    {`İlerleme Durumu: ${data.CompletedCount}/${data.TotalCount}`}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default CoursePage;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

//#region Material Components
import { withStyles } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
//#endregion

//#region Internal
import { Primary } from './../../assets/Colors';
import { FontSize } from './../../assets/Dimensions';
//#endregion

//#region Override Style
const Button = withStyles({
    root:{
        backgroundColor: Primary.TopBar,
        boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
        marginTop: 10,
        '&:hover': {
            backgroundColor: Primary.TopBar,
            opacity: 0.6
        }
    },
    label: {
        color: Primary.ContainerBackground,
        fontSize: FontSize.Small,
        textTransform: 'capitalize'
    },
    text: {
        padding: '6px 16px'
    }
})(MuiButton);
//#endregion

class ApproveButton extends Component {
    constructor(props){
        super(props);

        this.state={};
    }

    render(){
        return(
            !this.props.excelImport ? 
                <Button 
                    onClick={this.props.onClick} 
                    style={{...this.props.style}} 
                    id={this.props.id && this.props.id}
                >
                    {this.props.text}
                </Button>
            :
            <Button 
                variant='contained'
                component='label'
                style={{...this.props.style}}
            >
                {this.props.text}
                <input
                    type="file"
                    accept=".csv, text/csv"
                    style={{ display: "none" }}
                    onChange={this.props.onClick}
                />
            </Button>
        )
    }
};

ApproveButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    style: PropTypes.object,
    excelImport: PropTypes.bool,
    id: PropTypes.string
};

ApproveButton.defaultProps = {
    excelImport: false
};

export default ApproveButton;

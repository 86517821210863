import { UserActions } from './../ActionTypes';

export const loginUser = (user) => ({
    type: UserActions.Login,
    user
});

export const updateUser = (user) => ({
    type: UserActions.Update,
    user
});

export const logoutUser = () => ({
    type: UserActions.Logout
});

import React, { useEffect, useState } from "react";
import ImageComponent from "../../components/questionSubTopicSelection/imageComponent";
import SubCourses from "../../components/questionSubTopicSelection/subCourses";
import {
  getQuestionById,
  updateQuestion,
} from "../../api/QuestionSubCourseSelection/courses";
import { ToastContainer, toast } from "react-toastify";
import SideBar from "../../components/SideBar";
import { useMediaQuery } from "react-responsive";
import { Components } from "../../assets/Dimensions";
import ExamType from "../../enums/ExamType";
import "./style.css";
import { GetObjectKey } from "../../helpers/getObjectKeyFromValue";
import MenuIcon from "@material-ui/icons/Menu";
import Loader from "@material-ui/core/CircularProgress"
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import QuestionReportModal from "../../components/ReportModal/Components/Modal/Modal";
import AdminPanelPage from "../../enums/AdminPanelPage";
import { useReportIssues } from "../../hooks/useReportIssue";

const QuestionPage = (props) => {
  const { courseId, courseName, examType } = props.location.state;
  console.log("{ courseId, courseName, examType }", {
    courseId,
    courseName,
    examType,
  });
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("user"));
  const userAdminToken = sessionStorage.getItem("jwtP");
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const [selectedSubTopic, setSelectedSubTopic] = useState(null);
  const [questionData, setQuestionData] = useState(null);
  const [nav, setNav] = useState(false);
  const [nestedTitles, setNestedTitles] = useState(null);
  const [noTopicModal, setNoTopicModal] = useState(false);
  const [loader, setLoader] = useState(false);

  const {
    issueTypes,
    reportModal,
    openReportModal,
    onReportSubmit,
    setIssueTypes,
    setReportModal,
  } = useReportIssues(user.Token);

  const getExamTypeName = (value) => {
    for (const key in ExamType) {
      if (ExamType[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };

  const style = {
    container: {
      //state e bool değer ekle menü butonuna basıldığında css
      //içinde container a margin eklesin hemde navı açsın

      //marginLeft: isTabletOrMobile ? 0 : Components.SideBarWidth + 15,
      paddingLeft: isTabletOrMobile ? 15 : Components.SideBarWidth + 15,
     
      paddingRight: 15,
      marginBottom: 50,
      marginTop: "1rem",
    },
    editIcon: {
      width: Components.Image.Big.Width,
      height: Components.Image.Big.Height,
    },
    iconButton: {
      marginLeft: 10,
      padding: 0,
    },
  };

  

  const previousQuestion = () => {
    if (selectedSubTopic) {
      console.log(selectedSubTopic,"burayamı girdi")
      updateQuestionWithTopic(questionData.TeacherOrder-1);
    } else {
      // setNoTopicModal(true);
      getQuestion(questionData.TeacherOrder-1)
    }
    console.log("prevQuestion");
  };
  const nextQuestion = () => {
    // const data = {
    //   BookSectionCropId: questionData.BookSectionCropId,
    //   SubCourseTopicUniqueId: selectedSubTopic.SubCourseTopicUniqueId,
    // };
    // const res = await updateQuestion()
    //   console.log("nextQuestion",questionData.BookSectionCropId,selectedSubTopic.SubCourseTopicUniqueId)
    //   // console.log("nextQuestion",{selectedSubTopic,questionData?.BookSectionCropId})
    if (selectedSubTopic) {
      updateQuestionWithTopic(questionData.TeacherOrder+1);
    } else if(questionData.NewSubCourseTopicUniqueId!==questionData.SubCourseTopicUniqueId) {
      getQuestion(questionData.TeacherOrder+1)
    }else{
      setNoTopicModal(true);
    }
  };

  const updateQuestionWithTopic = async (questionOrder) => {
    setLoader(true)
    const data = {
      BookSectionCropId: questionData.BookSectionCropId,
      SubCourseTopicUniqueId: selectedSubTopic
        ? selectedSubTopic.SubCourseTopicUniqueId
        : null,
    };

    try {
      setLoader(true)
      const res = await updateQuestion(user.Token, data);
      if (res.data.ResponseStatus == 1) {
        setLoader(false)
        getQuestion(questionOrder);
        getSelectedSubTopic(null)
      } else {
        setLoader(false)
        toast.error("Bir sorun oluştu lütfen yetkililere ulaşınız.");
        setTimeout(() => {
          history.goBack();
        }, 2000);
      }

    } catch (error) {
      setLoader(false)
      toast.error("Bir sorun oluştu lütfen yetkililere ulaşınız.");
      setTimeout(() => {
        history.goBack();
      }, 2000);
      console.log("error", error);
    }
  };

  const getSelectedSubTopic = (item) => {
    setSelectedSubTopic(item);
  };

  const getNestedTittlesData = () => {
    const localData = localStorage.getItem(getExamTypeName(examType));
    setNestedTitles(JSON.parse(localData));
  };

  const getQuestion = async (order = null) => {
    setLoader(true);
    try {
      const res = await getQuestionById(user.Token, examType, courseId, order);
      console.log("ressss", res);
      if (res.data.ResponseStatus === 1) {
        setQuestionData(res.data.ResponseData);
        setLoader(false);
        console.log("res.data.ResponseData", res.data.ResponseData);
      } else if (res.data.ResponseStatus === 3) {
        setLoader(false);
        toast.error("Bu Ders ile ilgili soru bulunmamaktadır");
        setTimeout(() => {
          history.goBack();
        }, 2000);
      } else {
        setLoader(false);
        toast.error("Bir hata oluştu lütfen yetkililere ulaşınız");
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching question:", error);
      toast.error("Bir hata oluştu lütfen yetkililere ulaşınız");
      setTimeout(() => {
        history.goBack();
      }, 2000);
    }
  };
  

  useEffect(() => {
    getQuestion();
    getNestedTittlesData();
  }, []);

  return (
    <>
      {isTabletOrMobile ? (
        <div>
          <span
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={() => setNav(!nav)}
          >
            <MenuIcon />
          </span>
          {nav && <SideBar />}
        </div>
      ) : (
        <SideBar />
      )}
      
      <ToastContainer />
      <div style={style.container}>
        {questionData && (
          <div
            style={{
              position: "relative",
            
              display: "flex",
              flexDirection: "column",
              top: 0,
              //left: isTabletOrMobile ? 15 : Components.SideBarWidth + 15,
              
            }}
          >
           {questionData.NewSubCourseTopicUniqueId!==questionData.SubCourseTopicUniqueId&&<div
                style={{
                  width: "100vw",
                  top: 0,
                  position: "fixed",

                  backgroundColor: "blue",
                  color: "white",
                  textAlign: "center",
                  marginBottom: 20,
                  zIndex: 1000,
                }}
              >
               
                {questionData.NewSelectSubCourseTopicName}{questionData.NewSubCourseTopicUniqueId!==0?`-${GetObjectKey(parseInt(questionData.NewSubCourseTopicUniqueId.toString().trim().charAt(0)),ExamType)}`:null}
              </div>} 

            {selectedSubTopic && (
              <div
                style={{
                  width: "100vw",
                  top: 0,
                  position: "fixed",

                  backgroundColor: "green",
                  color: "white",
                  textAlign: "center",
                  marginBottom: 20,
                  zIndex: 1000,
                }}
              >
                {selectedSubTopic.NewSubCourseTopicName}
              </div>
            )}
                        <button
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center", // Ensure the content is vertically centered
                width: "100%", // Make sure the button takes up full width
              }}
              onClick={() => {
                history.goBack();
              }}
              className="prevButton"
            >
              <ArrowBackIcon />
            </button>
            <div style={{display:"flex",fontSize:"10px"}}>
              {questionData.BookSectionCropId}-{GetObjectKey(examType, ExamType)}-{questionData.CourseName}/
              <div style={{fontSize:"10px"}}>
              {questionData.NewCourseTopicName}
              {questionData.NewCourseTopicName!==questionData.NewSubCourseTopicName?`-${questionData.NewSubCourseTopicName}`:null}
            </div>
            </div>

            <div style={{fontSize:"10px"}}>
              {questionData.BookName}-{questionData.BookSectionName}
            </div>
            <div style={{fontSize:"10px"}}>
             {questionData.CourseTopicName}{questionData.CourseTopicName!==questionData.SubCourseTopicName?`-${questionData.SubCourseTopicName}`:null}
            </div>
            
          </div>
        )}
       
        {/* Loader container */}
        {loader && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Loader size={100} />
          </div>
        )}

        {reportModal && questionData && (
          <QuestionReportModal
          onClose={() => setReportModal(false)}
          responseData={issueTypes}
          setResponseData={setIssueTypes}
          isLoading={loader}
          onSubmit={onReportSubmit}
          adminPanelPage={AdminPanelPage.QuestionSubTopicSelection}
          BookSectionCropId={questionData.BookSectionCropId}
          />
        )}
    
        {questionData && (
          <div>
            <div
              className="questionContainer"
              // style={{display:"flex",direction:"column",flexWrap: "wrap",gap:"5rem" }}
            >
              {noTopicModal && (
                <div className="modal">
                  <div className="modal-content">
                    <div style={{ textAlign: "center" }}>
                      <h2>
                        Konu Seçimi Yapmadınız Devam etmek istediğinize
                        Eminmisiniz?
                      </h2>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <button
                          style={{ padding: 10, width: "30%" }}
                          onClick={() => {
                            setNoTopicModal(false);
                          }}
                        >
                          Vazgeç
                        </button>
                        <button
                          style={{ width: "30%" }}
                          onClick={() => {
                            setNoTopicModal(false);
                            getQuestion(questionData.TeacherOrder+1)
                          }}
                        >
                          Devam Et
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className="questionImage"
                style={
                  {
                    // Enable vertical scrollbar when content overflows
                    // Set the maximum height of the component to 100vh
                    //flex:70
                    // overflow:"auto",
                    // maxWidth:"40%",
                    // maxHeight:window.screen.height/1.5
                  }
                }
              >
                <ImageComponent
                  openReportModal={openReportModal}
                  examTypeId={examType}
                  questionData={questionData}
                />
              </div>
              <div
                className="topicsContainer"
                style={
                  {
                    // overflowY: "auto", // Enable vertical scrollbar when content overflows
                    // Set the maximum height of the component to 100vh
                    //flex:30
                    // overflow:"auto",
                    // maxWidth:"40%",
                    // height:"auto",
                    // maxHeight:"100vh"
                  }
                }
              >
                <SubCourses
                  nestedTitlesData={nestedTitles}
                  CourseId={courseId}
                  examTypeId={examType}
                  getSelectedSubTopic={getSelectedSubTopic}
                  questionDataExamType={questionData.ExamType}
                  questionDataCourseId={questionData.CourseId}
                  nextQuestion={nextQuestion}
                  previousQuestion={previousQuestion}
                  questionOrder={questionData.TeacherOrder}
                  selectedSubTopic={questionData.NewSubCourseTopicUniqueId}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default QuestionPage;

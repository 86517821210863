import { UserActions } from './../ActionTypes';

const initialState = {
    Users: [],
    RowCount: 0
};

export const allUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActions.SetAll:
            return Object.assign({}, state, { 
                Users: action.users,
                RowCount: action.rowCount
            });
        default:
            return state;
    }
};
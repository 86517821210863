import { SubCourseTopicActions } from './../ActionTypes';

const initialState = {
    SubCourseTopics: []
};

export const subCourseTopicReducer = (state = initialState, action) => {
    switch (action.type) {
        case SubCourseTopicActions.SetAll:
            return Object.assign({}, state, { 
                SubCourseTopics: action.subCourseTopics
            });
        default:
            return state;
    }
};
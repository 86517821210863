import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import SideBar from "../../components/SideBar";
import { Components } from "../../assets/Dimensions";
import SwitchComponent from "../../components/SwitchComponent";
import { ToastContainer, toast } from "react-toastify";
import "../questionVideoSolution/style.css";
import { getCourses, nestedTitles } from "../../api/QuestionSubCourseSelection/courses";
import Toggle from "../questionVideoSolution/components/toggle/toggle";
import { encryption, base64Encode } from "../../helpers/encryptionHelper";
import CourseBG from "../../assets/images/bg-lesson.jpeg";
import ExamCategoryType from "../../enums/ExamCategoryType";
import ExamType from "../../enums/ExamType";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { setCookie } from "../../helpers/cookie";
import QuestionPage from "../questionSubTopicSelection/QuestionPage";
import { useHistory } from 'react-router-dom';
import Loader from "@material-ui/core/CircularProgress"

const CoursePage = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });
  const user = JSON.parse(localStorage.getItem("user"));
  const userAdminToken = sessionStorage.getItem("jwtP");

  const [nav, setNav] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedExamCategory, setSelectedExamCategory] = useState(null);
  const [coursesData, setCoursesData] = useState([]);
  const [allCourseData, setAllCourseData] = useState(null);
  const [examTypeId, setExamTypeId] = useState(1);
  const [loader, setLoader] = useState(false);

  const history = useHistory();

  const style = {
    container: {
      //state e bool değer ekle menü butonuna basıldığında css
      //içinde container a margin eklesin hemde navı açsın

      //marginLeft: isTabletOrMobile ? 0 : Components.SideBarWidth + 15,
      paddingLeft: isTabletOrMobile ? 15 : Components.SideBarWidth + 15,
      paddingTop: 20,
      paddingRight: 15,
      marginBottom: 20,
    },
    editIcon: {
      width: Components.Image.Big.Width,
      height: Components.Image.Big.Height,
    },
    iconButton: {
      marginLeft: 10,
      padding: 0,
    },
  };

  


  const getCategoryName = (value) => {
    for (const key in ExamCategoryType) {
      if (ExamCategoryType[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };
  const getExamTypeName = (value) => {
    for (const key in ExamType) {
      if (ExamType[key] === value) {
        return key;
      }
    }
    return null; // Return null if the value is not found
  };

  const filterCoursesData = (data, categoryId, ExamTypeId) => {
    const category = data.find(
      (category) => category.ExamCategoryId === categoryId
    );
    if (!category) {
      console.log("Category not found");
      toast.error(
        "Şuan Bu Alanda Konu belirlenecek soru bulunamamıştır."
      );
      history.push('./userprofile')
      return;
    }

    const examType = category.ExamCategoryCourses.find(
      (examType) => examType.ExamTypeId === ExamTypeId
    );
    if (!examType) {
      console.log("Exam type not found in the category");
      return;
    }

    return setCoursesData(examType.ExamTypeCourses);
  };

  const getCoursesData = async () => {
    try {
      setLoader(true);
      const res = await getCourses(user.Token);
      if (res.data.ResponseStatus === 1) {
        localStorage.setItem(
          "QuestionSubTopicSelectionCourses",
          JSON.stringify(res.data.ResponseData)
        );
        setAllCourseData(res.data.ResponseData);
        if (selectedExamCategory) {
          if (selectedExamCategory === ExamCategoryType.YKS) {
            filterCoursesData(
              res.data.ResponseData,
              selectedExamCategory,
              ExamType.TYT
            );
          } else {
            filterCoursesData(
              res.data.ResponseData,
              selectedExamCategory,
              ExamType.LGS
            );
          }
        } else {
          setIsOpen(true);
        }
      } 
    } catch (error) {
      console.error("Error fetching courses:", error);
      toast.error(
        "Bir hata oluştu lütfen yetkililerle iletişime geçiniz."
      );
    } finally {
      setLoader(false); // Ensure loader is hidden even if there's an error
    }
  };
  

  const getNestedTitles = async () => {
    console.log("getExamTypeName(ExamType.TYT)",getExamTypeName(ExamType.TYT))
    const checkLocal = localStorage.getItem(getExamTypeName(ExamType.TYT))
    if(!checkLocal){
      
    const res = await nestedTitles()
    if(res.status == 200){
      res.data.forEach(item => {
          const key = item.SourceTypeName;

          const serializedObject = JSON.stringify(item)

          localStorage.setItem(key, serializedObject)
      });
    }
  }

  }
  useEffect(()=>{
    getNestedTitles();
  },[])

  useEffect(() => {
    getCoursesData();
  }, [selectedExamCategory]);
  const selectExamCategory = (id) => {
    setSelectedExamCategory(id);
    localStorage.setItem("SelectedExamCategory", id);
    setIsOpen(false);
  };

  return (
    <>
      {isTabletOrMobile ? (
        <div>
          <span
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={() => setNav(!nav)}
          >
            menu
          </span>
          {nav && <SideBar />}
        </div>
      ) : (
        <SideBar />
      )}

      {loader&&<div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Loader size={100} />
          </div>}

      <div style={style.container}> 
        {isOpen && (
          <div className="modal">
            <div className="modal-content">
              <div style={{ textAlign: "center" }}>
                <h2>Lütfen Sınav Seçimi Yapınız</h2>
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <button
                    style={{ padding: 10, width: "30%" }}
                    onClick={() => {
                      selectExamCategory(ExamCategoryType.YKS);
                    }}
                  >
                    YKS
                  </button>
                  <button
                    style={{ width: "30%" }}
                    onClick={() => {
                      selectExamCategory(ExamCategoryType.LGS);
                    }}
                  >
                    LGS
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div style={{fontWeight:"bold",fontSize:"20px"}}>
          Konu Belirleme Paneli
        </div>
        {selectedExamCategory && (
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 10,
              margin: 10,
            }}
          >
            {/* <Toggle
              labels={["YKS", "LGS"]}
              values={[ExamCategoryType.YKS, ExamCategoryType.LGS]}
              initialValue={selectedExamCategory}
              onChange={(value) => {
                setSelectedExamCategory(value);
                filterCoursesData(allCourseData, value, value == ExamCategoryType.LGS ? ExamType.LGS : ExamType.TYT);
                setExamTypeId(value == ExamCategoryType.LGS ? ExamType.LGS : ExamType.TYT)
                localStorage.setItem("SelectedExamCategory",value)
              }}
            /> */}
            <div
              style={{
                borderWidth: 2,
                borderColor: "black",
                borderRadius: 8,
                padding: 10,
                borderStyle: "solid", // Add border style explicitly
                cursor: "pointer",
                alignItems: "center", // Add cursor style to indicate it's clickable
              }}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {getCategoryName(selectedExamCategory)}
              <IconButton style={{ ...style.iconButton }}>
                <EditIcon style={{ ...style.editIcon }} />
              </IconButton>
            </div>
            {selectedExamCategory == 1 && (
              <Toggle
                labels={["TYT", "AYT"]}
                values={[ExamType.TYT, ExamType.AYT]}
                initialValue={selectedExamCategory}
                onChange={(value) => {
                  filterCoursesData(allCourseData, ExamCategoryType.YKS, value);
                  setExamTypeId(value);
                }}
              />
            )}
          </div>
        )}

        {coursesData.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              marginRight: "auto",
              marginLeft: "auto",
              justifyContent: "center",
            }}
          >
            {coursesData.map((data) => {
              return (
               
                  <div
                    onClick={() => {
                      console.log("basıyormu")
                     history.push({
                       pathname: "/questionSubTopicSelection",
                       state: {
                         courseId: data.CourseId,
                         courseName: data.CourseName,
                         examType:selectedExamCategory==2?ExamType.LGS:examTypeId
                       },
                     });
                 
                    }}
                    style={{
                      //background: 'linear-gradient(to right, #FFFFFF, #053EDD)',
                      color: "#FFFFFF",
                      backgroundImage: `url(${CourseBG})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      padding: 30,
                      display: "flex",
                      justifyContent: "space-between",
                      borderRadius: 8,
                      flexWrap: "wrap",
                    }}
                  >
                    <div>{data.CourseName}</div>
                    <div>
                      {`İlerleme Durumu: ${data.CompletedCount}/${data.TotalCount}`}
                    </div>
                  </div>
             
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default CoursePage;

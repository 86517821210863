import { PageHeaderActions } from './../ActionTypes';

export const setLinks = (links) => ({
    type: PageHeaderActions.SetLinks,
    links
});

export const addLink = (links) => ({
    type: PageHeaderActions.Add,
    links
});
import { BookSectionActions } from '../ActionTypes';

export const setAllBookSections = (bookSections, rowCount) => ({
    type: BookSectionActions.SetAll,
    bookSections,
    rowCount
});

export const setSelectedBookSectionId = (selectedId) => ({
    type: BookSectionActions.SetSelectedBookSectionId,
    selectedId
});
